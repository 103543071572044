import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { fetchQuizProjects } from "actions/quiz.actions"
import eventUnitsStyle from '../../shared/assets/jss/material-dashboard-pro-react/views/eventUnitsStyle'
import { withStyles } from '@material-ui/core/styles'
import { withIntl } from "@hlcr/ui/Intl"
import { PaginatedDataModel } from "shared/models/paginatedData.model"
import List from './List'
import PropTypes from 'prop-types'

class QuizList extends React.Component {
	pathSegment = 'quizzes'
	languageKey = 'quiz'

	constructor(props) {
		super(props)
		this.state = {
			search: ''
		}
	}

	componentDidMount() {
		const { pageNumber } = this.props
		if (pageNumber) {
			this.props.fetchQuizProjects(pageNumber)
		} else {
			this.fetchPage(1)
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timer)
	}

	fetchPage = page => {
		this.props.history.push(`/${this.pathSegment}/page/${page}`)
		this.props.fetchQuizProjects(page)
	}

	triggerChange = (search, WAIT_INTERVAL) => {
		if (search && search.length > 0) {
			clearTimeout(this.timer)
			this.setState({ search: search })
			this.timer = setTimeout(() => {
				this.props.history.push(`/${this.pathSegment}/search?q=${search}`)
			}, WAIT_INTERVAL)
		} else {
			this.fetchPage(1)
		}
	}

	render() {
		const { paginatedQuizzes, history } = this.props
		return (
			<List
				paginatedQuizzes={paginatedQuizzes}
				triggerChange={this.triggerChange}
				fetchPage={this.fetchPage}
				languageKey={this.languageKey}
				pathSegment={this.pathSegment}
				inputSearchValue={this.state.search}
				history={history}
			/>
		)
	}
}

List.propTypes = {
	paginatedQuizzes: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
	const pageNumber = ownProps.match.params.pageNumber
	const paginatedQuizzes = state.api.resources.quizzes.data.find(
		quiz => parseInt(quiz.page, 10) === parseInt(pageNumber, 10)
	)
	return {
		paginatedQuizzes: PaginatedDataModel.fromDtoWithQuizzes(paginatedQuizzes),
		pageNumber: pageNumber
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchQuizProjects
		},
		dispatch
	)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(eventUnitsStyle),
	withIntl
)(QuizList)
