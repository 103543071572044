import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import CustomTableCell from '../../shared/components/Table/CustomTableCell'

import tableStyle from '../../shared/assets/jss/material-dashboard-pro-react/components/tableStyle'
import Tooltip from '../../shared/components/CustomTooltip/CustomTooltip'

function CustomTable({ ...props }) {
	const {
		classes,
		tableHead,
		tableData,
		tableRenderer,
		tableHeaderColor,
		hover,
		colorsColls,
		coloredColls,
		customCellClasses,
		customClassesForCells,
		striped,
		tableShopping,
		customHeadCellClasses,
		customHeadClassesForCells,
		onRowClick
	} = props

	const calculateCellClasses = rowIndex =>
		cx(classes.tableCell, {
			[classes[colorsColls[coloredColls.indexOf(rowIndex)]]]:
				coloredColls.indexOf(rowIndex) !== -1,
			[customCellClasses[customClassesForCells.indexOf(rowIndex)]]:
				customClassesForCells.indexOf(rowIndex) !== -1
		})

	const tableHeadList =
		tableRenderer && tableRenderer.some(dr => !!dr.title)
			? tableRenderer.map(dr => dr.title)
			: tableHead

	const tableRow = (row, key) => {
		let rowColor = ''
		let rowColored = false
		if (row.color !== undefined) {
			rowColor = row.color
			rowColored = true
			row = row.data
		}
		const tableRowClasses = cx({
			[classes.tableRowHover]: hover,
			[classes[rowColor + 'Row']]: rowColored,
			[classes.tableStripedRow]: striped && key % 2 === 0
		})

		if (row.total) {
			return (
				<TableRow key={key} hover={hover} className={tableRowClasses}>
					<TableCell className={classes.tableCell} colSpan={row.colspan} />
					<TableCell className={cx(classes.tableCell, classes.tableCellTotal)}>
						Total
					</TableCell>
					<TableCell className={cx(classes.tableCell, classes.tableCellAmount)}>
						{row.amount}
					</TableCell>
					{tableHead.length - (row.colspan - 0 + 2) > 0 ? (
						<TableCell
							className={classes.tableCell}
							colSpan={tableHead.length - (row.colspan - 0 + 2)}
						/>
					) : null}
				</TableRow>
			)
		}

		if (row.purchase) {
			return (
				<TableRow key={key} hover={hover} className={tableRowClasses}>
					<TableCell className={classes.tableCell} colSpan={row.colspan} />
					<TableCell
						className={cx(classes.tableCell, classes.right)}
						colSpan={row.col.colspan}
					>
						{row.col.text}
					</TableCell>
				</TableRow>
			)
		}

		if (tableRenderer) {
			const cursorStyle =
				typeof onRowClick === 'function' ? 'pointer' : 'inherit'
			return (
				<TableRow
					key={key}
					hover={hover}
					className={cx(classes.tableRow, tableRowClasses)}
					style={{ cursor: cursorStyle }}
					onClick={() => typeof onRowClick === 'function' && onRowClick(row)}
				>
					{tableRenderer.map((columnRenderer, key) => (
						<CustomTableCell
							className={calculateCellClasses(key)}
							onClick={columnRenderer.onClick && columnRenderer.onClick(row)}
							key={key}
						>
							{!columnRenderer.toolTip ? (
								columnRenderer.renderCell(row)
							) : (
								<Tooltip
									title={columnRenderer.toolTip(row)}
									placement={
										key === 0
											? 'right'
											: key === tableRenderer.length - 1
											? 'left'
											: 'bottom'
									}
								>
									{columnRenderer.renderCell(row)}
								</Tooltip>
							)}
						</CustomTableCell>
					))}
				</TableRow>
			)
		}

		return (
			<TableRow
				key={key}
				hover={hover}
				className={cx(classes.tableRow, tableRowClasses)}
			>
				{row.map((prop, key) => {
					return (
						<TableCell className={calculateCellClasses(key)} key={key}>
							{prop}
						</TableCell>
					)
				})}
			</TableRow>
		)
	}

	return (
		<div className={classes.tableResponsive}>
			<Table className={classes.table}>
				{tableHeadList !== undefined ? (
					<TableHead className={classes[tableHeaderColor]}>
						<TableRow className={classes.tableRow}>
							{tableHeadList.map((head, key) => {
								const tableCellClasses = cx(
									classes.tableHeadCell,
									classes.tableCell,
									{
										[customHeadCellClasses[
											customHeadClassesForCells.indexOf(key)
										]]: customHeadClassesForCells.indexOf(key) !== -1,
										[classes.tableShoppingHead]: tableShopping,
										[classes.tableHeadFontSize]: !tableShopping
									}
								)
								return (
									<TableCell className={tableCellClasses} key={key}>
										{head}
									</TableCell>
								)
							})}
						</TableRow>
					</TableHead>
				) : null}
				<TableBody>{tableData.map((row, key) => tableRow(row, key))}</TableBody>
			</Table>
		</div>
	)
}

CustomTable.defaultProps = {
	tableHeaderColor: 'gray',
	hover: false,
	colorsColls: [],
	coloredColls: [],
	striped: false,
	customCellClasses: [],
	customClassesForCells: [],
	customHeadCellClasses: [],
	customHeadClassesForCells: []
}

CustomTable.propTypes = {
	classes: PropTypes.object.isRequired,
	tableHeaderColor: PropTypes.oneOf([
		'warning',
		'primary',
		'danger',
		'success',
		'info',
		'rose',
		'gray'
	]),
	tableHead: PropTypes.arrayOf(PropTypes.string),
	// Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
	tableData: PropTypes.array,
	tableRenderer: PropTypes.arrayOf(PropTypes.object),
	hover: PropTypes.bool,
	coloredColls: PropTypes.arrayOf(PropTypes.number),
	// Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
	colorsColls: PropTypes.array,
	customCellClasses: PropTypes.arrayOf(PropTypes.string),
	customClassesForCells: PropTypes.arrayOf(PropTypes.number),
	customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
	customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
	striped: PropTypes.bool,
	rowToolTip: PropTypes.func,
	// this will cause some changes in font
	tableShopping: PropTypes.bool,
	onRowClick: PropTypes.func
}

export default withStyles(tableStyle)(CustomTable)
