import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { withIntl } from "@hlcr/ui/Intl"
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import Select from '@material-ui/core/Select/Select'
import FormControl from '@material-ui/core/FormControl/FormControl'

import challengeStyle from '../../../shared/assets/jss/material-dashboard-pro-react/views/challengeStyle'
import {
	challengeGoldNuggetTypes,
	GoldNugget
} from "shared/helper/challenge"
import GridContainer from '../../../shared/components/Grid/GridContainer.jsx'
import GridItem from '../../../shared/components/Grid/GridItem.jsx'
import CustomInput from '../../../shared/components/CustomInput/CustomInput'
import MediaListForMarkdown from '../../../shared/components/Media/MediaListForMarkdown'
import { ChallengeModel } from "shared/models/challenge.model"
import MarkdownInput from '../../../components/MarkdownInput/MarkdownInput'

class SolutionAndGoldNuggetStep extends React.Component {
	constructor(props) {
		super(props)
		const challenge: ChallengeModel = this.props.currentChallenge
		this.state = {
			grading: challenge.grading,
			gradingState: '',
			goldNuggetType: challenge.goldNugget.type || challengeGoldNuggetTypes[0],
			goldNuggetSecret: challenge.goldNugget.secret || '',
			goldNuggetSecretState: '',
			gradingMediaFiles: []
		}
	}

	sendState = () => {
		return this.state
	}

	validate = field => {
		let allFieldsValid = true
		if (
			((field === 'goldNuggetSecret' ||
				field === 'goldNuggetType' ||
				field === '*') &&
				this.state.goldNuggetType === 'static' &&
				this.state.goldNuggetSecret.length === 0) ||
			this.state.goldNuggetSecret.length > 100
		) {
			allFieldsValid = false
			this.setState({ goldNuggetSecretState: 'error' })
		} else {
			this.setState({ goldNuggetSecretState: 'success' })
		}
		if (field === '*' || field === 'grading') {
			if (this.state.grading.length > 0) {
				this.setState({ gradingState: 'success' })
			} else {
				allFieldsValid = false
				this.setState({ gradingState: 'error' })
			}
		}
		return allFieldsValid
	}

	isValidated = () => {
		return this.validate('*')
	}

	handleChangeGoldNuggetType = value => {
		let changeset = { goldNuggetType: value }
		if (value !== GoldNugget.STATIC) {
			changeset.goldNuggetSecret = ''
		}
		this.setState(changeset, () => this.validate('goldNuggetType'))
	}

	handleChange = event => {
		const fieldName = event.target.name
		if (fieldName === undefined) {
			console.error(event.target)
			console.error('input has no name to bind')
			return
		}
		if (event.target.value === undefined) {
			console.error(event.target)
			console.error('input has no value to bind')
			return
		}
		this.setState({ [fieldName]: event.target.value }, () =>
			this.validate(fieldName)
		)
	}

	challengeGoldNuggetTypes = classes => {
		const { intl } = this.props
		return challengeGoldNuggetTypes.map(type => (
			<MenuItem
				key={type}
				classes={{
					root: classes.selectMenuItem,
					selected: classes.selectMenuItemSelected
				}}
				value={type}
			>
				{intl.fm('challenge.goldNuggetType.' + type)}
			</MenuItem>
		))
	}

	uploadMedia = (file, callback) => {
		this.props.uploadMedia(
			file.byteData,
			file.name,
			this.props.currentChallenge.uuid,
			mediaFile => callback(mediaFile),
			`${file.name} uploaded`
		)
	}

	render() {
		const { classes, intl } = this.props
		return (
			<div>
				<h4 className={classes.infoText}>
					Define a {intl.fm('challenge.gold-nugget')} for automatic grading
				</h4>
				<GridContainer justify="center">
					<GridItem xs={12} sm={12} md={12} lg={10}>
						<GridContainer>
							<GridItem
								xs={12}
								sm={this.state.goldNuggetType === GoldNugget.STATIC ? 6 : 12}
							>
								<FormControl fullWidth className={classes.selectFormControl}>
									<InputLabel
										htmlFor="simple-select"
										className={classes.selectLabel}
									>
										Choose {intl.fm('challenge.gold-nugget')} Type
									</InputLabel>
									<Select
										MenuProps={{
											className: classes.selectMenu
										}}
										classes={{
											select: classes.select
										}}
										value={this.state.goldNuggetType}
										onChange={event =>
											this.handleChangeGoldNuggetType(event.target.value)
										}
										inputProps={{
											name: 'goldNuggetType',
											id: 'goldNuggetType'
										}}
									>
										<MenuItem
											disabled
											classes={{
												root: classes.selectMenuItem
											}}
										>
											Choose Challenge Type
										</MenuItem>
										{this.challengeGoldNuggetTypes(classes)}
									</Select>
								</FormControl>
							</GridItem>
							{this.state.goldNuggetType === GoldNugget.STATIC && (
								<GridItem xs={12} sm={6}>
									<CustomInput
										//hidden={this.state.goldNuggetType !== 'static'}
										success={this.state.goldNuggetSecretState === 'success'}
										error={this.state.goldNuggetSecretState === 'error'}
										labelText={<span>{intl.fm('challenge.gold-nugget')}</span>}
										id="secret"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											name: 'goldNuggetSecret',
											id: 'goldNuggetSecret',
											onChange: this.handleChange,
											value: this.state.goldNuggetSecret,
										}}
									/>
								</GridItem>
							)}
						</GridContainer>
						<h4 className={classes.infoText}>
							Grading Instructions (required)
						</h4>
						<GridContainer>
							<GridItem xs={12} sm={3}>
								<MediaListForMarkdown
									uploadedMediaFiles={this.state.gradingMediaFiles}
									uploadMedia={this.uploadMedia}
								/>
							</GridItem>
							<GridItem xs={12} sm={9}>
								<div className={classes.paperBorder}>
									<MarkdownInput
										name="grading"
										placeholder="Grading Instructions"
										onChange={this.handleChange}
										content={this.state.grading}
										headingOffset={2}
										success={this.state.gradingState === 'success'}
										error={this.state.gradingState === 'error'}
									/>
								</div>
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div>
		)
	}
}

// SolutionAndGoldNuggetStep.propTypes = {
// 	classes: PropTypes.object.isRequired,
// 	uploadMedia: PropTypes.func.isRequired
// }
//
// const mapDispatchToProps = dispatch =>
// 	bindActionCreators({ uploadMedia }, dispatch)
//
// SolutionAndGoldNuggetStep = compose(
// 	connect(
// 		mapDispatchToProps
// 	),
// 	withStyles(challengeStyle),
// 	withIntl
// )(SolutionAndGoldNuggetStep)
//
// export default SolutionAndGoldNuggetStep

export default withIntl(withStyles(challengeStyle)(SolutionAndGoldNuggetStep))
