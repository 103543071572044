const challengeTypes = ['jeopardy', 'ctf', 'training']

const challengeLevels = {
	1: { name: 'novice', color: '#23CCEF' },
	2: { name: 'easy', color: '#87cb16' },
	3: { name: 'medium', color: '#FFA534' },
	4: { name: 'hard', color: '#FB404B' },
	5: { name: 'leet', color: '#444444' }
}

const GoldNugget = {
	NONE: 'none',
	STATIC: 'static',
	DYNAMIC: 'dynamic'
}

const challengeGoldNuggetTypeLabels = {
	none: { name: GoldNugget.NONE.toUpperCase(), color: '#444444' },
	static: { name: GoldNugget.STATIC.toUpperCase(), color: '#FFA534' },
	dynamic: { name: GoldNugget.DYNAMIC.toUpperCase(), color: '#87cb16' }
}

const challengeGoldNuggetTypes = [
	GoldNugget.NONE,
	GoldNugget.STATIC,
	GoldNugget.DYNAMIC
]

export {
	challengeTypes,
	challengeLevels,
	GoldNugget,
	challengeGoldNuggetTypes,
	challengeGoldNuggetTypeLabels
}
