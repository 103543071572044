import de from '../../localization/messages/de'
import en from '../../localization/messages/en'

export function getLocaleId() {
	return 'en' // navigator.language === 'de-DE' ? 'de' : navigator.language;
}

export function getTranslation() {
	switch (getLocaleId().split('-')[0]) {
		case 'de':
			return de
		case 'en':
			return en
		default:
			return en
	}
}
