import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridContainer from '../../../shared/components/Grid/GridContainer.jsx'
import GridItem from '../../../shared/components/Grid/GridItem.jsx'
import challengeStyle from '../../../shared/assets/jss/material-dashboard-pro-react/views/challengeStyle'
import { Button } from "@hlcr/mui/Button";
import {SectionModel} from "shared/models/section.model"
import {TheoryModel} from "shared/models/theory.model"
import MediaListForMarkdown from '../../../shared/components/Media/MediaListForMarkdown'
import MarkdownInput from '../../../components/MarkdownInput/MarkdownInput'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import CustomIconButton from '../../../components/Button/CustomIconButton'
import StepsAccordion from '../../../components/Accordion/StepsAccordion'

class SectionsStep extends React.Component {
    constructor(props) {
        super(props)
        const theory: TheoryModel = this.props.currentTheory
        this.state = {
            sections: theory.sections.slice(0),
            activeSection: 0,
            sectionMediaFiles: []
        }
    }

    sendState() {
        return this.state
    }

    handleChange = (event, sectionIndex) => {
        const sections = this.state.sections
        const name = event.target.name
        const value = event.target.value
        if (sectionIndex !== undefined) {
            sections[sectionIndex].steps[name].content = value
        } else {
            sections[name].content = value
        }
        this.setState({sections: sections})
    }

    handleBlur = () => {
        this.setState({sections: this.state.sections.slice(0)})
    }

    handleDelete = sectionIndex => {
        const sections = this.state.sections.slice(0)
        sections.splice(sectionIndex, 1)
        this.setState({sections, activeSection: sectionIndex === sections.length ? sectionIndex - 1 : sectionIndex})
    }

    moveUpInArray = (array, elementIndex) => {
        const element = array[elementIndex]
        array.splice(elementIndex, 1)
        array.splice(elementIndex - 1, 0, element)
    }

    moveDownInArray = (array, elementIndex) => {
        const element = array[elementIndex]
        array.splice(elementIndex, 1)
        array.splice(elementIndex + 1, 0, element)
    }

    handleMoveUp = sectionIndex => {
        const sections = this.state.sections.slice(0)
        let activeSection = this.state.activeSection
        if (sectionIndex > 0) {
            this.moveUpInArray(sections, sectionIndex)
            activeSection = activeSection - 1
            this.setState({sections, activeSection})
        }
    }

    handleMoveDown = sectionIndex => {
        const sections = this.state.sections.slice(0)
        let activeSection = this.state.activeSection
        if (sectionIndex < sections.length - 1) {
            this.moveDownInArray(sections, sectionIndex)
            activeSection = activeSection + 1
            this.setState({sections, activeSection})
        }
    }

    uploadMedia = (file, callback) => {
        this.props.uploadMedia(
            file.byteData,
            file.name,
            this.props.currentTheory.uuid,
            mediaFile => callback(mediaFile),
            `${file.name} uploaded`
        )
    }

    handleSectionAccordionChange = (panel: number) => (event, expanded) => {
        this.setState({activeSection: expanded ? panel : -1})
    }

    sectionsList = classes => {
        const {activeSection, sections} = this.state
        if (sections && sections.length > 0) {
            return (
                <StepsAccordion
                    overrideActiveSectionIndex={activeSection}
                    onChange={this.handleSectionAccordionChange}
                    sections={sections.map((section: SectionModel, index: number) => ({
                        title: (`Section ${index + 1}`),
                        content: (
                            <div style={{width: '100%', overflow: 'hidden'}}>
                                <MarkdownInput
                                    placeholder={`Section ${section.uuid}`}
                                    name={index}
                                    content={section.content}
                                    onChange={event => this.handleChange(event)}
                                    headingOffset={2}
                                    plainTabs={true}
                                />
                            </div>
                        ),
                        actions: (
                            <div className={classes.stepActions}>
                                <CustomIconButton disabled={index === 0} onClick={() => this.handleMoveUp(index)}>
                                    <ArrowUpwardIcon className={classes.buttonIcon} fontSize="small"/>
                                </CustomIconButton>
                                <CustomIconButton disabled={index + 1 === this.state.sections.length} onClick={() => this.handleMoveDown(index)}>
                                    <ArrowDownwardIcon className={classes.buttonIcon} fontSize="small"/>
                                </CustomIconButton>
                                <CustomIconButton onClick={() => this.handleDelete(index)}>
                                    <DeleteIcon className={classes.buttonIconDanger} fontSize="small"/>
                                </CustomIconButton>
                                <CustomIconButton onClick={() => this.handleAddNewSectionAtPosition(index)}>
                                    <AddIcon className={classes.buttonIconInfo} fontSize="small"/>
                                </CustomIconButton>
                            </div>
                        )
                    }))}
                />
            )
        } else {
            return (
                <div className={classes.actionsCenter}>
                    <Button variant="contained" color="primary" onClick={this.handleAddNewSectionAtEnd}>
                        Add new Section
                    </Button>
                </div>
            )
        }
    }

    handleAddNewSectionAtEnd = () => {
        const sections = this.state.sections.slice(0)
        sections.push(new SectionModel('', this.state.sections.length + 1, '', '', []))
        this.setState({sections: sections, activeSection: sections.length - 1})
    }

    handleAddNewSectionAtPosition = (positionIndex: number) => {
        const sections = this.state.sections.slice(0)
        if (positionIndex < sections.length) {
            sections.splice(positionIndex + 1, 0, new SectionModel('', positionIndex + 1, '', '', []))
            sections.map((section, index) => (section.order = index + 1))
            const activeSection = positionIndex + 1
            this.setState({sections: sections, activeSection})
        }
    }

    render() {
        const {classes} = this.props
        return (
            <div>
                <h4 className={classes.infoText}>Sections</h4>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={3}>
                        <MediaListForMarkdown uploadedMediaFiles={this.state.sectionMediaFiles} uploadMedia={this.uploadMedia}/>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                        {this.sectionsList(classes)}
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

export default withStyles(challengeStyle)(SectionsStep)
