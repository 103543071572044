import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { StyledMarkdown } from "@hlcr/ui";

import { answerColors, getCorrectState } from "shared/helper/quiz";
import CustomRadio from "shared/components/CustomSwitch/CustomRadio";

const styles = {
	...Object.assign(
		{},
		...Object.keys(answerColors).map(k => ({
			["root" + k]: {
				color: answerColors[k] + "FF !important",
				"&$checked": {
					color: answerColors[k],
				},
			},
			["disabled" + k]: {
				color: answerColors[k] + "FF !important",
			},
		})),
	),
	label: {
		"& > div > p": { margin: 0 },
	},
	checked: {},
};

class SingleChoiceQuestion extends React.Component {
	onChange = event => {
		const value = event.target.value;
		const { onChange } = this.props;

		const checkedOptions = [ { selectedOptionUuid: value } ];
		onChange(checkedOptions);
	};

	render() {
		const { question, disabled, classes } = this.props;
		let options = question.options ? question.options.slice(0) : [];
		if (options.length > 0 && disabled) {
			options = options.map(mco =>
				                      Object.assign({}, mco, {
					                      customRadioClasses: {
						                      root: classes[`root${getCorrectState(mco.correct)}`],
						                      disabled: classes[`disabled${getCorrectState(mco.correct)}`],
					                      },
					                      labelStyle: classes.label,
				                      }),
			);
		} else {
			options = options.map(mco =>
				                      Object.assign({}, mco, { labelStyle: classes.label }),
			);
		}

		const answer = options.find(o => o.correct);

		return (
			<div>
				{question.options && (
					<CustomRadio
						disabled={disabled}
						value={answer}
						onChange={this.onChange}
						options={options}
						valueGetter={o => o.order}
						labelGetter={o => <StyledMarkdown source={o.content} />}
					/>
				)}
			</div>
		);
	}
}

export default withStyles(styles)(SingleChoiceQuestion);
