import withStyles from "@material-ui/core/styles/withStyles";

import { Accordion } from "@hlcr/mui";
import { COLOR_ROSE } from "@hlcr/mui/theme/hacking-lab.theme";

const backgroundColor = '#fff'
const border = '1px solid #eee'

export default withStyles({
	root: {
		flexGrow: 1,
		marginBottom: '20px'
	},
	expansionPanel: {
		boxShadow: 'none',
		'&:before': {
			display: 'none !important'
		}
	},
	expansionPanelExpanded: {
		margin: '0',
		marginBottom: 20
	},
	expansionPanelSummary: {
		border: border,
		backgroundColor: backgroundColor,
		minHeight: 'auto !important',
		padding: '10px 5px',
		borderRadius: '3px',
		color: '#3C4858',
		'&:hover': {
			color: COLOR_ROSE,
			backgroundColor: '#f3f3f3'
		}
	},
	expansionPanelSummaryExpaned: {
		borderBottom: 'none',
		color: COLOR_ROSE,
		margin: 0
	},
	expansionPanelSummaryContent: {
		margin: '0 !important'
	},
	expansionPanelSummaryExpandIcon: {
		color: 'inherit',
		height: 16,
		width: 16,
		top: 'auto',
		bottom: 0
	},
	title: {
		fontSize: '15px',
		fontWeight: 'bolder',
		marginTop: '0',
		marginBottom: '0',
		color: 'inherit',
		width: "100%"
	},
	expansionPanelDetails: {
		marginBottom: '0',
		padding: '0',
		backgroundColor: backgroundColor,
		border: border,
		borderTop: 'none',
		borderBottomLeftRadius: '0',
		borderBottomRightRadius: '0'
	},
	expansionPanelDetailsBorderBottom: {
		borderBottom: '1px solid #ddd'
	},
	expansionPanelActions: {
		padding: '5px 10px',
		backgroundColor: backgroundColor,
		border: border,
		borderTop: 'none',
		borderBottomLeftRadius: '3px',
		borderBottomRightRadius: '3px'
	},
	expansionPanelActionsBorderBottom: {
		borderBottom: '1px solid #ddd'
	}
})(Accordion)
