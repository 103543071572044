import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { primaryColor } from '../../assets/jss/material-dashboard-pro-react.jsx'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const styles = {
	radio: {
		color: primaryColor
	},
	switchBase: {
		color: primaryColor,
		'&$checked': {
			color: primaryColor,
			'& + $colorBar': {
				backgroundColor: primaryColor
			}
		}
	},
	colorBar: {},
	checked: {},
	icon: {
		border: '1px solid',
		borderColor: primaryColor,
		boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.4)',
		color: '#FFFFFF !important',
		transform: 'translateX(-4px)!important'
	},
	iconChecked: {
		borderColor: primaryColor,
		transform: 'translateX(4px)!important'
	}
}

const CustomRadio = ({
	value,
	options,
	onChange,
	disabled,
	valueGetter,
	labelGetter,
	classes
}) => (
	<RadioGroup
		aria-label="Gender"
		name="gender1"
		value={`${valueGetter ? valueGetter(value) : value}`}
		onChange={onChange}
		disabled={disabled}
	>
		{options.map((option, i) => (
			<FormControlLabel
				key={i}
				disabled={disabled}
				value={`${valueGetter ? valueGetter(option) : option.value}`}
				control={
					<Radio
						classes={option.customRadioClasses || { root: classes.radio }}
					/>
				}
				label={labelGetter ? labelGetter(option) : option.label}
				classes={{ label: option.labelStyle }}
			/>
		))}
	</RadioGroup>
)
CustomRadio.propTypes = {
	classes: PropTypes.object.isRequired,
	value: PropTypes.any,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	valueGetter: PropTypes.func,
	labelGetter: PropTypes.func
}

export const RadioBox = ({ disabled, checked, label, classes, onChange }) => (
	<FormControlLabel
		disabled={disabled}
		onChange={onChange}
		checked={checked}
		control={<Radio />}
		label={label}
		classes={classes}
	/>
)

export default withStyles(styles)(CustomRadio)
