import { grayColor } from 'shared/assets/jss/material-dashboard-pro-react.jsx'

const levelLabelStyle = {
	root: {
		display: 'inline-block',
		borderRadius: 100,
		background: grayColor,
		padding: '4px 8px',
		lineHeight: '1em',
		color: '#fff'
	}
}

export default levelLabelStyle
