import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import customSelectStyle from '../../assets/jss/material-dashboard-pro-react/customSelectStyle'
import PropTypes from 'prop-types'
import cx from 'classnames'

const CustomSelect = ({
	value,
	list,
	onSelect,
	title,
	displayField,
	idField,
	classes,
	additionalClasses,
	selectClassName
}) => {
	return (
		<Select
			MenuProps={{
				className: classes.selectMenu
			}}
			className={selectClassName}
			classes={{
				...additionalClasses,
				select: cx(
					classes.select,
					additionalClasses ? additionalClasses.select : null
				)
			}}
			value={value ? value[idField] : ''}
			renderValue={_ => (value ? value[displayField] : 'unknown')}
			onChange={onSelect}
		>
			{title && (
				<MenuItem
					disabled
					classes={{
						root: classes.selectMenuItem
					}}
				>
					{title}
				</MenuItem>
			)}
			{list.map((value, key) => (
				<MenuItem
					key={key}
					value={value[idField]}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
				>
					{value[displayField]}
				</MenuItem>
			))}
		</Select>
	)
}

CustomSelect.propTypes = {
	classes: PropTypes.object.isRequired,
	additionalClasses: PropTypes.object,
	selectClassName: PropTypes.string,
	value: PropTypes.object,
	list: PropTypes.arrayOf(PropTypes.object).isRequired,
	onSelect: PropTypes.func.isRequired,
	title: PropTypes.node,
	displayField: PropTypes.string.isRequired,
	idField: PropTypes.string.isRequired
}

export default withStyles(customSelectStyle)(CustomSelect)
