// @flow
import type { Dispatch } from 'redux'
import type { Action } from '.'
import type { Dto } from '../reducers/'
import type { MessageTypeType } from '../models/Message'
import { MessageType } from '../models/Message'

export const API_REQUEST: 'API_REQUEST' = 'API_REQUEST'
export const API_RESULT: 'API_RESULT' = 'API_RESULT'
export const API_REMOVE: 'API_REMOVE' = 'API_REMOVE'
export const SAVE_AUTH_TOKEN: 'SAVE_AUTH_TOKEN' = 'SAVE_AUTH_TOKEN'
export const ADD_NOTIFICATION: 'ADD_NOTIFICATION' = 'ADD_NOTIFICATION'
export const REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION' = 'REMOVE_NOTIFICATION'

export type UpdatePropForObj = {
	idSelector: (dto: Dto) => string | number
}

export type UpdatePropForArray = {
	key: string | ((dto: Dto) => string | number),
	value: string | number
}

export type UpdateField = {
	key: string,
	entityId: string | number
}

export type AddField = {
	key: string,
	value: any
}

export type ApiRequestAction = {
	type: typeof API_REQUEST,
	url: string,
	method: 'get' | 'post' | 'put' | 'delete',
	resource?: string,
	updatePropForArray?: UpdatePropForArray,
	updatePropForObj?: UpdatePropForObj,
	updateField?: UpdateField,
	setFieldOnObject?: AddField,
	body?: Object | Object[],
	nonJsonBody?: any,
	entity?: Object | Object[],
	onSuccess?: (dispatch: Dispatch<Action>, result: any) => void,
	onBefore?: (dispatch: Dispatch<Action>) => void,
	onFailure?: (dispatch: Dispatch<Action>) => void,
	successNotification?: boolean | string
}

export type SaveApiResultAction = {
	type: typeof API_RESULT,
	resource: string,
	data?: Object | Object[] | null,
	updatePropForArray?: UpdatePropForArray,
	updatePropForObj?: UpdatePropForObj,
	updateField?: UpdateField,
	setFieldOnObject?: AddField
}

export const saveApiResult = (
	resource: string,
	data?: Object | Object[] | null,
	updatePropForArray?: UpdatePropForArray,
	updatePropForObj?: UpdatePropForObj,
	updateField?: UpdateField,
	setFieldOnObject?: AddField
): SaveApiResultAction => ({
	type: API_RESULT,
	resource,
	data,
	updatePropForArray,
	updatePropForObj,
	updateField,
	setFieldOnObject
})

export type RemoveApiSuccessAction = {
	type: typeof API_REMOVE,
	resource: string,
	entity: Object | Object[],
	updatePropForObj?: UpdatePropForObj
}

export const removeApiSuccess = (
	resource: string,
	entity: Object | Object[],
	updatePropForObj?: UpdatePropForObj
): RemoveApiSuccessAction => ({
	type: API_REMOVE,
	resource,
	entity,
	updatePropForObj
})

export type AddNotificationAction = {
	type: typeof ADD_NOTIFICATION,
	id: string,
	message: string,
	messageType: MessageTypeType
}

export const addNotification = (
	id: string,
	message: string,
	messageType: MessageTypeType = MessageType.ERROR
): AddNotificationAction => ({
	type: ADD_NOTIFICATION,
	id,
	message,
	messageType
})

export type RemoveNotificationAction = {
	type: typeof REMOVE_NOTIFICATION,
	id: string
}

export const removeNotification = (id: string): RemoveNotificationAction => ({
	type: REMOVE_NOTIFICATION,
	id
})
