/* eslint-disable */
if (!String.prototype.startsWith) {
	String.prototype.startsWith = function(search, pos) {
		return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search
	}
}

if (!Array.prototype.find) {
	Object.defineProperty(Array.prototype, 'find', {
		value: function(predicate) {
			if (this == null) {
				throw new TypeError('"this" is null or not defined')
			}

			var o = Object(this)

			var len = o.length >>> 0

			if (typeof predicate !== 'function') {
				throw new TypeError('predicate must be a function')
			}

			var thisArg = arguments[1]

			var k = 0

			while (k < len) {
				var kValue = o[k]
				if (predicate.call(thisArg, kValue, k, o)) {
					return kValue
				}
				k++
			}

			return undefined
		},
		configurable: true,
		writable: true
	})
}

if (!Array.prototype.includes) {
	Object.defineProperty(Array.prototype, 'includes', {
		value: function(searchElement, fromIndex) {
			if (this == null) {
				throw new TypeError('"this" is null or not defined')
			}

			var o = Object(this)

			var len = o.length >>> 0

			if (len === 0) {
				return false
			}

			var n = fromIndex | 0

			var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)

			function sameValueZero(x, y) {
				return (
					x === y ||
					(typeof x === 'number' &&
						typeof y === 'number' &&
						isNaN(x) &&
						isNaN(y))
				)
			}

			while (k < len) {
				if (sameValueZero(o[k], searchElement)) {
					return true
				}
				k++
			}

			return false
		}
	})
}
/* eslint-enable */
