import * as React from "react";

import withStyles from '@material-ui/core/styles/withStyles'
import BuildIcon from '@material-ui/icons/Build'

import { StyledMarkdown } from "@hlcr/ui";
import { Accordion } from "@hlcr/mui";

import NoData from 'shared/components/NoData/NoData'
import RegularCard from 'shared/components/Cards/RegularCard'
import ItemGrid from 'shared/components/Grid/ItemGrid'
import GridContainer from 'shared/components/Grid/GridContainer'
import IconCard from 'shared/components/Cards/IconCard'
import challengeStyle from 'shared/assets/jss/material-dashboard-pro-react/views/challengeStyle'

import InfoSection from './Review/InfoSection'

class ReviewAndSaveStep extends React.Component {
	sendState() {
		return this.state
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.checked })
	}

	isValidated() {
		return true
	}

	resolveTitleImageSource = (url, uploaded) => {
		if (uploaded && uploaded.data) {
			return `data:image/png;base64, ${uploaded.data}`
		}
		return url
	}

	render() {
		const { getStepStates, mergeWizardSteps, intl, classes } = this.props
		const stepStates = getStepStates()
		const titleImage =
			stepStates &&
			stepStates['general'] &&
			stepStates['general'].titleImageFile
		const theory = mergeWizardSteps(stepStates)
		return theory ? (
			<div>
				<GridContainer>
					<ItemGrid xs={12} sm={12} md={12} lg={6}>
						<IconCard
							icon={BuildIcon}
							title={intl.fm('theory.name')}
							iconColor="purple"
							content={
								<div>
									{titleImage !== null && (
										<div className={classes.imageContainer}>
											<img
												className={classes.image}
												src={this.resolveTitleImageSource(
													`/api${theory.titleImageUrl}`,
													titleImage
												)}
												alt={theory.title}
											/>
										</div>
									)}
									{theory.abstract ? (
										<StyledMarkdown
											source={theory.abstract}
											headingOffset={2}
										/>
									) : (
										<NoData text={intl.fm('theory.nodata.content')} />
									)}
								</div>
							}
						/>
					</ItemGrid>
					<ItemGrid xs={12} sm={12} md={12} lg={6}>
						<InfoSection theory={theory} unitType={intl.fm('theory.name')} />
					</ItemGrid>
					<ItemGrid xs={12} sm={12} md={12} lg={12}>
						<h2>Sections</h2>
						<Panel theory={theory} intl={intl} />
					</ItemGrid>
				</GridContainer>
			</div>
		) : (
			<NoData />
		)
	}
}

const Panel = ({ theory, intl }) => {
	if (theory.sections && theory.sections.length > 0) {
		return theory.sections.map((section, index) => (
			<RegularCard
				key={section.uuid}
				content={
					<DescriptionContent
						section={section}
						index={index}
						theoryUuid={theory.uuid}
					/>
				}
			/>
		))
	} else {
		return <NoData text={intl.fm('theory.nodata.content')} />
	}
}

const DescriptionContent = ({ section, index, theoryUuid }) =>
	section && section.steps ? (
		<div>
			<StyledMarkdown
				source={section.content}
				headingOffset={2}
			/>
			{section.steps && section.steps.length
				? section.steps.map((step, stepIndex) => (
						<Accordion
							key={step.uuid}
							sections={[{
								title: <span>Step {stepIndex + 1}</span>,
								content: (
									<StyledMarkdown
										source={step.content}
										headingOffset={3}
									/>
								)
							}]}
						/>
				  ))
				: null}
		</div>
	) : (
		<NoData text="NoData" />
	)

export default withStyles(challengeStyle)(ReviewAndSaveStep)
