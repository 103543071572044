// @flow
import type { ApiRequestAction } from '../shared/actions/api'
import { API_REQUEST } from "shared/actions/api"

import type { PaginatedDataModelDto } from '../shared/models/paginatedData.model'
import type { ProjectModel } from '../shared/models/project.model'
import { PipelineResultModel } from "shared/models/pipelineResult.model"

export const fetchProjectsBase = (pageNumber: number): ApiRequestAction => ({
	type: API_REQUEST,
	method: 'get',
	updatePropForArray: {
		key: 'pageNumber',
		value: pageNumber
	},
	updatePropForObj: {
		idSelector: (paginatedProjects: PaginatedDataModelDto) =>
			paginatedProjects.page
	}
})

export const fetchSingleBase: ApiRequestAction = {
	type: API_REQUEST,
	method: 'get',
	updatePropForObj: {
		idSelector: (project: ProjectModel) => project.uuid
	}
}

export const fetchSearchBase = (search: string): ApiRequestAction => ({
	type: API_REQUEST,
	method: 'get',
	updatePropForArray: {
		key: 'search',
		value: search
	},
	updatePropForObj: {
		idSelector: (paginatedProjects: PaginatedDataModelDto) =>
			paginatedProjects.page
	}
})

export const createProjectBase = (callback, modelClass): ApiRequestAction => ({
	type: API_REQUEST,
	method: 'post',
	updatePropForObj: {
		idSelector: (project: ProjectModel) => project.uuid
	},
	onSuccess: (dispatch, project) => callback(modelClass.fromDto(project)),
	onFailure: () => callback()
})

export const cloneProjectBase = (
	projectUuid,
	callback,
	modelClass
): ApiRequestAction => ({
	type: API_REQUEST,
	method: 'post',
	updatePropForObj: {
		idSelector: (project: ProjectModel) => project.uuid
	},
	onSuccess: (dispatch, project) => callback(modelClass.fromDto(project)),
	onFailure: () => callback()
})

export const deployProjectBase = (
	projectUuid,
	callback,
	deployClass
): ApiRequestAction => ({
	type: API_REQUEST,
	method: 'post',
	updatePropForObj: {
		idSelector: (project: ProjectModel) => project.uuid
	},
	onSuccess: (dispatch, project) => callback(deployClass.fromDto(project)),
	onFailure: () => callback()
})

export const fetchPipelineBase = (
	pipelineId,
	callback,
	pipelineResultClass
): ApiRequestAction => ({
	type: API_REQUEST,
	method: 'get',
	updatePropForObj: {
		idSelector: (pipelineResult: PipelineResultModel) =>
			pipelineResult.pipelineId
	},
	onSuccess: (dispatch, pipelineResult) =>
		callback(pipelineResultClass.fromDto(pipelineResult)),
	onFailure: () => callback()
})

export const updateProjectBase = (
	uuid: string,
	project: ProjectModel,
	titleImageFile?: any,
	callback: Function,
	failCallback: Function,
	uploadTitleImage: ApiRequestAction,
	removeTitleImage: ApiRequestAction
): ApiRequestAction => ({
	type: API_REQUEST,
	body: project.toDto(),
	method: 'put',
	updatePropForObj: {
		idSelector: (project: ProjectModel) => project.uuid
	},
	onSuccess: (dispatch, result: any) => {
		if (titleImageFile && titleImageFile.byteData) {
			dispatch(
				uploadTitleImage(
					titleImageFile.byteData,
					'title',
					uuid,
					() => callback(),
					'Title image uploaded'
				)
			)
		} else if (titleImageFile === null && project.titleImageUrl !== '') {
			dispatch(
				removeTitleImage('title', uuid, () => callback(), 'Title image deleted')
			)
		} else {
			callback()
		}
	},
	onFailure: () => failCallback()
})

export const uploadTitleImageBase = (
	file: any,
	onSuccess?: (string | number) => void,
	successNotification?: string | boolean
): ApiRequestAction => ({
	type: API_REQUEST,
	nonJsonBody: file,
	method: 'post',
	onSuccess: (dispatch, mediaFile) => onSuccess && onSuccess(mediaFile),
	successNotification
})

export const removeTitleImageBase = (
	onSuccess?: () => void,
	successNotification?: string | boolean
): ApiRequestAction => ({
	type: API_REQUEST,
	method: 'delete',
	onSuccess: () => onSuccess && onSuccess(),
	successNotification
})

export const uploadMediaBase = (
	fileData: any,
	callback?: (string | number) => void,
	successNotification?: string | boolean
): ApiRequestAction => ({
	type: API_REQUEST,
	nonJsonBody: fileData,
	method: 'post',
	onSuccess: (dispatch, mediaFile) => callback && callback(mediaFile),
	successNotification
})
