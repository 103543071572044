import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import PaginatedProjects from "../../components/PaginatedProjects/PaginatedProjects";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { cloneChallenge, createChallenge, deployChallenge, fetchChallengePipeline, fetchChallengeProjects } from "actions/challenge.actions";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import eventUnitsStyle from "../../shared/assets/jss/material-dashboard-pro-react/views/eventUnitsStyle";
import { withIntl } from "@hlcr/ui/Intl";

const LoadingModal = ({ title, onConfirm }) => (
	<SweetAlert title={title} onConfirm={onConfirm} showConfirm={false}>
		<CircularProgress />
	</SweetAlert>
)

class List extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			alert: null
		}
	}

	showLoadingModal = title => {
		this.setState({
			alert: <LoadingModal title={title} onConfirm={() => this.hideAlert()} />
		})
	}

	hideAlert = () => {
		this.setState({
			alert: null
		})
	}

	handleNewChallenge = () => {
		this.showLoadingModal(
			`Creating new ${this.props.intl.fm(`${this.props.languageKey}.name`)}`
		)
		this.props.createChallenge(this.handleChallengeCreated)
	}

	handleCloneChallenge = projectUuid => {
		this.showLoadingModal(
			`Cloning ${this.props.intl.fm(`${this.props.languageKey}.name`)}`
		)
		this.props.cloneChallenge(projectUuid, this.handleChallengeCreated)
	}

	handleDeployChallenge = (projectUuid, callback) => {
		this.showLoadingModal(
			`Deploying ${this.props.intl.fm(`${this.props.languageKey}.name`)}`
		)
		this.props.deployChallenge(projectUuid, result => {
			this.handleChallengeCreated(result)
			if (callback) {
				callback(result)
			}
		})
	}

	handleChallengeCreated = (result) => {
		if (result && result.uuid)
			this.props.history.push(`/${this.props.pathSegment}/${result.uuid}`);
		this.hideAlert();
	};

	handleFetchChallengePipeline = (pipelineId, callback) => {
		this.props.fetchChallengePipeline(pipelineId, result => {
			if (callback) {
				callback(result)
			}
		})
	}

	render() {
		const {
			paginatedChallengesPending,
			paginatedChallenges,
			triggerChange,
			fetchPage,
			languageKey,
			pathSegment,
			inputSearchValue,
			history,
			intl,
			classes,
			...other
		} = this.props

		return paginatedChallengesPending ? (
			<LoadingModal
				title={intl.fm(`${this.props.languageKey}.loadingListText`)}
				onConfirm={() => this.hideAlert()}
			/>
		) : (
			<div>
				{this.state.alert}
				<PaginatedProjects
					triggerChange={triggerChange}
					inputSearchValue={inputSearchValue}
					fetchPage={fetchPage}
					createNewProject={this.handleNewChallenge}
					cloneProject={this.handleCloneChallenge}
					deployProject={this.handleDeployChallenge}
					fetchPipeline={this.handleFetchChallengePipeline}
					paginatedProjects={paginatedChallenges}
					languageKey={languageKey}
					pathSegment={pathSegment}
					history={history}
					intl={intl}
					{...other}
				/>
			</div>
		)
	}
}

List.propTypes = {
	paginatedChallenges: PropTypes.object,
	fetchChallengeProjects: PropTypes.func.isRequired,
	createChallenge: PropTypes.func.isRequired,
	cloneChallenge: PropTypes.func.isRequired,
	deployChallenge: PropTypes.func.isRequired,
	fetchChallengePipeline: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		paginatedChallengesPending: state.api.resources.challenges.pending,
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			createChallenge,
			cloneChallenge,
			deployChallenge,
			fetchChallengePipeline,
			fetchChallengeProjects
		},
		dispatch
	)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(eventUnitsStyle),
	withIntl
)(List)
