import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { primaryColor } from '../../assets/jss/material-dashboard-pro-react.jsx'

const styles = {
	root: {
		color: primaryColor,
		'&$checked': {
			color: primaryColor
		}
	},
	checked: {},
	disabled: {
		color: primaryColor + '70 !important'
	}
}

const CustomCheckbox = ({
	checked,
	onChange,
	disabled,
	label,
	labelStyle,
	classes
}) => (
	<FormControlLabel
		disabled={disabled}
		control={
			<Checkbox
				tabIndex={-1}
				checked={checked}
				onChange={onChange}
				classes={{
					checked: classes.checked,
					root: classes.root,
					disabled: classes.disabled
				}}
			/>
		}
		classes={{
			label: labelStyle
		}}
		label={label}
	/>
)
CustomCheckbox.propTypes = {
	classes: PropTypes.object.isRequired,
	checked: PropTypes.bool.isRequired,
	disabled: PropTypes.bool,
	labelStyle: PropTypes.string,
	onChange: PropTypes.func
}

export default withStyles(styles)(CustomCheckbox)
