// @flow
export type ChallengeLevelDto = '1' | '2' | '3' | '4' | '5'

export type ChallengeLevelDataModel = {
	id: number,
	name: string,
	color: string
}

export const challengeLevels: ChallengeLevelDataModel[] = [
	{ id: 1, name: 'novice', color: '#23CCEF' },
	{ id: 2, name: 'easy', color: '#87cb16' },
	{ id: 3, name: 'medium', color: '#FFA534' },
	{ id: 4, name: 'hard', color: '#FB404B' },
	{ id: 5, name: 'leet', color: '#444444' }
]

export class ChallengeLevelModel {
	id: ChallengeLevelDto
	name: string
	color: string

	constructor(id: ChallengeLevelDto, name: string, color: string) {
		this.id = id
		this.name = name
		this.color = color
	}

	static fromData(data: ChallengeLevelDataModel) {
		return new ChallengeLevelModel(data.id, data.name, data.color)
	}

	static fromDto(dto: ChallengeLevelDto): ChallengeLevelModel {
		if (!dto) {
			return ChallengeLevelModel.fromData(challengeLevels[0])
		}
		const challengeLevel: ChallengeLevelDataModel = challengeLevels.find(
			e => e.id === parseInt(dto, 10)
		)
		if (challengeLevel) {
			return new ChallengeLevelModel(
				challengeLevel.id,
				challengeLevel.name,
				challengeLevel.color
			)
		} else {
			console.warn(
				`[ChallengeLevelModel.fromDto] unknown challengeLevel: ${dto}`
			)
			return ChallengeLevelModel.fromData(challengeLevels[0])
		}
	}

	toDto(): ChallengeLevelDto {
		return this.id
	}
}
