import * as PropTypes from 'prop-types';
import cx from 'classnames';
// material-ui components
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
// material-ui-icons
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';

import customInputStyle from '../../assets/jss/material-dashboard-pro-react/components/customInputStyle';

function CustomInput({ ...props }) {
	const {
		classes,
		formControlProps,
		labelText,
		id,
		labelProps,
		inputProps,
		inheritWidth,
		error,
		white,
		inputRootCustomClasses,
		success,
		helpText,
		inputRef
	} = props;

	const labelClasses = cx({
		[classes.labelRootError]: error,
		[classes.labelRootSuccess]: success && !error
	});

	const underlineClasses = cx({
		[classes.underlineError]: error,
		[classes.underlineSuccess]: success && !error,
		[classes.underline]: true,
		[classes.whiteUnderline]: white
	});
	const successClasses = cx(classes.feedback, classes.labelRootSuccess, {
		[classes.feedbackNoLabel]: labelText === undefined,
		[classes.feedbackAdorment]:
			inputProps && inputProps.endAdornment !== undefined
	});
	const errorClasses = cx(classes.feedback, classes.labelRootError, {
		[classes.feedbackNoLabel]: labelText === undefined,
		[classes.feedbackAdorment]:
			inputProps && inputProps.endAdornment !== undefined
	});
	const marginTop = cx({
		[inputRootCustomClasses]: inputRootCustomClasses !== undefined
	});
	const inputClasses = cx({
		[classes.input]: true,
		[classes.whiteInput]: white,
		[classes.inheritWidth]: inheritWidth
	});
	if (inputProps) {
		delete inputProps.inputClasses;
	}
	let formControlClasses;
	if (formControlProps !== undefined) {
		formControlClasses = cx(formControlProps.className, classes.formControl);
	} else {
		formControlClasses = classes.formControl;
	}
	const helpTextClasses = cx({
		[classes.labelRootError]: error,
		[classes.labelRootSuccess]: success && !error
	});
	return (
		<FormControl {...formControlProps} className={formControlClasses}>
			{labelText !== undefined ? (
				<InputLabel
					className={classes.labelRoot + ' ' + labelClasses}
					htmlFor={id}
					{...labelProps}
				>
					{labelText}
				</InputLabel>
			) : null}
			<Input
				classes={{
					input: inputClasses,
					root: marginTop,
					disabled: classes.disabled,
					underline: underlineClasses
				}}
				id={id}
				inputRef={inputRef}
				{...inputProps}
			/>
			{error ? (
				<Clear className={errorClasses} />
			) : success ? (
				<Check className={successClasses} />
			) : null}
			{helpText !== undefined ? (
				<FormHelperText className={helpTextClasses} id={id + '-text'}>
					{helpText}
				</FormHelperText>
			) : null}
		</FormControl>
	);
}

CustomInput.propTypes = {
	classes: PropTypes.object.isRequired,
	labelText: PropTypes.node,
	labelProps: PropTypes.object,
	id: PropTypes.string,
	inputProps: PropTypes.object,
	inheritWidth: PropTypes.bool,
	formControlProps: PropTypes.object,
	inputRootCustomClasses: PropTypes.string,
	error: PropTypes.bool,
	success: PropTypes.bool,
	white: PropTypes.bool,
	helpText: PropTypes.node,
	inputRef: PropTypes.object,
};

export default withStyles(customInputStyle)(CustomInput);
