import WebSecurity from './01_web_security.png'
import Exploitation from './02_exploitation.png'
import Crypto from './03_crypto.png'
import ReverseEngineering from './04_reverse_engineering.png'
import Forensic from './05_forensic.png'
import PenetrationTesting from './06_penetration_testing.png'
import NetworkSecurity from './07_network_security.png'
import DatabaseSecurity from './08_database_security.png'
import Malware from './09_malware.png'
import Programming from './10_programming.png'
import Linux from './11_linux.png'
import Windows from './12_windows.png'
import Osx from './13_osx.png'
import Android from './14_android.png'
import Ios from './15_ios.png'
import Wireless from './16_wireless.png'
import Phone from './17_phone.png'
import Defense from './18_defense.png'
import Fun from './19_fun.png'
import SocialEngineering from './20_social_engineering.png'
import OpenSourceIntelligence from './21_open_source_intelligence.png'

export default {
	WebSecurity,
	Exploitation,
	Crypto,
	ReverseEngineering,
	Forensic,
	PenetrationTesting,
	NetworkSecurity,
	DatabaseSecurity,
	Malware,
	Programming,
	Linux,
	Windows,
	Osx,
	Android,
	Ios,
	Wireless,
	Phone,
	Defense,
	Fun,
	SocialEngineering,
	OpenSourceIntelligence
}
