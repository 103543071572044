// @flow

export type OptionModelDto = {
	uuid: ?string,
	order: ?number,
	content: ?string,
	correct: ?boolean
}

export class ChoiceOptionModel {
	uuid: string
	order: number
	content: string
	correct: boolean

	constructor(uuid: string, order: number, content: string, correct: boolean) {
		this.uuid = uuid
		this.order = order
		this.content = content
		this.correct = correct
	}

	static fromDto(dto: OptionModelDto): ?ChoiceOptionModel {
		if (!dto) {
			return new ChoiceOptionModel()
		}
		return new ChoiceOptionModel(dto.uuid, dto.order, dto.content, dto.correct)
	}

	static fromDtoList(dtos: OptionModelDto[]): ChoiceOptionModel[] {
		if (!dtos) {
			return []
		}
		return dtos.map(dto => ChoiceOptionModel.fromDto(dto))
	}

	toDto(index: ?number): OptionModelDto {
		return {
			uuid: this.uuid,
			order: index + 1,
			content: this.content,
			correct: this.correct
		}
	}
}
