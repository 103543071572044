import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { withStyles } from '@material-ui/core/styles'
import Header from '../shared/components/Header/Header.jsx'
import Sidebar from '../shared/components/Sidebar/Sidebar.jsx'
import { connect } from 'react-redux'
import dashboardRoutes from '../routes/dashboard.jsx'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import appStyle from '../shared/assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx'

import image from '../shared/assets/img/sidebar-2.jpg'
import logoBottom from '../shared/assets/img/logo.png'
import logoMiniBottom from '../shared/assets/img/logo-small.png'
import { checkHasRole, ProtectedRoute } from "shared/auth/authUtils"
import { bindActionCreators, compose } from 'redux'
import brandingActions from '../actions/branding'

const mapRoutes = dashboardRoutes => {
	return dashboardRoutes.map((route, index) => {
		if (route.collapse) {
			return mapRoutes(route.routes)
		}
		if (route.needsRole) {
			return (
				<ProtectedRoute
					exact
					needsRole={route.needsRole}
					path={route.path}
					component={route.component}
					key={index}
				/>
			)
		}
		if (route.redirect) {
			if (route.roleMapping) {
				for (const key in route.roleMapping) {
					if (key && checkHasRole(key) && route.roleMapping[key]) {
						return (
							<Redirect
								from={route.path}
								to={route.roleMapping[key]}
								key={index}
							/>
						)
					}
				}
			}
			return <Redirect from={route.path} to={route.pathTo} key={index} />
		}
		return (
			<Route exact path={route.path} component={route.component} key={index} />
		)
	})
}

const switchRoutes = <Switch>{mapRoutes(dashboardRoutes)}</Switch>

class Dashboard extends React.Component {
	ps = undefined

	state = {
		mobileOpen: false,
		miniActive: false
	}
	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen })
	}

	getRoute() {
		return this.props.location.pathname !== '/maps/full-screen-maps'
	}

	componentDidMount() {
		this.props.fetchBranding()

		if (navigator.platform.indexOf('Win') > -1) {
			// eslint-disable-next-line
			this.ps = new PerfectScrollbar(this.refs.mainPanel, {
				suppressScrollX: true,
				suppressScrollY: false
			})
		}
	}

	componentWillUnmount() {
		if (navigator.platform.indexOf('Win') > -1) {
			this.ps.destroy()
		}
	}

	componentDidUpdate(e) {
		if (e.history.location.pathname !== e.location.pathname) {
			this.refs.mainPanel.scrollTop = 0
		}
	}

	sidebarMinimize = () => {
		this.setState({ miniActive: !this.state.miniActive })
	}

	render() {
		const {
			classes,
			appLogo,
			appLogoSmall,
			websiteName,
			realm,
			...rest
		} = this.props
		const mainPanel = cx(classes.mainPanel, {
			[classes.mainPanelSidebarMini]: this.state.miniActive,
			[classes.mainPanelWithPerfectScrollbar]:
				navigator.platform.indexOf('Win') > -1
		})

		return (
			<div className={classes.wrapper}>
				<HelmetProvider>
					<Helmet>
						<title>{websiteName}</title>
					</Helmet>
				</HelmetProvider>
				<Sidebar
					routes={dashboardRoutes
						.filter(route => route.hasSidebarLink)
						.filter(route => checkHasRole(route.needsRole))}
					logo={appLogo}
					logoMini={appLogoSmall}
					logoBottom={logoBottom}
					logoMiniBottom={logoMiniBottom}
					image={image}
					handleDrawerToggle={this.handleDrawerToggle}
					open={this.state.mobileOpen}
					color="blue"
					bgColor="black"
					miniActive={this.state.miniActive}
					sidebarMinimize={this.sidebarMinimize}
					realm={realm}
					{...rest}
				/>
				<div className={mainPanel} ref="mainPanel">
					<Header
						miniActive={this.state.miniActive}
						routes={dashboardRoutes}
						handleDrawerToggle={this.handleDrawerToggle}
						{...rest}
					/>
					{/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
					{this.getRoute() ? (
						<div className={classes.content}>
							<div className={classes.container}>{switchRoutes}</div>
						</div>
					) : (
						<div className={classes.map}>{switchRoutes}</div>
					)}
				</div>
			</div>
		)
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
	appLogo: state.branding.appLogo,
	appLogoSmall: state.branding.appLogoSmall,
	websiteName: state.branding.websiteName,
	realm: state.branding.realm
})

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchBranding: brandingActions.fetchBranding
		},
		dispatch
	)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(appStyle)
)(Dashboard)
