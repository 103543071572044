import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { mediaListForMarkdownStyle } from '../../assets/jss/material-dashboard-pro-react/components/mediaListForMarkdownStyle'
import { Upload } from "@hlcr/mui/Upload";
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import CustomInput from '../CustomInput/CustomInput'
import Paper from '@material-ui/core/Paper/Paper'
import { Button } from "@hlcr/mui/Button";
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import CustomTooltip from '../../../shared/components/CustomTooltip/CustomTooltip'

class MediaListForMarkdown extends React.Component {
	constructor(props) {
		super(props)
		this.references = {}
		this.setInputRef = (element, uuid) => {
			this.references[uuid] = element
		}
		this.state = {
			uploadedFiles: props.uploadedMediaFiles
		}
	}

	fileUploaded = mediaFile => {
		console.log('uploaded', mediaFile.uuid)
		const uploadedFiles: [] = this.state.uploadedFiles.slice(0)
		const changedFileIndex = uploadedFiles.findIndex(
			file => file.name === mediaFile.fileName
		)
		console.log('uploadedfiles', uploadedFiles)
		uploadedFiles[changedFileIndex].uuid = mediaFile.uuid
		uploadedFiles[changedFileIndex].path = mediaFile.path
		this.setState({ uploadedFiles })
	}

	onDrop = file => {
		console.log('filename', file.name)
		const uploadedFiles = this.state.uploadedFiles.slice(0)
		console.log('uploadedfiles', uploadedFiles)
		uploadedFiles.push(file)
		console.log('uploadedfiles', uploadedFiles)
		this.setState({ uploadedFiles }, () =>
			this.props.uploadMedia(file, this.fileUploaded)
		)
	}

	copyToClipboard = uuid => {
		if (this.references[uuid]) {
			this.references[uuid].select()
			document.execCommand('copy')
			this.references[uuid].blur()
		}
	}

	uploadedFilesList = () => {
		return this.state.uploadedFiles.map(file => (
			<GridContainer key={file.name} style={{ marginTop: '10px' }}>
				<GridItem xs={12}>
					<div style={{ overflow: 'auto' }}>
						<strong>{file.name}</strong>
					</div>
				</GridItem>
				{file.uuid ? (
					<GridItem xs={12}>
						<div
							style={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }}
						>
							<CustomInput
								inputRef={element => this.setInputRef(element, file.uuid)}
								labelText={<span>Markdown Reference</span>}
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									readOnly: true,
									value: this.getMarkdownLink(file)
								}}
							/>
							<CustomTooltip title="Copy To Clipboard" placement="top">
								<Button
									onClick={() => this.copyToClipboard(file.uuid)}
									color="infoNoBackground"
									customClass={this.props.classes.copyButton}
								>
									<CopyIcon fontSize="small" />
								</Button>
							</CustomTooltip>
						</div>
					</GridItem>
				) : (
					<GridItem xs={12} style={{ textAlign: 'center' }}>
						<CircularProgress size={12} />
					</GridItem>
				)}
			</GridContainer>
		))
	}

	render() {
		return (
			<Paper style={{ padding: '10px' }}>
				<Upload type="file" handleProcessedFile={this.onDrop} />
				{this.uploadedFilesList()}
			</Paper>
		)
	}

	getMarkdownLink = file => {
		const multimediaExtensions = [
			'jpg',
			'jpeg',
			'png',
			'gif',
			'bmp',
			'mp4',
			'webm',
			'ogv',
			'avi'
		]

		return multimediaExtensions.includes(
			file.name
				.split('.')
				.pop()
				.toLowerCase()
		)
			? `![${file.uuid}](${file.path})`
			: `[${file.uuid}](${file.path})`
	}
}

MediaListForMarkdown.propTypes = {
	classes: PropTypes.object.isRequired,
	uploadedMediaFiles: PropTypes.array,
	uploadMedia: PropTypes.func.isRequired
}

export default withStyles(mediaListForMarkdownStyle)(MediaListForMarkdown)
