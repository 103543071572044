import type { Dto } from './index'

export type QuizState = {
	quizzes: {
		pending: boolean,
		data: Dto[]
	},
	currentQuiz: {
		pending: boolean,
		data: Dto
	}
}

export const quizInitState: QuizState = {
	quizzes: {
		pending: false,
		data: []
	},
	currentQuiz: {
		pending: false,
		data: []
	}
}
