import { Button } from "@hlcr/mui/Button";
import React from 'react'
import PropTypes from 'prop-types'

class NavigationButtons extends React.Component {
	render() {
		const {
			classes,
			previousButton,
			previousButtonClasses,
			previousButtonClick,
			previousButtonText,
			nextButton,
			nextButtonClasses,
			nextButtonClick,
			nextButtonText,
			finishButton,
			finishButtonClasses,
			finishButtonClick,
			finishButtonText,
			finishButtonDeployText,
			finishButtonDeployClick
		} = this.props

		return (
			<div className={classes.footer}>
				<div className={classes.left}>
					{previousButton ? (
						<Button
							className={previousButtonClasses}
							onClick={previousButtonClick}
						>
							{previousButtonText}
						</Button>
					) : null}
				</div>
				<div className={classes.right}>
					{nextButton ? (
						<Button
							color="rose"
							className={nextButtonClasses}
							onClick={nextButtonClick}
						>
							{nextButtonText}
						</Button>
					) : null}
					{finishButton ? (
						<Button
							color="rose"
							className={finishButtonClasses}
							onClick={finishButtonClick}
						>
							{finishButtonText}
						</Button>
					) : null}
					{finishButton ? (
						<Button
							color="success"
							className={finishButtonClasses}
							onClick={finishButtonDeployClick}
						>
							{finishButtonDeployText}
						</Button>
					) : null}
				</div>
				<div className={classes.clearfix} />
			</div>
		)
	}
}

NavigationButtons.propTypes = {
	previousButtonClasses: PropTypes.string,
	previousButtonText: PropTypes.string,
	nextButtonClasses: PropTypes.string,
	nextButtonText: PropTypes.string,
	finishButtonClasses: PropTypes.string,
	finishButtonText: PropTypes.string,
	finishButtonClick: PropTypes.func,
	finishButtonDeployClick: PropTypes.func
}

export default NavigationButtons
