import React, { Fragment } from 'react'
import cx from 'classnames'
import InfoIcon from '@material-ui/icons/Info'
import { withStyles } from '@material-ui/core/styles'
import { withIntl } from "@hlcr/ui/Intl"
import IconCard from '../../../../shared/components/Cards/IconCard'
import Table from '../../../../components/Table/Table'
import { grayColor } from "shared/assets/jss/material-dashboard-pro-react"
import { TheoryModel } from "shared/models/theory.model"
import { categoryImages } from "shared/models/category.model"
import Tooltip from '@material-ui/core/Tooltip'

const infoSectionStyles = {
	typeIcon: {
		color: grayColor,
		height: 24
	},
	tooltip: {
		fontSize: '1.2em',
		letterSpacing: '.05em'
	},
	summaryBox: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		'& > div': {
			marginRight: 6
		}
	},
	categoryImage: {
		width: 36,
		height: 36
	},
	spacer: {
		marginRight: 6
	}
}

const InfoSection = ({ theory, unitType, classes, intl }) => {
	return (
		<IconCard
			icon={InfoIcon}
			title={intl.fm('theory.properties')}
			content={
				<InfoSectionTable
					theory={theory}
					unitType={unitType}
					classes={classes}
					intl={intl}
				/>
			}
		/>
	)
}

const categories = (theory: TheoryModel, classes) => (
	<div>
		<Fragment>
			{theory.categories.map((category, index) => {
				const imageSrc = categoryImages[category]
				return imageSrc ? (
					<Tooltip
						title={<span>{category.toUpperCase().replace('_', ' ')}</span>}
						placement="top"
						key={index}
					>
						<img
							src={imageSrc}
							alt={category}
							className={cx(classes.categoryImage, {
								[classes.spacer]: index !== theory.categories.length - 1
							})}
						/>
					</Tooltip>
				) : (
					<span className={classes.categoryText} key={index}>
						{category.toUpperCase().replace('_', ' ')}
					</span>
				)
			})}
		</Fragment>
	</div>
)

const theoryBasicValue = value => <div>{value}</div>

const theoryKeywords = usages => (
	<div>
		{usages.map((usage, index) => (index === 0 ? usage : `, ${usage}`))}
	</div>
)

const theoryIsPublic = (isPublic, intl) => (
	<div>{isPublic ? intl.fm(`theory.public`) : intl.fm(`theory.private`)}</div>
)

const InfoSectionTable = ({ theory, event, unitType, classes, intl }) => {
	const tableRenderer = [
		{ renderCell: row => row.title },
		{ renderCell: row => row.value }
	]

	let tableData = [
		{
			title: 'Name',
			value: theoryBasicValue(theory.name)
		},
		{
			title: 'UUID',
			value: theoryBasicValue(theory.uuid)
		},
		{
			title: intl.fm('theory.access'),
			value: theoryIsPublic(theory.isPublic, intl)
		},
		{
			title: intl.fm('theory.keywords'),
			value: theoryKeywords(theory.keywords)
		},
		{
			title: intl.fm('event.table.categories'),
			value: categories(theory, classes)
		}
	]

	return <Table tableRenderer={tableRenderer} tableData={tableData} hover />
}

export default withIntl(withStyles(infoSectionStyles)(InfoSection))
