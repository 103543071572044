import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import { QuizModel } from "shared/models/quiz.model"
import PaginatedProjects from '../../components/PaginatedProjects/PaginatedProjects'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import {
	cloneQuiz,
	createQuiz,
	deployQuiz,
	fetchQuizPipeline,
	fetchQuizProjects
} from "actions/quiz.actions"
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import eventUnitsStyle from '../../shared/assets/jss/material-dashboard-pro-react/views/eventUnitsStyle'
import { withIntl } from "@hlcr/ui/Intl"

const LoadingModal = ({ title, onConfirm }) => (
	<SweetAlert title={title} onConfirm={onConfirm} showConfirm={false}>
		<CircularProgress />
	</SweetAlert>
)

class List extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			alert: null
		}
	}

	showLoadingModal = title => {
		this.setState({
			alert: <LoadingModal title={title} onConfirm={() => this.hideAlert()} />
		})
	}

	hideAlert = () => {
		this.setState({
			alert: null
		})
	}

	handleNewQuiz = () => {
		this.showLoadingModal(
			`Creating new ${this.props.intl.fm(`${this.props.languageKey}.name`)}`
		)
		this.props.createQuiz(this.handleQuizCreated)
	}

	handleCloneQuiz = projectUuid => {
		this.showLoadingModal(
			`Cloning ${this.props.intl.fm(`${this.props.languageKey}.name`)}`
		)
		this.props.cloneQuiz(projectUuid, this.handleQuizCreated)
	}

	handleDeployQuiz = (projectUuid, callback) => {
		this.showLoadingModal(
			`Deploying ${this.props.intl.fm(`${this.props.languageKey}.name`)}`
		)
		this.props.deployQuiz(projectUuid, result => {
			this.handleQuizCreated(result)
			if (callback) {
				callback(result)
			}
		})
	}

	handleQuizCreated = (result: QuizModel) => {
		if (result && result.uuid)
			this.props.history.push(`/${this.props.pathSegment}/${result.uuid}`)
		this.hideAlert()
	}

	handleFetchQuizPipeline = (pipelineId, callback) => {
		this.props.fetchQuizPipeline(pipelineId, result => {
			if (callback) {
				callback(result)
			}
		})
	}

	render() {
		const {
			paginatedQuizzesPending,
			paginatedQuizzes,
			triggerChange,
			fetchPage,
			languageKey,
			pathSegment,
			inputSearchValue,
			history,
			intl,
			classes,
			...other
		} = this.props

		return paginatedQuizzesPending ? (
			<LoadingModal
				title={intl.fm(`${this.props.languageKey}.loadingListText`)}
				onConfirm={() => this.hideAlert()}
			/>
		) : (
			<div>
				{this.state.alert}
				<PaginatedProjects
					triggerChange={triggerChange}
					inputSearchValue={inputSearchValue}
					fetchPage={fetchPage}
					createNewProject={this.handleNewQuiz}
					cloneProject={this.handleCloneQuiz}
					deployProject={this.handleDeployQuiz}
					fetchPipeline={this.handleFetchQuizPipeline}
					paginatedProjects={paginatedQuizzes}
					languageKey={languageKey}
					pathSegment={pathSegment}
					history={history}
					intl={intl}
					{...other}
				/>
			</div>
		)
	}
}

List.propTypes = {
	paginatedQuizzes: PropTypes.object,
	fetchQuizProjects: PropTypes.func.isRequired,
	createQuiz: PropTypes.func.isRequired,
	cloneQuiz: PropTypes.func.isRequired,
	deployQuiz: PropTypes.func.isRequired,
	fetchQuizPipeline: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
	return {
		paginatedQuizzesPending: state.api.resources.quizzes.pending
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			createQuiz,
			cloneQuiz,
			deployQuiz,
			fetchQuizPipeline,
			fetchQuizProjects
		},
		dispatch
	)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(eventUnitsStyle),
	withIntl
)(List)
