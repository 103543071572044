import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { fetchChallengeProjects } from "actions/challenge.actions";
import eventUnitsStyle from "../../shared/assets/jss/material-dashboard-pro-react/views/eventUnitsStyle";
import { withStyles } from "@material-ui/core/styles";
import { withIntl } from "@hlcr/ui/Intl";
import { PaginatedDataModel } from "shared/models/paginatedData.model";
import List from "./List";
import PropTypes from "prop-types";

class ChallengeList extends React.Component {
	pathSegment = 'challenges'
	languageKey = 'challenge'

	constructor(props) {
		super(props)
		this.state = {
			search: "",
			showError: false,
		}
	}

	componentDidMount() {
		const { pageNumber } = this.props
		if (pageNumber) {
			this.props.fetchChallengeProjects(pageNumber, this.state.search)
		} else {
			this.fetchPage(1)
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timer)
	}

	fetchPage = page => {
		this.fetchPageWithSearch(page, this.state.search);
	}

	fetchPageWithSearch = (page, search) => {
		this.props.history.push(`/${this.pathSegment}/page/${page}`);
		this.props.fetchChallengeProjects(page, search);
	}

	triggerChange = (search, shouldReload) => {
		if (search && search.length > 0) {
			this.setState({ search: search });
			this.fetchPageWithSearch(1, search)
		} else {
			this.setState({ search: "" });
			this.fetchPageWithSearch(1, "");
		}
	}

	render() {
		const { paginatedChallenges, history } = this.props
		return (
			<List
				paginatedChallenges={paginatedChallenges}
				triggerChange={this.triggerChange}
				fetchPage={this.fetchPage}
				languageKey={this.languageKey}
				pathSegment={this.pathSegment}
				inputSearchValue={this.state.search}
				history={history}
			/>
		)
	}
}

List.propTypes = {
	paginatedChallenges: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
	const pageNumber = ownProps.match.params.pageNumber
	const paginatedChallenges = state.api.resources.challenges.data.find(
		challenge => parseInt(challenge.page, 10) === parseInt(pageNumber, 10)
	)
	return {
		paginatedChallenges: PaginatedDataModel.fromDtoWithChallenges(
			paginatedChallenges
		),
		pageNumber: pageNumber
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchChallengeProjects
		},
		dispatch
	)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(eventUnitsStyle),
	withIntl
)(ChallengeList)
