// @flow

import type { ApiState } from './apiReducer'
import api from './apiReducer'
import { combineReducers } from 'redux'
import branding from './brandingReducer'

export type AppState = { api: ApiState }
export type Dto = any

const reducer = combineReducers({
	api,
	branding
})

export default reducer
