import brandingActions, {
	defaultBranding,
	FETCH_BRANDING_CONFIG
} from '../actions/branding'
import defaultAppLogo from '../shared/assets/img/logo-large.png'
import defaultAppLogoSmall from '../shared/assets/img/logo-small.png'

export default middleware => next => async action => {
	const nextAction = next(action)

	if (action.type === FETCH_BRANDING_CONFIG) {
		try {
			const response = await fetch('/config/branding.json')
			const json = await response.json()
			if (!json.appLogo) {
				json.appLogo = defaultAppLogo
			}
			if (!json.appLogoSmall) {
				json.appLogoSmall = defaultAppLogoSmall
			}
			middleware.dispatch(brandingActions.setBranding(json))
		} catch (e) {
			return middleware.dispatch(brandingActions.setBranding(defaultBranding))
		}
	}

	return nextAction
}
