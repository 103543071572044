import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "shared/components/Grid/GridContainer.jsx";
import challengeStyle from "shared/assets/jss/material-dashboard-pro-react/views/challengeStyle";
import { Button } from "@hlcr/mui/Button";
import CustomInput from "shared/components/CustomInput/CustomInput";
import GridItem from "shared/components/Grid/GridItem";
import { withIntl } from "@hlcr/ui/Intl";

class ResourcesStep extends React.Component {
	constructor(props) {
		super(props);
		const challenge = this.props.currentChallenge;
		this.state = {
			resources: challenge.resources.slice(0),
		};
	}

	sendState() {
		return this.state;
	}

	handleChangeResourceUuid = (index, value) => {
		const { resources } = this.state;
		resources.splice(index, 1, value);
		this.setState({ resources });
	};

	handleAddNewResourceClicked = () => {
		const { resources } = this.state;
		if (resources.findIndex(resource => resource === "") === -1) {
			resources.push("");
			this.setState({ resources: resources });
		}
	};
	handleRemoveResource = index => {
		const { resources } = this.state;
		resources.splice(index, 1);
		this.setState({ resources: resources });
	};

	isValidated() {
		return true;
	}

	render() {
		const { resources } = this.state;
		const { classes } = this.props;
		return (
			<GridContainer justify={"center"}>
				<GridItem xs={12} sm={12} key="title" style={{ textAlign: "center" }}>
					<h4 className={classes.infoText}>Resources</h4>
					<Button
						variant="contained"
						color="primary"
						onClick={this.handleAddNewResourceClicked}
					>
						Add new Resource
					</Button>
				</GridItem>
				{this.resourceList(classes, resources)}
			</GridContainer>
		);
	}

	resourceList = (classes, resources) => {
		return resources.map((resource, index) => (
			<GridItem xs={12} sm={7} key={index} style={{ textAlign: "center" }}>
				<div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
					<CustomInput
						labelText={
							<span>
								Resource {index + 1} UUID <small>(required)</small>
							</span>
						}
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							id: `resource-${index}`,
							name: `${index}`,
							onChange: event =>
								this.handleChangeResourceUuid(index, event.target.value),
							value: resource,
						}}
					/>
					<Button
						style={{ marginLeft: "12px" }}
						variant="contained"
						onClick={() => this.handleRemoveResource(index)}
						color="danger"
					>
						{this.props.intl.fm("common.labels.delete")}
					</Button>
				</div>
			</GridItem>
		));
	};
}

export default withIntl(withStyles(challengeStyle)(ResourcesStep));
