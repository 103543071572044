import { defaultFont } from 'shared/assets/jss/material-dashboard-pro-react.jsx'

const headerLinksStyle = theme => ({
	linkText: {
		zIndex: '4',
		...defaultFont,
		fontSize: '14px',
		margin: '0!important'
	},
	buttonLink: {
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			marginLeft: '30px',
			width: 'auto'
		}
	},
	links: {
		width: '20px',
		height: '20px',
		zIndex: '4',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			width: '30px',
			height: '30px',
			color: 'inherit',
			opacity: '0.8',
			marginRight: '16px',
			marginLeft: '-5px'
		}
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	}
})

export default headerLinksStyle
