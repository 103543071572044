import React, { Fragment } from 'react'
import cx from 'classnames'
import InfoIcon from '@material-ui/icons/Info'
import DeleteIcon from '@material-ui/icons/Delete'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'

import { grayColor } from "shared/assets/jss/material-dashboard-pro-react"
import { withIntl } from "@hlcr/ui/Intl"
import IconCard from '../../../../shared/components/Cards/IconCard'
import Table from '../../../../components/Table/Table'
import LevelLabel from '../../../../shared/components/LevelLabel/LevelLabel'
import Label from '../../../../shared/components/Label/Label'
import { challengeGoldNuggetTypeLabels } from "shared/helper/challenge"
import { ChallengeModel } from "shared/models/challenge.model"
import IconButton from '@material-ui/core/IconButton/IconButton'
import { hasWriteAccess } from "shared/auth/authUtils"
import { categoryImages } from "shared/models/category.model"

const infoSectionStyles = {
	typeIcon: {
		color: grayColor,
		height: 24
	},
	tooltip: {
		fontSize: '1.2em',
		letterSpacing: '.05em'
	},
	summaryBox: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		'& > div': {
			marginRight: 6
		}
	},
	categoryImage: {
		width: 36,
		height: 36
	},
	spacer: {
		marginRight: 6
	}
}

const InfoSection = ({
	challenge,
	unitType,
	classes,
	intl,
	handleRemoveOwner,
	ownerEmail
}) => {
	return (
		<IconCard
			icon={InfoIcon}
			title={intl.fm('challenge.properties')}
			content={
				<InfoSectionTable
					challenge={challenge}
					unitType={unitType}
					classes={classes}
					intl={intl}
					handleRemoveOwner={handleRemoveOwner}
					ownerEmail={ownerEmail}
				/>
			}
		/>
	)
}

const categories = (challenge: ChallengeModel, classes) => (
	<div>
		<Fragment>
			{challenge.categories.map((category, index) => {
				const imageSrc = categoryImages[category]
				return imageSrc ? (
					<Tooltip
						title={<span>{category.toUpperCase().replace('_', ' ')}</span>}
						placement="top"
						key={index}
					>
						<img
							src={imageSrc}
							alt={category}
							className={cx(classes.categoryImage, {
								[classes.spacer]: index !== challenge.categories.length - 1
							})}
						/>
					</Tooltip>
				) : (
					<span className={classes.categoryText} key={index}>
						{category.toUpperCase().replace('_', ' ')}
					</span>
				)
			})}
		</Fragment>
	</div>
)

const challengeLevel = (challenge, intl) => (
	<div>
		{challenge.level ? (
			<LevelLabel level={challenge.level} />
		) : (
			intl.fm('common.labels.unknown')
		)}
	</div>
)

const challengeBasicValue = value => <div>{value}</div>

const challengeOwnerEmail = (challenge, ownerEmail, handleRemoveOwner) => (
	<div>
		{ownerEmail === '' ? (
			<s>{challenge.ownerEmail}</s>
		) : (
			<div>
				{ownerEmail}{' '}
				{hasWriteAccess(challenge) ? (
					<IconButton
						size={'small'}
						color={'secondary'}
						onClick={handleRemoveOwner}
					>
						<DeleteIcon />
					</IconButton>
				) : null}
			</div>
		)}
	</div>
)

const challengeKeywords = usages => (
	<div>
		{usages.map((usage, index) => (index === 0 ? usage : `, ${usage}`))}
	</div>
)

const challengeType = (type, intl) => (
	<div>{intl.fm(`challenge.type.${type || 'unknown'}`)}</div>
)

const challengeIsPublic = (isPublic, intl) => (
	<div>
		{isPublic ? intl.fm(`challenge.public`) : intl.fm(`challenge.private`)}
	</div>
)

const goldNugget = (goldNugget, intl) => {
	if (goldNugget) {
		let label = challengeGoldNuggetTypeLabels[goldNugget.type]
		label = !label ? challengeGoldNuggetTypeLabels['none'] : label
		return <Label value={label.name} color={label.color} />
	}
	return <Label value={intl.fm('common.labels.unknown')} />
}

const InfoSectionTable = ({
	challenge,
	event,
	unitType,
	classes,
	intl,
	handleRemoveOwner,
	ownerEmail
}) => {
	const tableRenderer = [
		{ renderCell: row => row.title },
		{ renderCell: row => row.value }
	]

	let tableData = [
		{
			title: 'Name',
			value: challengeBasicValue(challenge.name)
		},
		{
			title: 'Owner Email',
			value: challengeOwnerEmail(challenge, ownerEmail, handleRemoveOwner)
		},
		{
			title: 'UUID',
			value: challengeBasicValue(challenge.uuid)
		},
		{
			title: intl.fm('challenge.type'),
			value: challengeType(challenge.type, intl)
		},
		{
			title: intl.fm('event.table.level'),
			value: challengeLevel(challenge, intl)
		},
		{
			title: intl.fm('challenge.access'),
			value: challengeIsPublic(challenge.isPublic, intl)
		},
		{
			title: intl.fm('challenge.keywords'),
			value: challengeKeywords(challenge.keywords)
		},
		{
			title: intl.fm('event.table.categories'),
			value: categories(challenge, classes)
		},
		{
			title: intl.fm('challenge.gold-nugget'),
			value: goldNugget(challenge.goldNugget, intl)
		}
	]

	if (challenge.goldNugget.type === 'static') {
		tableData = tableData.concat([
			{
				title: `${intl.fm('challenge.gold-nugget')} Secret`,
				value: <div>{challenge.goldNugget.secret}</div>
			}
		])
	}

	return <Table tableRenderer={tableRenderer} tableData={tableData} hover />
}

export default withIntl(withStyles(infoSectionStyles)(InfoSection))
