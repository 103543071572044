// @flow

import Categories from '../assets/img/categories'

export const categoryImages = {
	web_security: Categories.WebSecurity,
	exploitation: Categories.Exploitation,
	crypto: Categories.Crypto,
	reverse_engineering: Categories.ReverseEngineering,
	forensic: Categories.Forensic,
	penetration_testing: Categories.PenetrationTesting,
	network_security: Categories.NetworkSecurity,
	database_security: Categories.DatabaseSecurity,
	malware: Categories.Malware,
	programming: Categories.Programming,
	linux: Categories.Linux,
	windows: Categories.Windows,
	osx: Categories.Osx,
	android: Categories.Android,
	ios: Categories.Ios,
	wireless: Categories.Wireless,
	phone: Categories.Phone,
	defense: Categories.Defense,
	fun: Categories.Fun,
	social_engineering: Categories.SocialEngineering,
	opensource_intelligence: Categories.OpenSourceIntelligence
}
