import React from 'react'
// material-ui components
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import cx from 'classnames'

const style = {
	grid: {
		padding: '0 15px !important'
	}
}

function ItemGrid({ ...props }) {
	const { classes, children, className, ...rest } = props
	return (
		<Grid item {...rest} className={cx(classes.grid, className)}>
			{children}
		</Grid>
	)
}

export default withStyles(style)(ItemGrid)
