// @flow

export type StepType = 'instruction'

export type StepModelDto = {
	uuid: ?string,
	order: ?number,
	type: ?StepType,
	title: ?string,
	content: ?string
}

export class StepModel {
	uuid: string
	order: number
	type: StepType
	title: string
	content: string

	constructor(uuid: string, order: number, type: StepType, title: string, content: string) {
		this.uuid = uuid
		this.order = order
		this.type = type
		this.title = title
		this.content = content
	}

	static fromDto(dto: StepModelDto): ?StepModel {
		if (!dto) {
			return new StepModel()
		}
		return new StepModel(dto.uuid, dto.order, dto.type, dto.title, dto.content)
	}

	static fromDtoList(dtos: StepModelDto[]): StepModel[] {
		if (!dtos) {
			return []
		}
		return dtos.map(dto => StepModel.fromDto(dto))
	}

	toDto(index: ?number): StepModelDto {
		return {
			uuid: this.uuid,
			order: index + 1,
			type: this.type,
			title: this.title,
			content: this.content
		}
	}
}
