// @flow
import type { StepModelDto } from './step.model'
import { StepModel } from './step.model'

export type SectionModelDto = {
	uuid: ?string,
	order: ?number,
	title: ?string,
	content: ?string,
	steps: ?(StepModelDto[])
}

export class SectionModel {
	uuid: string
	order: number
	title: string
	content: string
	steps: StepModel[]

	constructor(
		uuid: string,
		order: number,
		title: string,
		content: string,
		steps: StepModel[]
	) {
		this.uuid = uuid
		this.order = order
		this.title = title
		this.content = content
		this.steps = steps || []
	}

	static fromDto(dto: SectionModelDto): SectionModel {
		if (!dto) {
			return new SectionModel()
		}
		return new SectionModel(
			dto.uuid,
			dto.order,
			dto.title,
			dto.content,
			StepModel.fromDtoList(dto.steps)
		)
	}

	static fromDtoList(dtos: SectionModelDto[]): SectionModel[] {
		if (!dtos) {
			return []
		}
		return dtos.map(dto => SectionModel.fromDto(dto))
	}

	toDto(index): SectionModelDto {
		return {
			uuid: this.uuid,
			order: index + 1,
			title: this.title,
			content: this.content,
			steps: this.steps.map((step, index) => step.toDto(index))
		}
	}
}
