import React from "react";
import { NavLink } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import dashboardRoutes from "../../../routes/dashboard.jsx";
import { withStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HelpIcon from "@material-ui/icons/Help";
import breadCrumbsStyle from "../../assets/jss/material-dashboard-pro-react/components/breadCrumbsStyle.jsx";
import cx from "classnames";
import { fetchHelp } from "../../variables/helpPage";
import { compose } from "redux";
import { connect } from "react-redux";

const matchingHelpLink = (breadcrumbs, helpUrl) => {
	const lastElement = breadcrumbs[breadcrumbs.length - 1];
	const currentRoute = dashboardRoutes.find(
		r => r.path === lastElement.match?.path,
	);

	if (currentRoute && currentRoute.help) {
		const help = currentRoute.help;
		return fetchHelp(helpUrl, help.role, help.action);
	} else {
		return helpUrl;
	}
};

// map & render your breadcrumb components however you want.
// each `breadcrumb` has the props `key`, `location`, and `match` included!
const BreadCrumbs = ({
	                     classes,
	                     breadcrumbs,
	                     className,
	                     linkClassName,
	                     alternative,
	                     helpUrl,
                     }) =>
	breadcrumbs.length > 0 ? (
		<div className={cx(classes.container, className)}>
			<a
				href={matchingHelpLink(breadcrumbs, helpUrl)}
				target="_blank"
				rel="noopener noreferrer"
			>
				<HelpIcon className={classes.helpIcon} />
			</a>
			{breadcrumbs.map((breadcrumb, index) => (
				<span key={breadcrumb.key} className={classes.item}>
					<NavLink to={breadcrumb.match.url} className={linkClassName}>
						{breadcrumb.breadcrumb}
					</NavLink>
					{index < breadcrumbs.length - 1 && (
						<ChevronRightIcon className={classes.separator} />
					)}
				</span>
			))}
		</div>
	) : (
		alternative
	);

const mapStateToProps = state => ({ helpUrl: state.branding.helpUrl });

function mapRoutes(dashboardRoutes) {
	const filtered = dashboardRoutes.filter(r => r.routes);
	if (filtered.length > 0) {
		dashboardRoutes.concat(filtered.map(r => r.routes).reduce((arr, route) => arr.concat(route)));
	}
	return dashboardRoutes.map(r => ({
		path: r.path || "unknown",
		breadcrumb: r.breadcrumb,
	}));
}

export default compose(
	connect(mapStateToProps),
	withStyles(breadCrumbsStyle),
	withBreadcrumbs(
		mapRoutes(dashboardRoutes),
		{ disableDefaults: true },
	),
)(BreadCrumbs);
