export const FETCH_BRANDING_CONFIG = 'FETCH_BRANDING_CONFIG'
export const SET_BRANDING_CONFIG = 'SET_BRANDING_CONFIG'

export const defaultBranding = {
	websiteName: 'Hacking-Lab',
	appLogo: undefined,
	appLogoSmall: undefined,
	realm: 'master'
}

const fetchBranding = () => ({ type: FETCH_BRANDING_CONFIG })

const setBranding = payload => ({
	type: SET_BRANDING_CONFIG,
	payload
})

export default { fetchBranding, setBranding }
