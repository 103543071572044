import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import headerLinksStyle from '../../assets/jss/material-dashboard-pro-react/components/headerLinksStyle'
import { doLogout } from '../../auth/authUtils'
import classNames from 'classnames'

class HeaderLinks extends React.Component {
	render() {
		const { classes } = this.props

		return (
			<Hidden smDown>
				<Button
					onClick={doLogout}
					color="inherit"
					className={classes.buttonLink}
				>
					<ExitToAppIcon
						className={classNames(classes.links, classes.leftIcon)}
					/>
					Logout
				</Button>
			</Hidden>
		)
	}
}

HeaderLinks.propTypes = {
	classes: PropTypes.object.isRequired,
	rtlActive: PropTypes.bool
}

export default withStyles(headerLinksStyle)(HeaderLinks)
