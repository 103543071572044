import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { fetchChallengeProjects, fetchChallengesByInputSearch } from "actions/challenge.actions";
import eventUnitsStyle from "../../shared/assets/jss/material-dashboard-pro-react/views/eventUnitsStyle";
import { withStyles } from "@material-ui/core/styles";
import { withIntl } from "@hlcr/ui/Intl";
import { PaginatedDataModel } from "shared/models/paginatedData.model";
import List from "./List";

class ChallengeSearchList extends React.Component {
	pathSegment = 'challenges'
	languageKey = 'challenge'

	constructor(props) {
		super(props)
		this.state = {
			search: undefined,
			showError: false,
		}
	}

	componentDidMount() {
		const { searchQuery } = this.props
		if (searchQuery) {
			if (searchQuery.length > 2) {
				this.props.fetchChallengesByInputSearch(searchQuery);
				this.setState({ showError: false });
			} else {
				this.setState({ showError: true });
			}
		} else {
			this.fetchPage(1)
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timer)
	}

	fetchPage = page => {
		this.props.history.push(`/${this.pathSegment}/page/${page}`)
		this.props.fetchChallengeProjects(page, "")
	}

	triggerChange = (search, shouldReload) => {
		if (search && search.length > 0) {
			clearTimeout(this.timer);
			this.setState({ search: search });
			if (shouldReload) {
				this.timer = setTimeout(() => {
					if (search.length > 2) {
						this.props.history.push(`/${this.pathSegment}/search?q=${encodeURI(search)}`);
						this.setState({ showError: false });
						this.props.fetchChallengesByInputSearch(search);
					} else {
						this.setState({ showError: true });
					}
				}, 0);
			}
		} else {
			this.setState({ search: "" });
			this.fetchPage(1);
		}
	}

	render() {
		const { paginatedChallenges, history, searchQuery } = this.props;
		return (
			<List
				paginatedChallenges={paginatedChallenges}
				triggerChange={this.triggerChange}
				fetchPage={this.fetchPage}
				languageKey={this.languageKey}
				pathSegment={this.pathSegment}
				inputSearchValue={this.state.search ?? searchQuery}
				history={history}
				showError={this.state.showError}
			/>
		)
	}
}

ChallengeSearchList.propTypes = {
	paginatedChallenges: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
	const query = new URLSearchParams(ownProps.location.search)
	const searchQuery = query.get('q') ? query.get('q') : ''
	const paginatedChallenges = state.api.resources.challenges.data.find(
		challenge => parseInt(challenge.page, 10) === 1
	)
	return {
		searchQuery: searchQuery,
		paginatedChallenges: PaginatedDataModel.fromDtoWithChallenges(
			paginatedChallenges
		)
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchChallengeProjects,
			fetchChallengesByInputSearch
		},
		dispatch
	)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(eventUnitsStyle),
	withIntl
)(ChallengeSearchList)
