import {
    dangerColor,
    grayColor,
    infoColor,
    primaryColor
} from '../../material-dashboard-pro-react'
import customSelectStyle from '../customSelectStyle'
import customCheckboxRadioSwitch from '../customCheckboxRadioSwitch'
import { buttonStyle } from "@hlcr/mui/Button/style";
import { accordionStyle } from "@hlcr/mui/Accordion/style";
// import React from 'react'

const challengeStyle = {
    infoCardFooter: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    solutionModalActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    imageCard: {
        padding: '0!important'
    },
    imageContainer: {
        overflow: 'hidden',
        marginBottom: 20
    },
    imageUploadWrapper: {
        position: 'relative',
        height: 0,
        width: '100%',
        paddingTop: '60%'
    },
    imageUploadForm: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%'
    },
    imageUploadFormRequired: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        color: 'red'
    },
    image: {
        borderRadius: 6,
        overflow: 'hidden',
        maxWidth: 400,
        maxHeight: 240
    },
    tooltip: {
        fontSize: '1.2em',
        letterSpacing: '.05em'
    },
    typeIcon: {
        color: grayColor
    },
    infoText: {
        fontWeight: '300',
        margin: '10px 0 30px',
        textAlign: 'center'
    },
    errorText: {
        color: dangerColor,
        textAlign: 'center'
    },
    inputAdornmentIcon: {
        color: '#555'
    },
    inputAdornment: {
        position: 'relative'
    },
    choice: {
        textAlign: 'center',
        marginTop: '20px'
    },
    chip: {
        margin: '2px 4px'
    },
    ...customSelectStyle,
    ...customCheckboxRadioSwitch,
    ...buttonStyle,
    ...accordionStyle,
    textOverFlow: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingLeft: 5,
        width: 300,
        float: 'left'
    },
    floatLeft: {
        float: 'left'
    },
    questionsMargin: {
        margin: '5px 0'
    },
    sectionAccordionTitleInput: {
      margin: 0,
      padding: 0,
    },
    stepActions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    actionsCenter: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    cardFooter: {
        padding: '0',
        paddingTop: '10px',
        margin: '0 15px 10px',
        borderRadius: '0',
        display: 'block',
        backgroundColor: 'transparent',
        border: '0'
    },
    buttonIcon: {
        color: primaryColor,
        ':disabled &': {
            color: grayColor
        }
    },
    buttonIconDanger: {
        color: dangerColor,
        ':disabled &': {
            color: grayColor
        }
    },
    buttonIconInfo: {
        color: infoColor,
        ':disabled &': {
            color: grayColor
        }
    },
    paperBorder: {
        border: '1px solid #ddd',
        borderRadius: '3px',
        width: '100%',
        overflow: 'hidden'
    },
    accordionTitleContainer: {
        float: 'left',
        display: 'flex',
        alignItems: 'center'
    },
    inlineErrorIcon: {
        marginRight: '0.4em',
        color: dangerColor
    }
}

export default challengeStyle
