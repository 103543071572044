// @flow

export type ChallengeGoldNuggetType = 'none' | 'static' | 'dynamic'

export type ChallengeGoldNuggetModelDto = {
	type: any,
	secret: any
}

export class ChallengeGoldNuggetModel {
	//type: ChallengeGoldNuggetType
	//secret: string

	constructor(type: ChallengeGoldNuggetType, secret: string) {
		this.type = type
		this.secret = secret
	}

	static fromDto(dto: ChallengeGoldNuggetModelDto): ChallengeGoldNuggetModel {
		if (!dto) {
			return new ChallengeGoldNuggetModel()
		}
		return new ChallengeGoldNuggetModel(dto.type, dto.secret)
	}

	toDto(): ChallengeGoldNuggetModelDto {
		return {
			type: this.type,
			secret: this.secret
		}
	}
}
