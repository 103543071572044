import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withIntl } from "@hlcr/ui/Intl";
import { StyledMarkdown } from "@hlcr/ui";

const styles = { textField: { width: 400 } };

class LongAnswerQuestion extends React.Component {
	onChange = event => {
		const value = event.target.value;
		const { onChange } = this.props;
		onChange(value);
	};

	render() {
		const { disabled, answer } = this.props;
		if (disabled && answer) {
			return <StyledMarkdown source={answer.longAnswer} headingOffset={2} />;
		} else return <div>Not yet implemented</div>;
	}
}

export default withStyles(styles)(withIntl(LongAnswerQuestion));
