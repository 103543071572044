import React from 'react'
import { Button } from "@hlcr/mui/Button";
import PublishIcon from '@material-ui/icons/Publish'
import withStyles from '@material-ui/core/styles/withStyles'
import deployComponentStyles from '../../shared/assets/jss/material-dashboard-pro-react/views/deployComponentStyles'
import PropTypes from 'prop-types'
import ShowGitlabStatus from '../../shared/helper/ShowGitlabStatus'
import CustomTooltip from '../../shared/components/CustomTooltip/CustomTooltip'

class DeployComponents extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			deployStatus: '',
			pipelineId: 0
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval)
	}

	render() {
		const { deployStatus } = this.state
		const { project, classes, deployProject } = this.props

		return (
			<React.Fragment>
				<DeployButton
					classes={classes}
					deployProject={deployProject}
					project={project}
					onDeployResult={this.onDeployResult}
				/>
				{deployStatus ? <ShowGitlabStatus status={deployStatus} /> : null}
			</React.Fragment>
		)
	}

	onDeployResult = result => {
		if (result) {
			this.setState({ pipelineId: result.id })
			this.setState({ deployStatus: result.status })
			this.interval = setInterval(
				() => this.props.fetchPipeline(result.id, this.onPipelineResult),
				5000
			)
		}
	}

	onPipelineResult = result => {
		if (result) {
			this.setState({ deployStatus: result.status })
			if (result.status.match(/^(success|failed|canceled|skipped)$/)) {
				clearInterval(this.interval)
			}
		}
	}
}

const DeployButton = ({ classes, deployProject, project, onDeployResult }) => (
	<CustomTooltip title="Deploy">
		<Button
			onClick={event => {
				event.stopPropagation()
				deployProject(project.uuid, onDeployResult)
			}}
			color="success"
			customClass={classes.actionButton}
		>
			<PublishIcon className={classes.icon} />
		</Button>
	</CustomTooltip>
)

DeployComponents.propTypes = {
	classes: PropTypes.object.isRequired,
	deployProject: PropTypes.func.isRequired,
	fetchPipeline: PropTypes.func.isRequired
}

export default withStyles(deployComponentStyles)(DeployComponents)
