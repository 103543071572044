import {
	dangerColor,
	defaultFont,
	primaryColor,
	successColor
} from 'shared/assets/jss/material-dashboard-pro-react.jsx'

const customInputStyle = {
	root: {
		marginTop: '0px'
	},
	feedback: {
		position: 'absolute',
		top: '20px',
		right: '0',
		zIndex: '2',
		display: 'block',
		width: '24px',
		height: '24px',
		textAlign: 'center',
		pointerEvents: 'none'
	},
	labelWithAdornment: {
		top: '3px'
	},
	feedbackAdorment: {
		right: '22px'
	},
	disabled: {
		'&:before': {
			borderColor: 'transparent !important'
		}
	},
	underline: {
		'&:hover:not($disabled):before,&:before': {
			borderColor: '#D2D2D2 !important',
			borderWidth: '1px !important'
		},
		'&:after': {
			borderColor: primaryColor
		}
	},
	underlineError: {
		'&:after': {
			borderColor: dangerColor
		}
	},
	underlineSuccess: {
		'&:after': {
			borderColor: successColor
		}
	},
	labelRoot: {
		...defaultFont,
		color: '#AAAAAA !important',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '1.42857',
	},
	labelRootError: {
		color: dangerColor + ' !important'
	},
	labelRootSuccess: {
		color: successColor + ' !important'
	},
	formControl: {
		margin: '0 0 17px 0',
		position: 'relative',
		verticalAlign: 'unset',
		'& svg,& .fab,& .far,& .fal,& .fas,& .material-icons': {
			color: '#495057'
		}
	},
	whiteUnderline: {
		'&:hover:not($disabled):before,&:before': {
			backgroundColor: '#FFFFFF'
		},
		'&:after': {
			backgroundColor: '#FFFFFF'
		}
	},
	input: {
		color: '#495057',
		'&,&::placeholder': {
			fontSize: '14px',
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: '400',
			lineHeight: '1.42857',
			opacity: '1'
		},
		'&::placeholder': {
			color: '#AAAAAA'
		}
	},
	inheritWidth: {
		width: "inherit !important"
	},
	whiteInput: {
		'&,&::placeholder': {
			color: '#FFFFFF',
			opacity: '1'
		}
	}
}

export default customInputStyle
