import React from 'react'
// material-ui components
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import cx from 'classnames'

const style = {
	grid: {
		margin: '0 -15px',
		width: 'calc(100% + 30px)'
		// '&:before,&:after':{
		//   display: 'table',
		//   content: '" "',
		// },
		// '&:after':{
		//   clear: 'both',
		// }
	}
}

function GridContainer({ ...props }) {
	const { classes, children, className, ...rest } = props
	return (
		<Grid container {...rest} className={cx(classes.grid, className)}>
			{children}
		</Grid>
	)
}

export default withStyles(style)(GridContainer)
