// @flow strict

import { TheorySectionModel } from './theorySection.model'

export type TheoryModelDto = {
	uuid: any,
	ownerEmail: any,
	createDate: any,
	createUser: any,
	modifyDate: any,
	modifyUser: any,
	modifyMessage: any,
	public: any,
	name: any,
	thumbnailImageUrl: any,
	keywords: any[],
	categories: any[],
	title: any,
	titleImageUrl: any,
	abstract: any,
	sections: any[],
	readOnly: any
}

export class TheoryModel {
	uuid: string
	ownerEmail: string
	createDate: ?Date
	createUser: string
	modifyDate: ?Date
	modifyUser: string
	modifyMessage: string
	isPublic: boolean
	name: string
	thumbnailImageUrl: string
	keywords: string[]
	categories: string[]
	title: string
	titleImageUrl: string
	abstract: string
	sections: TheorySectionModel[]
	readOnly: boolean

	constructor(
		uuid: string,
		ownerEmail: string,
		createDate: Date,
		createUser: string,
		modifyDate: Date,
		modifyUser: string,
		modifyMessage: string,
		isPublic: boolean,
		name: string,
		thumbnailImageUrl: string,
		keywords: string[],
		categories: string[],
		title: string,
		titleImageUrl: string,
		abstract: string,
		sections: TheorySectionModel[],
		readOnly: boolean
	) {
		this.uuid = uuid
		this.ownerEmail = ownerEmail
		this.createDate =
			createDate && isNaN(createDate.getTime()) ? null : createDate
		this.createUser = createUser
		this.modifyDate =
			modifyDate && isNaN(modifyDate.getTime()) ? null : modifyDate
		this.modifyUser = modifyUser
		this.modifyMessage = modifyMessage
		this.isPublic = isPublic
		this.name = name
		this.thumbnailImageUrl = thumbnailImageUrl
		this.keywords = keywords
		this.categories = categories
		this.title = title
		this.titleImageUrl = titleImageUrl
		this.abstract = abstract
		this.sections = sections
		this.readOnly = readOnly
	}

	static fromDto(dto: TheoryModelDto): ?TheoryModel {
		if (!dto) {
			return null
		}
		return new TheoryModel(
			dto.uuid,
			dto.ownerEmail,
			new Date(dto.createDate),
			dto.createUser,
			new Date(dto.modifyDate),
			dto.modifyUser,
			dto.modifyMessage,
			dto.public,
			dto.name,
			dto.thumbnailImageUrl,
			dto.keywords,
			dto.categories,
			dto.title,
			dto.titleImageUrl,
			dto.abstract,
			TheorySectionModel.fromDtoList(dto.sections),
			dto.readOnly
		)
	}

	static fromDtoList(dtos: TheoryModelDto[]): TheoryModel[] {
		if (!dtos) {
			return []
		}
		return dtos.map(dto => TheoryModel.fromDto(dto))
	}

	toDto(): TheoryModelDto {
		return {
			uuid: this.uuid,
			ownerEmail: this.ownerEmail,
			createDate: this.createDate ? this.createDate.toISOString() : '',
			createUser: this.createUser,
			modifyDate: this.modifyDate ? this.modifyDate.toISOString() : '',
			modifyUser: this.modifyUser,
			modifyMessage: this.modifyMessage,
			public: this.isPublic,
			name: this.name,
			thumbnailImageUrl: this.thumbnailImageUrl,
			keywords: this.keywords,
			categories: this.categories,
			title: this.title,
			titleImageUrl: this.titleImageUrl,
			abstract: this.abstract,
			sections: this.sections.map((section, index) => section.toDto(index))
		}
	}
}
