import { addNotification, API_REQUEST, removeApiSuccess, removeNotification, saveApiResult } from "../actions/api";
import guid from "../helper/guid";
import { getCookieValue } from "../auth/authUtils";

import { MessageType } from "../models/Message";

document.cookie = `CSRF-TOKEN=${guid()};path=/;`

export default (middleware) => (next) => async (action) => {
	const nextAction = next(action)

	if (action.type === API_REQUEST) {
		if (action.onBefore) {
			action.onBefore(middleware.dispatch)
		}

		let result,
			response = {}
		try {
			const init = {
				method: action.method,
				body: action.nonJsonBody
					? action.nonJsonBody
					: JSON.stringify(action.body || undefined),
				headers: createHeaders(),
				credentials: 'same-origin'
			}
			response = await fetch(`${action.url}`, init)
			result =
				response.status !== 204 && response.status !== 201
					? await response.json()
					: null
			if (
				response.status === 204 &&
				action.resource &&
				action.entity &&
				action.method === 'delete'
			) {
				middleware.dispatch(
					removeApiSuccess(
						action.resource,
						action.entity,
						action.updatePropForObj
					)
				)
			}
		} catch (e) {
		} finally {
			if (action.resource) {
				middleware.dispatch(
					saveApiResult(
						action.resource,
						response.ok ? result : undefined,
						action.updatePropForArray,
						action.updatePropForObj,
						action.updateField,
						action.setFieldOnObject
					)
				)
			}
		}

		if (response.ok) {
			if (action.onSuccess) action.onSuccess(middleware.dispatch, result)
			createSuccessMessage(
				middleware.dispatch,
				action.successNotification,
				action.method
			)
		} else {
			if (action.onFailure) action.onFailure(middleware.dispatch)

			const snackId = guid()
			middleware.dispatch(
				addNotification(snackId, buildMessage(response, result))
			)
			window.setTimeout(
				() => middleware.dispatch(removeNotification(snackId)),
				6000
			)
		}
	}

	return nextAction
}

const buildMessage = (response, result) => {
	if (response.status === 403)
		return (
			'Request Forbidden (403)' +
			(result && result.message ? `: ${result.message}` : '')
		)

	if (result && result.message) return result.message
	else
		return `${response.statusText || 'API error'} (${response.status ||
		                                                 'unknown'})`
}

const createHeaders = () => {
	const headers = new Headers()
	headers.append('Content-Type', 'application/json')
	headers.append('X-CSRF-TOKEN', getCookieValue('CSRF-TOKEN'))
	return headers
}

const getGenericSuccessMessage = (method) => {
	switch (method) {
		case 'get':
			return 'Received successfully'
		case 'post':
			return 'Created successfully'
		default:
			return 'Updated successfully'
	}
}

export const createSuccessMessage = (
	dispatch,
	successNotification,
	method = 'get'
) => {
	if (successNotification) {
		const snackId = guid()
		dispatch(
			addNotification(
				snackId,
				typeof successNotification === 'string'
					? successNotification
					: getGenericSuccessMessage(method),
				MessageType.SUCCESS
			)
		)
		window.setTimeout(() => dispatch(removeNotification(snackId)), 4000)
	}
}
