import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import {
	fetchTheoryProjects,
	fetchTheoriesByInputSearch
} from "actions/theory.actions"
import eventUnitsStyle from '../../shared/assets/jss/material-dashboard-pro-react/views/eventUnitsStyle'
import { withStyles } from '@material-ui/core/styles'
import { withIntl } from "@hlcr/ui/Intl"
import { PaginatedDataModel } from "shared/models/paginatedData.model"
import List from './List'

class TheorySearchList extends React.Component {
	pathSegment = 'theories'
	languageKey = 'theory'

	constructor(props) {
		super(props)
		this.state = {
			search: ''
		}
	}

	componentDidMount() {
		const { searchQuery } = this.props
		if (searchQuery) {
			this.setState({ search: searchQuery })
			this.props.fetchTheoriesByInputSearch(searchQuery)
		} else {
			this.fetchPage(1)
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timer)
	}

	fetchPage = page => {
		this.props.history.push(`/${this.pathSegment}/page/${page}`)
		this.props.fetchTheoryProjects(page)
	}

	triggerChange = (search, WAIT_INTERVAL) => {
		if (search && search.length > 0) {
			clearTimeout(this.timer)
			this.setState({ search: search })
			this.timer = setTimeout(() => {
				this.props.history.push(`/${this.pathSegment}/search?q=${search}`)
				this.props.fetchTheoriesByInputSearch(search)
			}, WAIT_INTERVAL)
		} else {
			this.fetchPage(1)
		}
	}

	render() {
		const { paginatedTheories, history } = this.props
		return (
			<List
				paginatedTheories={paginatedTheories}
				triggerChange={this.triggerChange}
				fetchPage={this.fetchPage}
				languageKey={this.languageKey}
				pathSegment={this.pathSegment}
				inputSearchValue={this.state.search}
				history={history}
			/>
		)
	}
}

TheorySearchList.propTypes = {
	paginatedTheories: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
	const query = new URLSearchParams(ownProps.location.search)
	const searchQuery = query.get('q') ? query.get('q') : ''
	const paginatedTheories = state.api.resources.theories.data.find(
		theory => parseInt(theory.page, 10) === 1
	)
	return {
		searchQuery: searchQuery,
		paginatedTheories: PaginatedDataModel.fromDtoWithTheories(paginatedTheories)
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchTheoryProjects,
			fetchTheoriesByInputSearch
		},
		dispatch
	)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(eventUnitsStyle),
	withIntl
)(TheorySearchList)
