import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
// core components
import GridContainer from "shared/components/Grid/GridContainer.jsx";
import GridItem from "shared/components/Grid/GridItem.jsx";
import challengeStyle from "shared/assets/jss/material-dashboard-pro-react/views/challengeStyle";
import CustomCheckbox from "shared/components/CustomSwitch/CustomCheckbox";
import { RadioBox } from "shared/components/CustomSwitch/CustomRadio";
import { Button } from "@hlcr/mui/Button";
import { QuestionModel, QuestionType } from "shared/models/question.model";
import { ChoiceOptionModel } from "shared/models/option.model";
import { QuizModel } from "shared/models/quiz.model";
import MediaListForMarkdown from "shared/components/Media/MediaListForMarkdown";
import MarkdownInput from "components/MarkdownInput/MarkdownInput";
import CustomIconButton from "components/Button/CustomIconButton";
import StepsAccordion from "components/Accordion/StepsAccordion";
import CustomSelect from "shared/components/CustomSelect/CustomSelect";
import CustomInput from "shared/components/CustomInput/CustomInput";

class QuestionsStep extends React.Component {
	constructor(props) {
		super(props);
		const quiz: QuizModel = this.props.currentQuiz;
		this.state = {
			questions: quiz.questions.slice(0),
			questionMediaFiles: [],
			activeQuestion: 0,
			activeQuestionOption: new Array(quiz.questions.length).fill(0),
		};
	}

	sendState() {
		return this.state;
	}

	handleContentChange = (event, questionIndex, optionIndex) => {
		const value = event.target.value;
		if (optionIndex !== undefined) {
			this.changeOptionField("content", value, questionIndex, optionIndex);
		} else {
			this.changeQuestionField("content", value, questionIndex);
		}
	};

	handleShortAnswerChange = (event, questionIndex, optionIndex) => {
		const value = event.target.value;
		if (optionIndex !== undefined) {
			this.changeShortAnswerField(value, questionIndex, optionIndex);
		} else {
			this.changeQuestionField("content", value, questionIndex);
		}
	};

	handleLongAnswerChange = (event, questionIndex, optionIndex) => {
		const value = event.target.value;
		if (optionIndex !== undefined) {
			this.changeLongAnswerField(value, questionIndex);
		} else {
			this.changeQuestionField("content", value, questionIndex);
		}
	};

	changeOptionField = (field, value, questionIndex, optionIndex) => {
		const questions = this.state.questions.slice(0);
		const question = questions[questionIndex];
		const option = question.options[optionIndex];
		question.options[optionIndex] = Object.assign({}, option, {
			[field]: value,
		});
		questions[questionIndex] = Object.assign({}, question);
		this.setState({ questions });
	};

	changeShortAnswerField = (value, questionIndex, shortAnswerIndex) => {
		const questions = this.state.questions.slice(0);
		const question = questions[questionIndex];
		question.shortAnswers[shortAnswerIndex] = value;
		questions[questionIndex] = Object.assign({}, question);
		this.setState({ questions });
	};

	changeLongAnswerField = (value, questionIndex) => {
		const questions = this.state.questions.slice(0);
		const question = questions[questionIndex];
		question.longAnswer = value;
		questions[questionIndex] = Object.assign({}, question);
		this.setState({ questions });
	};

	changeQuestionField = (
		field,
		value,
		questionIndex,
		additionalStateChanges = {},
	) => {
		const questions = this.state.questions.slice(0);
		const question = questions[questionIndex];
		questions[questionIndex] = Object.assign({}, question, { [field]: value });
		this.setState({ questions, ...additionalStateChanges });
	};

	handleToggle = (questionIndex, optionIndex) => event =>
		this.setCorrectOption(questionIndex, optionIndex, event.target.checked);

	setCorrectOption = (questionIndex, optionIndex, checked) => {
		const questions = this.state.questions.slice(0);
		const question = questions[questionIndex];
		if (question.type === QuestionType.MULTIPLE_CHOICE) {
			this.changeOptionField("correct", checked, questionIndex, optionIndex);
		} else {
			let options = question.options;
			options = options.map((option, index) => ({
				...option,
				correct: index === optionIndex,
			}));
			this.changeQuestionField("options", options, questionIndex);
		}
	};

	handleDeleteQuestion = (questionIndex) => {
		const questions = this.state.questions.slice(0);
		questions.splice(questionIndex, 1);
		this.setState({ questions, activeQuestion: questionIndex === questions.length ? questionIndex - 1 : questionIndex });
	};

	handleDeleteOption = (questionIndex, optionIndex) => {
		const questions = this.state.questions.slice(0);
		const question = questions[questionIndex];
		const options = question.options.slice(0);
		const removedOption = options.splice(optionIndex, 1)[0];
		if (
			question.type === QuestionType.SINGLE_CHOICE &&
			removedOption &&
			removedOption.correct
		) {
			options[0].correct = true;
		}
		this.changeQuestionField("options", options, questionIndex);
	};

	handleDeleteShortAnswer = (questionIndex, shortAnswerIndex) => {
		const questions = this.state.questions.slice(0);
		if (shortAnswerIndex !== undefined) {
			const question = questions[questionIndex];
			question.shortAnswers.splice(shortAnswerIndex, 1);
			this.setState({ questions });
		} else {
			questions.splice(questionIndex, 1);
			this.setState({
				              questions,
				              activeQuestion:
					              questionIndex === questions.length ? questionIndex - 1 : questionIndex,
			              });
		}
	};

	handleDeleteLongAnswer = (questionIndex, longAnswerIndex) => {
		const questions = this.state.questions.slice(0);
		if (longAnswerIndex !== undefined) {
			this.changeLongAnswerField("", questionIndex);
		} else {
			questions.splice(questionIndex, 1);
			this.setState({
				              questions,
				              activeQuestion:
					              questionIndex === questions.length ? questionIndex - 1 : questionIndex,
			              });
		}
	};

	moveUpInArray = (array, elementIndex) => {
		const element = array[elementIndex];
		array.splice(elementIndex, 1);
		array.splice(elementIndex - 1, 0, element);
	};

	moveDownInArray = (array, elementIndex) => {
		const element = array[elementIndex];
		array.splice(elementIndex, 1);
		array.splice(elementIndex + 1, 0, element);
	};

	handleMoveUp = (questionIndex, optionIndex) => {
		const questions = this.state.questions.slice(0);
		let activeQuestion = this.state.activeQuestion;
		const activeQuestionOption = this.state.activeQuestionOption;
		if (optionIndex !== undefined && optionIndex > 0) {
			const options = questions[questionIndex].options.slice(0);
			this.moveUpInArray(options, optionIndex);
			activeQuestionOption[questionIndex] =
				activeQuestionOption[questionIndex] - 1;
			this.changeQuestionField("options", options, questionIndex);
		} else if (questionIndex > 0) {
			this.moveUpInArray(questions, questionIndex);
			activeQuestion = activeQuestion - 1;
			this.setState({ questions, activeQuestion });
		}
	};

	handleMoveDown = (questionIndex, optionIndex) => {
		const questions = this.state.questions.slice(0);
		let activeQuestion = this.state.activeQuestion;
		const activeQuestionOption = this.state.activeQuestionOption;
		if (
			optionIndex !== undefined &&
			optionIndex < questions[questionIndex].options.length - 1
		) {
			const options = questions[questionIndex].options.slice(0);
			this.moveDownInArray(options, optionIndex);
			activeQuestionOption[questionIndex] =
				activeQuestionOption[questionIndex] + 1;
			this.changeQuestionField("options", options, questionIndex);
		} else if (questionIndex < questions.length - 1) {
			this.moveDownInArray(questions, questionIndex);
			activeQuestion = activeQuestion + 1;
			this.setState({ questions, activeQuestion });
		}
	};

	handleQuestionOptionAccordionChange = (
		questionIndex: number,
		panel: number,
	) => (event, expanded) => {
		let activeQuestionOption = this.state.activeQuestionOption;
		activeQuestionOption[questionIndex] = expanded ? panel : -1;
		this.setState({ activeQuestionOption: activeQuestionOption });
	};

	uploadMedia = (file, callback) => {
		this.props.uploadMedia(
			file.byteData,
			file.name,
			this.props.currentQuiz.uuid,
			mediaFile => callback(mediaFile),
			`${file.name} uploaded`,
		);
	};

	longAnswerList = (question, questionIndex) => {
		const { classes } = this.props;
		const { activeQuestionOption } = this.state;
		if (question.longAnswer !== undefined) {
			let longAnswerAsArray = [ question.longAnswer ];
			return (
				<div style={{ marginTop: "20px" }}>
					<StepsAccordion
						overrideActiveSectionIndex={activeQuestionOption[questionIndex]}
						onChange={panel => this.handleQuestionOptionAccordionChange(questionIndex, panel)}
						sections={longAnswerAsArray.map((longAnswer, index: number) => {
							return {
								title: `LongAnswer ${questionIndex + 1}`,
								content: (
									<div style={{ width: "100%", overflow: "hidden" }}>
										<MarkdownInput
											placeholder={`LongAnswer ${questionIndex + 1}`}
											name={index}
											content={longAnswer}
											onChange={event =>
												this.handleLongAnswerChange(event, questionIndex, index)
											}
											headingOffset={3}
											plainTabs={true}
										/>
									</div>
								),
								actions: (
									<div className={classes.stepActions}>
										<CustomIconButton
											disabled={longAnswer.length === 1}
											onClick={() =>
												this.handleDeleteLongAnswer(questionIndex, index)
											}
										>
											<DeleteIcon
												className={classes.buttonIconDanger}
												fontSize="small"
											/>
										</CustomIconButton>
									</div>
								),
							};
						})}
					/>
				</div>
			);
		} else {
			return (
				<div className={classes.actionsCenter}>
					<Button
						variant="contained"
						color="primary"
						onClick={() => this.handleAddNewLongAnswerAtEnd(questionIndex)}
					>
						Add new Answer
					</Button>
				</div>
			);
		}
	};

	shortAnswerList = (question, questionIndex) => {
		const { classes } = this.props;
		const { activeQuestionOption } = this.state;
		if (question.shortAnswers && question.shortAnswers.length > 0) {
			return (
				<div style={{ marginTop: "20px" }}>
					<StepsAccordion
						initiallyActiveSectionIndex={activeQuestionOption[questionIndex]}
						onHandleChange={panel =>
							this.handleQuestionOptionAccordionChange(questionIndex, panel)
						}
						sections={question.shortAnswers.map(
							(shortAnswer, index: number) => {
								return {
									title: `ShortAnswer ${questionIndex + 1}.${index + 1}`,
									content: (
										<div style={{ width: "100%", overflow: "hidden" }}>
											<div style={{ padding: 8 * 3, paddingTop: 0 }}>
												<CustomInput
													formControlProps={{ fullWidth: true }}
													inputProps={{
														onChange: event =>
															this.handleShortAnswerChange(
																event,
																questionIndex,
																index,
															),
														value: shortAnswer,
													}}
												/>
											</div>
										</div>
									),
									actions: (
										<div className={classes.stepActions}>
											<CustomIconButton
												disabled={shortAnswer.length === 1}
												onClick={() =>
													this.handleDeleteShortAnswer(questionIndex, index)
												}
											>
												<DeleteIcon
													className={classes.buttonIconDanger}
													fontSize="small"
												/>
											</CustomIconButton>
											<CustomIconButton
												onClick={() =>
													this.handleAddNewShortAnswerAtPosition(
														questionIndex,
														index,
													)
												}
											>
												<PlaylistAddIcon
													className={classes.buttonIconInfo}
													fontSize="small"
												/>
											</CustomIconButton>
										</div>
									),
								};
							},
						)}
					/>
				</div>
			);
		} else {
			return (
				<div className={classes.actionsCenter}>
					<Button
						variant="contained"
						color="primary"
						onClick={() => this.handleAddNewShortAnswerAtEnd(questionIndex)}
					>
						Add new Answer
					</Button>
				</div>
			);
		}
	};

	optionList = (options, question, questionIndex) => {
		const { classes } = this.props;
		const { activeQuestionOption } = this.state;
		if (options && options.length > 0) {
			return (
				<div style={{ marginTop: "20px" }}>
					<StepsAccordion
						initiallyActiveSectionIndex={activeQuestionOption[questionIndex]}
						onHandleChange={panel =>
							this.handleQuestionOptionAccordionChange(questionIndex, panel)
						}
						sections={options.map((option, index: number) => {
							let optionChecked = "";
							if (
								question.type &&
								question.type !== QuestionType.SHORT_ANSWER
							) {
								if (option.correct) {
									optionChecked = "(correct)";
								} else {
									optionChecked = "(wrong)";
								}
							}
							return {
								title: `Option ${questionIndex + 1}.${index +
								                                      1} ${optionChecked}`,
								content: (
									<div style={{ width: "100%", overflow: "hidden" }}>
										<MarkdownInput
											placeholder={`Option ${questionIndex + 1}.${index + 1}`}
											name={index}
											content={option.content}
											onChange={event =>
												this.handleContentChange(event, questionIndex, index)
											}
											headingOffset={3}
											plainTabs={true}
											actions={
												question.type === QuestionType.MULTIPLE_CHOICE ? (
													<CustomCheckbox
														checked={option.correct}
														onChange={this.handleToggle(questionIndex, index)}
														label="correct"
													/>
												) : question.type === QuestionType.SINGLE_CHOICE ? (
													<RadioBox
														checked={option.correct}
														onChange={this.handleToggle(questionIndex, index)}
														label="correct"
													/>
												) : null
											}
										/>
									</div>
								),
								actions: (
									<div className={classes.stepActions}>
										<CustomIconButton
											disabled={index === 0}
											onClick={() => this.handleMoveUp(questionIndex, index)}
										>
											<ArrowUpwardIcon
												className={classes.buttonIcon}
												fontSize="small"
											/>
										</CustomIconButton>
										<CustomIconButton
											disabled={index + 1 === options.length}
											onClick={() => this.handleMoveDown(questionIndex, index)}
										>
											<ArrowDownwardIcon
												className={classes.buttonIcon}
												fontSize="small"
											/>
										</CustomIconButton>
										<CustomIconButton
											disabled={options.length === 1}
											onClick={() =>
												this.handleDeleteOption(questionIndex, index)
											}
										>
											<DeleteIcon
												className={classes.buttonIconDanger}
												fontSize="small"
											/>
										</CustomIconButton>
										<CustomIconButton
											onClick={() =>
												this.handleAddNewOptionAtPosition(questionIndex, index)
											}
										>
											<PlaylistAddIcon
												className={classes.buttonIconInfo}
												fontSize="small"
											/>
										</CustomIconButton>
									</div>
								),
							};
						})}
					/>
				</div>
			);
		} else {
			return (
				<div className={classes.actionsCenter}>
					<Button
						variant="contained"
						color="primary"
						onClick={() => this.handleAddNewOptionAtEnd(questionIndex)}
					>
						Add new Option
					</Button>
				</div>
			);
		}
	};

	handleQuestionAccordionChange = (panel: number) => (event, expanded) => {
		this.setState({ activeQuestion: expanded ? panel : -1 });
	};

	questionsList = classes => {
		const { activeQuestion, questions } = this.state;
		if (questions && questions.length > 0) {
			return (
				<StepsAccordion
					initiallyActiveSectionIndex={activeQuestion}
					onHandleChange={this.handleQuestionAccordionChange}
					sections={questions.map(
						(question: QuestionModel, index: number) => ({
							title: (
								<span>
									<div className={classes.floatLeft}>Question {index + 1}</div>
									{question.content && question.content.length > 0 ? (
										<span className={classes.floatLeft}>&nbsp;-</span>
									) : (
										<span className={classes.floatLeft}>&nbsp;</span>
									)}
									<div className={classes.textOverFlow}>{question.content}</div>
								</span>
							),
							content: (
								<div style={{ width: "100%", overflow: "hidden" }}>
									<MarkdownInput
										placeholder={`Question ${question.uuid}`}
										name={index}
										content={question.content}
										onChange={event => this.handleContentChange(event, index)}
										headingOffset={3}
										plainTabs={true}
										actions={
											<CustomSelect
												title="Select Question Type"
												onSelect={this.setQuestionType(index)}
												value={{
													id: question.type,
													display: this.getQuestionType(question.type),
												}}
												list={this.questionTypeList}
												displayField="display"
												idField="id"
											/>
										}
									/>
									<div
										style={{ padding: "0 24px", borderTop: "1px #eee solid" }}
									>
										{question.type === QuestionType.SHORT_ANSWER
											? this.shortAnswerList(question, index)
											: null}
										{question.type === QuestionType.LONG_ANSWER
											? this.longAnswerList(question, index)
											: null}
										{question.type === QuestionType.SINGLE_CHOICE ||
										 question.type === QuestionType.MULTIPLE_CHOICE
											? this.optionList(question.options, question, index)
											: null}
									</div>
								</div>
							),
							actions: (
								<div className={classes.stepActions}>
									<CustomIconButton
										disabled={index === 0}
										onClick={() => this.handleMoveUp(index)}
									>
										<ArrowUpwardIcon
											className={classes.buttonIcon}
											fontSize="small"
										/>
									</CustomIconButton>
									<CustomIconButton
										disabled={index + 1 === questions.length}
										onClick={() => this.handleMoveDown(index)}
									>
										<ArrowDownwardIcon
											className={classes.buttonIcon}
											fontSize="small"
										/>
									</CustomIconButton>
									<CustomIconButton onClick={() => this.handleDeleteQuestion(index)}>
										<DeleteIcon
											className={classes.buttonIconDanger}
											fontSize="small"
										/>
									</CustomIconButton>
									<CustomIconButton
										onClick={() => this.handleAddNewQuestionAtPosition(index)}
									>
										<AddIcon
											className={classes.buttonIconInfo}
											fontSize="small"
										/>
									</CustomIconButton>
								</div>
							),
						}),
					)}
				/>
			);
		} else {
			return (
				<div className={classes.actionsCenter}>
					<Button
						variant="contained"
						color="primary"
						onClick={this.handleAddNewQuestionAtEnd}
					>
						Add new Question
					</Button>
				</div>
			);
		}
	};

	getQuestionType = questionType => {
		const { intl } = this.props;
		return intl.fm(`quiz.questionType.${questionType}`);
	};

	questionTypeList = [
		{
			id: QuestionType.MULTIPLE_CHOICE,
			display: this.getQuestionType(QuestionType.MULTIPLE_CHOICE),
		},
		{
			id: QuestionType.SINGLE_CHOICE,
			display: this.getQuestionType(QuestionType.SINGLE_CHOICE),
		},
		{
			id: QuestionType.SHORT_ANSWER,
			display: this.getQuestionType(QuestionType.SHORT_ANSWER),
		},
		{
			id: QuestionType.LONG_ANSWER,
			display: this.getQuestionType(QuestionType.LONG_ANSWER),
		},
	];

	setQuestionType = questionIndex => event => {
		const questions = this.state.questions.slice(0);
		const question = Object.assign({}, questions[questionIndex]);
		questions[questionIndex] = question;

		const questionType = event.target.value;
		question.type = questionType;
		let setCorrectOption = null;
		if (questionType === QuestionType.SINGLE_CHOICE) {
			const firstCorrectOption = question.options.find(o => o.correct);
			const newCorrectOptionIndex = firstCorrectOption
				? question.options.indexOf(firstCorrectOption)
				: 0;
			setCorrectOption = () =>
				this.setCorrectOption(questionIndex, newCorrectOptionIndex, true);
		}

		this.setState({ questions }, setCorrectOption);
	};

	handleAddNewQuestionAtPosition = (positionIndex: number) => {
		const questions = this.state.questions.slice(0);
		if (positionIndex < questions.length) {
			questions.splice(
				positionIndex + 1,
				0,
				new QuestionModel(
					"",
					positionIndex + 1,
					"",
					QuestionType.MULTIPLE_CHOICE,
					[],
				),
			);
			questions.map((question, index) => (question.order = index + 1));
			const activeQuestion = positionIndex + 1;
			this.setState({ questions: questions, activeQuestion });
		}
	};

	handleAddNewQuestionAtEnd = () => {
		const questions = this.state.questions.slice(0);
		const position = this.state.questions.length + 1;
		const newQuestion = new QuestionModel(
			"",
			position,
			"",
			QuestionType.MULTIPLE_CHOICE,
			[],
		);
		questions.push(newQuestion);

		this.setState(
			{
				questions: questions,
				activeQuestion: questions.length - 1,
			},
			() => this.handleAddNewOptionAtEnd(this.state.questions.length - 1),
		);
	};

	handleAddNewOptionAtPosition = (
		questionIndex: number,
		positionIndex: number,
	) => {
		const questions = this.state.questions.slice(0);
		const activeQuestionOption = this.state.activeQuestionOption;
		if (
			questionIndex < questions.length &&
			positionIndex < questions[questionIndex].options.length
		) {
			// allways add ChoiceOption. Transform to shortAnwserOption on saving
			questions[questionIndex].options.splice(
				positionIndex + 1,
				0,
				new ChoiceOptionModel("", 0, "", false),
			);
			questions[questionIndex].options.map(
				(option, index) => (option.order = index + 1),
			);
			activeQuestionOption[questionIndex] = positionIndex + 1;
			this.setState({ questions, activeQuestionOption });
		}
	};

	handleAddNewShortAnswerAtPosition = (
		questionIndex: number,
		positionIndex: number,
	) => {
		const questions = this.state.questions.slice(0);
		const activeQuestionOption = this.state.activeQuestionOption;
		if (
			questionIndex < questions.length &&
			positionIndex < questions[questionIndex].shortAnswers.length
		) {
			questions[questionIndex].shortAnswers.splice(positionIndex + 1, 0, "");
			activeQuestionOption[questionIndex] = positionIndex + 1;
			this.setState({ questions, activeQuestionOption });
		}
	};

	handleAddNewOptionAtEnd = (questionIndex: number) => {
		const options = this.state.questions[questionIndex].options.slice(0);
		// allways add ChoiceOption. Transform to shortAnwserOption on saving
		const option = new ChoiceOptionModel("", options.length + 1, "", false);
		options.push(option);
		this.changeQuestionField("options", options, questionIndex);
	};

	handleAddNewShortAnswerAtEnd = (questionIndex: number) => {
		const shortAnswers = this.state.questions[questionIndex].shortAnswers.slice(
			0,
		);
		shortAnswers.push("");
		this.changeQuestionField("shortAnswers", shortAnswers, questionIndex);
	};

	handleAddNewLongAnswerAtEnd = (questionIndex: number) => {
		const longAnswer = "";
		this.changeQuestionField("longAnswer", longAnswer, questionIndex);
	};

	render() {
		const { classes } = this.props;
		return (
			<div>
				<h4 className={classes.infoText}>Questions</h4>
				<GridContainer justify="center">
					<GridItem xs={12} sm={3}>
						<MediaListForMarkdown
							uploadedMediaFiles={this.state.questionMediaFiles}
							uploadMedia={this.uploadMedia}
						/>
					</GridItem>
					<GridItem xs={12} sm={9}>
						{this.questionsList(classes)}
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

export default withStyles(challengeStyle)(QuestionsStep);
