import { getDevelopmentToken } from "@hlcr/app/model/HackingLabRole";
import { keycloak } from "@hlcr/ui/auth";
import "chartist/dist/chartist.js";

import "shared/assets/scss/material-dashboard-pro-react.scss";
import "polyfills.js";
import { createApp } from "App";
import { isLocalServerEnvironment, isProductionEnvironment } from "ProcessEnvironment";

keycloak.init(() => createApp(), !isProductionEnvironment() && isLocalServerEnvironment() ? getDevelopmentToken() : undefined);
