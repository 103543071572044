import React from 'react'
import Table from '../../../../components/Table/Table'
import { grayColor } from "shared/assets/jss/material-dashboard-pro-react"
import withStyles from '@material-ui/core/styles/withStyles'

const resourceTableStyles = {
	typeIcon: {
		color: grayColor,
		height: 24
	},
	tooltip: {
		fontSize: '1.2em',
		letterSpacing: '.05em'
	},
	summaryBox: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		'& > div': {
			marginRight: 6
		}
	}
}

const ResourceTable = ({ resources }) => {
	const tableRenderer = [{ renderCell: row => row.value }]

	const tableData = resources.map((resource, index) => {
		return { value: <div key={index}>{resource}</div> }
	})

	return <Table tableRenderer={tableRenderer} tableData={tableData} hover />
}

export default withStyles(resourceTableStyles)(ResourceTable)
