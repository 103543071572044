import connect from 'react-redux/es/connect/connect'
import React from 'react'
import type { AppState } from '../../reducers'

let TheoryBreadCrumb = ({ theory }) => (
	<span>{theory ? theory.name || 'New Theory' : 'Theories'}</span>
)

const mapStateToPropsTheory = (state: AppState, ownProps) => {
	const theoryUuid = ownProps.match.params.theoryUuid
	return {
		theory: state.api.resources.currentTheory.data.find(
			c => c.uuid === theoryUuid
		)
	}
}

TheoryBreadCrumb = connect(mapStateToPropsTheory)(TheoryBreadCrumb)
export default TheoryBreadCrumb
