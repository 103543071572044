import type { Dto } from './index'
import type { ChallengeModelDto } from '../shared/models/challenge.model'

export type ChallengeState = {
	challenges: {
		pending: boolean,
		data: Dto[]
	},
	currentChallenge: {
		pending: boolean,
		data: ChallengeModelDto[]
	}
}

export const challengeInitState: ChallengeState = {
	challenges: {
		pending: false,
		data: []
	},
	currentChallenge: {
		pending: false,
		data: []
	}
}
