// @flow
export type QuizLevelDto = '1' | '2' | '3' | '4' | '5'

export type QuizLevelDataModel = {
	id: number,
	name: string,
	color: string
}

export const quizLevels: QuizLevelDataModel[] = [
	{ id: 1, name: 'novice', color: '#23CCEF' },
	{ id: 2, name: 'easy', color: '#87cb16' },
	{ id: 3, name: 'medium', color: '#FFA534' },
	{ id: 4, name: 'hard', color: '#FB404B' },
	{ id: 5, name: 'leet', color: '#444444' }
]

export class QuizLevelModel {
	id: QuizLevelDto
	name: string
	color: string

	constructor(id: QuizLevelDto, name: string, color: string) {
		this.id = id
		this.name = name
		this.color = color
	}

	static fromData(data: QuizLevelDataModel) {
		return new QuizLevelModel(data.id, data.name, data.color)
	}

	static fromDto(dto: QuizLevelDto): QuizLevelModel {
		if (!dto) {
			return QuizLevelModel.fromData(quizLevels[0])
		}
		const quizLevel: QuizLevelDataModel = quizLevels.find(
			e => e.id === parseInt(dto, 10)
		)
		if (quizLevel) {
			return new QuizLevelModel(quizLevel.id, quizLevel.name, quizLevel.color)
		} else {
			console.warn(`[QuizLevelModel.fromDto] unknown quizLevel: ${dto}`)
			return QuizLevelModel.fromData(quizLevels[0])
		}
	}

	toDto(): QuizLevelDto {
		return this.id
	}
}
