// @flow strict

import { ChallengeGoldNuggetModel } from './challengeGoldNugget.model'
import { ChallengeLevelModel } from './challengeLevel.model'
import { SectionModel } from './section.model'

export type ChallengeType = 'jeopardy' | 'ctf'

export type ChallengeUsage = 'ctf' | 'training'

export type ChallengeModelDto = {
	uuid: any,
	ownerEmail: any,
	createDate: any,
	createUser: any,
	modifyDate: any,
	modifyUser: any,
	modifyMessage: any,
	public: any,
	name: any,
	thumbnailImageUrl: any,
	type: any,
	level: any,
	usages: any[],
	keywords: any[],
	categories: any[],
	goldNugget: any,
	title: any,
	titleImageUrl: any,
	abstract: any,
	grading: any,
	sections: any[],
	resources: any[],
	readOnly: any
}

export class ChallengeModel {
	uuid: string
	ownerEmail: string
	createDate: ?Date
	createUser: string
	modifyDate: ?Date
	modifyUser: string
	modifyMessage: string
	isPublic: boolean
	name: string
	thumbnailImageUrl: string
	type: ChallengeType
	level: ChallengeLevelModel
	usages: string[]
	keywords: string[]
	categories: string[]
	goldNugget: ChallengeGoldNuggetModel
	title: string
	titleImageUrl: string
	abstract: string
	grading: string
	sections: SectionModel[]
	resources: string[]
	readOnly: false

	constructor(
		uuid: string,
		ownerEmail: string,
		createDate: Date,
		createUser: string,
		modifyDate: Date,
		modifyUser: string,
		modifyMessage: string,
		isPublic: boolean,
		name: string,
		thumbnailImageUrl: string,
		type: ChallengeType,
		level: ChallengeLevelModel,
		usages: string[],
		keywords: string[],
		categories: string[],
		goldNugget: ChallengeGoldNuggetModel,
		title: string,
		titleImageUrl: string,
		abstract: string,
		grading: string,
		sections: SectionModel[],
		resources: string[],
		readOnly: boolean
	) {
		this.uuid = uuid
		this.ownerEmail = ownerEmail
		this.createDate =
			createDate && isNaN(createDate.getTime()) ? null : createDate
		this.createUser = createUser
		this.modifyDate =
			modifyDate && isNaN(modifyDate.getTime()) ? null : modifyDate
		this.modifyUser = modifyUser
		this.modifyMessage = modifyMessage
		this.isPublic = isPublic
		this.name = name
		this.thumbnailImageUrl = thumbnailImageUrl
		this.type = type
		this.level = level
		this.usages = usages
		this.keywords = keywords
		this.categories = categories
		this.goldNugget = goldNugget
		this.title = title
		this.titleImageUrl = titleImageUrl
		this.abstract = abstract
		this.grading = grading
		this.sections = sections
		this.resources = !resources ? [] : resources
		this.readOnly = readOnly
	}

	static fromDto(dto: ChallengeModelDto): ?ChallengeModel {
		if (!dto) {
			return null
		}
		return new ChallengeModel(
			dto.uuid,
			dto.ownerEmail,
			new Date(dto.createDate),
			dto.createUser,
			new Date(dto.modifyDate),
			dto.modifyUser,
			dto.modifyMessage,
			dto.public,
			dto.name,
			dto.thumbnailImageUrl,
			dto.type,
			ChallengeLevelModel.fromDto(dto.level),
			dto.usages,
			dto.keywords,
			dto.categories,
			ChallengeGoldNuggetModel.fromDto(dto.goldNugget),
			dto.title,
			dto.titleImageUrl,
			dto.abstract,
			dto.grading,
			SectionModel.fromDtoList(dto.sections),
			dto.resources, //ResourceModel.fromDtoList(dto.resources)
			dto.readOnly
		)
	}

	static fromDtoList(dtos: ChallengeModelDto[]): ChallengeModel[] {
		if (!dtos) {
			return []
		}
		return dtos.map(dto => ChallengeModel.fromDto(dto))
	}

	toDto(): ChallengeModelDto {
		return {
			uuid: this.uuid,
			ownerEmail: this.ownerEmail,
			createDate: this.createDate ? this.createDate.toISOString() : '',
			createUser: this.createUser,
			modifyDate: this.modifyDate ? this.modifyDate.toISOString() : '',
			modifyUser: this.modifyUser,
			modifyMessage: this.modifyMessage,
			public: this.isPublic,
			name: this.name,
			thumbnailImageUrl: this.thumbnailImageUrl,
			type: this.type,
			level: this.level.id,
			usages: this.usages,
			keywords: this.keywords,
			categories: this.categories,
			goldNugget: this.goldNugget.toDto(),
			title: this.title,
			titleImageUrl: this.titleImageUrl,
			abstract: this.abstract,
			grading: this.grading,
			sections: this.sections.map((section, index) => section.toDto(index)),
			resources: this.resources
		}
	}
}
