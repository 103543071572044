import connect from 'react-redux/es/connect/connect'
import React from 'react'
import type { AppState } from '../../reducers'

let QuizBreadCrumb = ({ quiz }) => (
	<span>{quiz ? quiz.name || 'New Quiz' : 'Quizzes'}</span>
)

const mapStateToPropsQuiz = (state: AppState, ownProps) => {
	const quizUuid = ownProps.match.params.quizUuid
	return {
		quiz: state.api.resources.currentQuiz.data.find(c => c.uuid === quizUuid)
	}
}

QuizBreadCrumb = connect(mapStateToPropsQuiz)(QuizBreadCrumb)
export default QuizBreadCrumb
