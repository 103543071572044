import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import Checkbox from '@material-ui/core/Checkbox'
// core components
import GridContainer from '../../../shared/components/Grid/GridContainer.jsx'
import GridItem from '../../../shared/components/Grid/GridItem.jsx'
import challengeStyle from '../../../shared/assets/jss/material-dashboard-pro-react/views/challengeStyle'
import Chip from '@material-ui/core/Chip/Chip'
import CustomInput from '../../../shared/components/CustomInput/CustomInput'
import { Button } from "@hlcr/mui/Button";
import { categoryImages } from "shared/models/category.model"
import { QuizModel } from "shared/models/quiz.model"

class CategoryAndTagsStep extends React.Component {
	constructor(props) {
		super(props)
		const quiz: QuizModel = this.props.currentQuiz
		this.state = {
			categories: quiz.categories || [],
			keywords: quiz.keywords || [],
			newKeyword: ''
		}
	}

	sendState() {
		return this.state
	}

	isValidated() {
		return true
	}

	handleTypeChange = event => {
		this.setState({ [event.target.name]: event.target.value })
	}
	handleChange = key => event => {
		const categories = this.state.categories
		event.target.checked
			? categories.push(key)
			: categories.splice(categories.indexOf(key), 1)
		this.setState({ categories: categories })
	}

	handleNewKeywordChange = event => {
		this.setState({ newKeyword: event.target.value })
	}

	handleKeyPress = event => {
		if (event.key === 'Enter') {
			this.handleAddKeywordClicked()
		}
	}

	handleDeleteKeyword = data => () => {
		this.setState(state => {
			const keywords = [...state.keywords]
			const keywordToDelete = keywords.indexOf(data)
			keywords.splice(keywordToDelete, 1)
			return { keywords }
		})
	}

	handleAddKeywordClicked = () => {
		this.setState(
			state => {
				const keywords = [...state.keywords]
				if (!keywords.includes(state.newKeyword))
					keywords.push(state.newKeyword)

				return { keywords }
			},
			() => {
				this.setState({ newKeyword: '' })
			}
		)
	}

	categoryCheckboxList = classes => {
		return Object.keys(categoryImages).map((category: string) => (
			<GridItem xs={12} sm={4} key={category}>
				<div className={classes.choice}>
					<Checkbox
						key={category}
						tabIndex={-1}
						onClick={this.handleChange(category)}
						checked={this.state.categories.includes(category)}
						checkedIcon={
							<img
								src={categoryImages[category]}
								alt={category.replace('_', ' ')}
								className={classes.iconCheckboxImageChecked}
							/>
						}
						icon={
							<img
								src={categoryImages[category]}
								alt={category.replace('_', ' ')}
								className={classes.iconCheckboxImage}
							/>
						}
						classes={{
							checked: classes.iconCheckboxChecked,
							root: classes.iconCheckbox
						}}
					/>
					<h6>{category.toUpperCase().replace('_', ' ')}</h6>
				</div>
			</GridItem>
		))
	}

	render() {
		const { classes } = this.props
		return (
			<div>
				<h4 className={classes.infoText}>Keywords</h4>
				<GridContainer justify="center">
					<GridItem xs={12} lg={8}>
						{this.state.keywords.map(data => {
							return (
								<Chip
									key={data}
									label={data}
									onDelete={this.handleDeleteKeyword(data)}
									className={classes.chip}
								/>
							)
						})}
					</GridItem>
				</GridContainer>
				<GridContainer justify="center">
					<GridItem xs={12} lg={4}>
						<CustomInput
							success={this.state.goldNuggetSecretState === 'success'}
							error={this.state.goldNuggetSecretState === 'error'}
							labelText={<span>New Keyword</span>}
							id="keyword"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								onChange: event => this.handleNewKeywordChange(event),
								onKeyPress: event => this.handleKeyPress(event),
								value: this.state.newKeyword
							}}
						/>
					</GridItem>
					<GridItem xs={12} lg={2}>
						<Button
							onClick={this.handleAddKeywordClicked}
							variant={'contained'}
						>
							Add Keyword
						</Button>
					</GridItem>
				</GridContainer>
				<h4 className={classes.infoText}>Quiz Categories</h4>
				<GridContainer justify="center">
					<GridItem xs={12} sm={12} md={12} lg={10}>
						<GridContainer>{this.categoryCheckboxList(classes)}</GridContainer>
					</GridItem>
				</GridContainer>
			</div>
		)
	}
}

export default withStyles(challengeStyle)(CategoryAndTagsStep)
