// @flow
import React from 'react'

import BuildIcon from '@material-ui/icons/Build'
import SchoolIcon from '@material-ui/icons/School'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'

export const projectNamespaceIcons = {
	challenge: <BuildIcon />,
	theory: <SchoolIcon />,
	quiz: <FormatListBulletedIcon />
}
