// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { grayColor } from '../../assets/jss/material-dashboard-pro-react'

const labelStyle = {
	root: {
		display: 'inline-block',
		borderRadius: 100,
		background: grayColor,
		padding: '4px 8px',
		lineHeight: '1em',
		color: '#fff'
	}
}

const Label = ({ value, color, classes }: Props) => (
	<div className={classes.root} style={color && { backgroundColor: color }}>
		{value}
	</div>
)

export default withStyles(labelStyle)(Label)
