import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import GridItem from '../../shared/components/Grid/GridItem.jsx'
import {
	deployTheory,
	fetchTheory,
	fetchTheoryPipeline,
	updateTheory,
	uploadMedia
} from "actions/theory.actions"
import challengeStyle from '../../shared/assets/jss/material-dashboard-pro-react/views/challengeStyle'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import { Redirect } from 'react-router-dom'

import { withIntl } from "@hlcr/ui/Intl"
import { formatFullDate } from "shared/helper/dateCalc"
import { TheoryModel } from "shared/models/theory.model"
import type { SectionModelDto } from '../../shared/models/section.model'
import { SectionModel } from "shared/models/section.model"
import NoData from '../../shared/components/NoData/NoData'
import GridContainer from '../../shared/components/Grid/GridContainer'

import Wizard from '../../components/Wizard/Wizard.jsx'
import GeneralStep from './TheorySteps/GeneralStep.jsx'
import CategoryAndTagsStep from './TheorySteps/CategoryAndTagsStep.jsx'
import TheoryStep3 from './TheorySteps/SectionsStep.jsx'
import ReviewAndSaveStep from './TheorySteps/ReviewAndSaveStep'
import { hasWriteAccess } from "shared/auth/authUtils"
import ShowGitlabStatus from '../../shared/helper/ShowGitlabStatus'

class TheoryDetail extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			alert: null,
			show: false,
			redirect: false,
			theory: props.currentTheory,
			theoryUuid: props.theoryUuid,
			deployStatus: '',
			pipelineId: 0
		}
	}

	componentDidMount() {
		const { fetchTheory, theoryUuid } = this.props
		if (theoryUuid) {
			const temporaryTheory: TheoryModel = this.restoreTheory(theoryUuid)
			if (temporaryTheory) {
				this.setState({
					alert: (
						<SweetAlert
							warning
							title="Local changes found"
							onConfirm={() => {
								this.loadLocalChanges(temporaryTheory)
							}}
							onCancel={() => {
								this.hideAlert()
								this.removeLocalBackup(theoryUuid)
								fetchTheory(theoryUuid)
							}}
							confirmBtnCssClass={
								this.props.classes.button + ' ' + this.props.classes.success
							}
							cancelBtnCssClass={
								this.props.classes.button + ' ' + this.props.classes.danger
							}
							confirmBtnText="Yes, restore!"
							cancelBtnText="No, discard local changes!"
							showCancel
						>
							Do you want to restore your local changes from{' '}
							{formatFullDate(temporaryTheory.modifyDate)}
						</SweetAlert>
					)
				})
			} else {
				fetchTheory(theoryUuid)
			}
		}
	}

	restoreTheory = (theoryUuid): TheoryModel => {
		const cacheData = localStorage.getItem('theoryData')
		if (cacheData) {
			const cachedTheory = JSON.parse(cacheData)[theoryUuid]
			return TheoryModel.fromDto(cachedTheory)
		}
	}

	backupTheory = (theory: TheoryModel) => {
		if (theory) {
			theory.modifyDate = new Date()
			let localCache = JSON.parse(localStorage.getItem('theoryData'))
			localCache = localCache ? localCache : {}
			localCache[theory.uuid] = theory.toDto()
			// Disables localStorage for the moment
			// localStorage.setItem('theoryData', JSON.stringify(localCache))
		}
	}

	removeLocalBackup = uuid => {
		let localCache = JSON.parse(localStorage.getItem('theoryData'))
		if (localCache && localCache[uuid]) {
			delete localCache[uuid]
			localStorage.setItem('theoryData', JSON.stringify(localCache))
		}
	}

	loadLocalChanges = (localTheory: TheoryModel) => {
		this.setState({ theory: localTheory, alert: null })
	}

	hideAlert = () => {
		this.setState({
			alert: null
		})
	}

	mergeWizardSteps = (allStepStates): TheoryModel => {
		const theory: TheoryModel =
			this.state.theory !== null ? this.state.theory : this.props.currentTheory

		if (theory && allStepStates) {
			// Change according to step states
			theory.modifyMessage = 'updated theory' // allStepStates['review'].modifyMessage
			theory.modifyDate = new Date()

			if (allStepStates['general']) {
				theory.name = allStepStates['general'].name
				theory.abstract = allStepStates['general'].abstract
				theory.isPublic = allStepStates['general'].isPublic
			}

			if (allStepStates['category-and-tags']) {
				theory.categories = allStepStates['category-and-tags'].categories
				theory.keywords = allStepStates['category-and-tags'].keywords
			}

			if (allStepStates['sections']) {
				const sections: SectionModelDto = allStepStates['sections'].sections
				theory.sections = SectionModel.fromDtoList(sections)
			}
		}

		return theory
	}

	handleOnBlur = allStepStates => {
		const theory: TheoryModel = this.mergeWizardSteps(allStepStates)
		this.backupTheory(theory)
	}

	save(allStepStates, callback) {
		if (hasWriteAccess(this.props.currentTheory)) {
			this.setState({
				alert: (
					<LoadingModal
						title="Saving your changes"
						onConfirm={() => this.hideAlert()}
					/>
				)
			})

			const theory: TheoryModel = this.mergeWizardSteps(allStepStates)

			this.props.updateTheory(
				this.props.theoryUuid,
				theory,
				allStepStates['general'].titleImageFile,
				() => {
					callback()
					this.removeLocalBackup(theory.uuid)
				},
				() => {
					this.setState({ alert: null })
				}
			)
		} else {
			callback()
		}
	}

	handleFinish = allStepStates => {
		this.save(allStepStates, () =>
			this.setState({ alert: null, redirect: true, theory: null })
		)
	}

	handleFinishDeploy = allStepStates => {
		this.save(allStepStates, () =>
			this.handleDeployTheory(this.props.theoryUuid)
		)
	}

	handleDeployTheory = projectUuid => {
		this.setState({
			alert: (
				<LoadingModal
					title={'Deploying Theory'}
					onConfirm={() => this.hideAlert()}
				/>
			)
		})
		this.props.deployTheory(projectUuid, result => {
			if (result && result.id) {
				this.setState({ pipelineId: result.id })
				this.setState({ deployStatus: result.status })
				this.interval = setInterval(
					() => this.handleFetchTheoryPipeline(result.id),
					5000
				)
			} else {
				this.setState({
					alert: (
						<DeployModal
							title={'Deploy Theory Error'}
							classes={this.props.classes}
							onConfirm={() => this.setState({ alert: null, redirect: false })}
							showConfirm={true}
							gitlabStatus={'failed'}
						/>
					)
				})
			}
		})
	}

	handleFetchTheoryPipeline = pipelineId => {
		this.props.fetchTheoryPipeline(pipelineId, result => {
			if (result && result.status) {
				this.setState({
					deployStatus: result.status,
					alert: (
						<DeployModal
							title={'Deploy Theory Status'}
							classes={this.props.classes}
							onConfirm={() =>
								this.setState({ alert: null, redirect: true, theory: null })
							}
							showConfirm={
								result.status.match(/^(success|failed|canceled|skipped)$/) !=
								null
							}
							gitlabStatus={result.status}
						/>
					)
				})
				if (result.status.match(/^(success|failed|canceled|skipped)$/)) {
					clearInterval(this.interval)
				}
			} else {
				this.setState({
					alert: (
						<DeployModal
							title={'Deploy Theory Error'}
							classes={this.props.classes}
							onConfirm={() => this.setState({ alert: null, redirect: false })}
							showConfirm={true}
							gitlabStatus={'failed'}
						/>
					)
				})
				clearInterval(this.interval)
			}
		})
	}

	render() {
		const { currentTheory, currentTheoryPending } = this.props
		const { theory, redirect } = this.state

		if (redirect) {
			return <Redirect to={`/theories/search?q=${this.props.theoryUuid}`} />
		}

		if (currentTheoryPending) {
			return (
				<LoadingModal
					title="Loading Theory"
					onConfirm={() => this.hideAlert()}
				/>
			)
		}

		if (!currentTheory && !theory)
			return (
				<div>
					{this.state.alert}
					<NoData />
				</div>
			)

		this.backupTheory(currentTheory)

		return (
			<div>
				{this.state.alert}
				<ProjectDetail
					{...this.props}
					currentTheory={currentTheory}
					mergeWizardSteps={allStepStates =>
						this.mergeWizardSteps(allStepStates)
					}
					handleFinish={this.handleFinish}
					handleFinishDeploy={this.handleFinishDeploy}
					handleOnBlur={this.handleOnBlur}
				/>
			</div>
		)
	}
}

const LoadingModal = ({ title, onConfirm }) => (
	<SweetAlert title={title} onConfirm={onConfirm} showConfirm={false}>
		<CircularProgress />
	</SweetAlert>
)

const DeployModal = ({
	title,
	classes,
	onConfirm,
	showConfirm,
	gitlabStatus
}) => (
	<SweetAlert
		title={title}
		onConfirm={onConfirm}
		confirmBtnText={'Close'}
		confirmBtnCssClass={classes.button + ' ' + classes.success}
		showConfirm={showConfirm}
	>
		<ShowGitlabStatus status={gitlabStatus} />
	</SweetAlert>
)

const ProjectDetail = ({ ...props }) => {
	return (
		<GridContainer justify="center">
			<GridItem xs={12} sm={10}>
				<Wizard
					color={'primary'}
					validate
					steps={
						hasWriteAccess(props.currentTheory)
							? [
									{
										stepName: 'General',
										stepComponent: GeneralStep,
										stepId: 'general',
										props: { ...props }
									},
									{
										stepName: 'Category & Tags',
										stepComponent: CategoryAndTagsStep,
										stepId: 'category-and-tags',
										props: { ...props }
									},
									{
										stepName: 'Sections',
										stepComponent: TheoryStep3,
										stepId: 'sections',
										props: { ...props }
									},
									{
										stepName: 'Review & Save',
										stepComponent: ReviewAndSaveStep,
										stepId: 'review',
										props: { ...props }
									}
							  ]
							: [
									{
										stepName: 'Review & Save',
										stepComponent: ReviewAndSaveStep,
										stepId: 'review',
										props: { ...props }
									}
							  ]
					}
					title="Theory Editor"
					subtitle={props.currentTheory.uuid}
					finishButtonClick={props.handleFinish}
					finishButtonDeployClick={props.handleFinishDeploy}
					handleOnBlur={props.handleOnBlur}
					finishButtonText={
						hasWriteAccess(props.currentTheory) ? 'Save' : 'Close'
					}
					finishButtonDeployText={
						hasWriteAccess(props.currentTheory)
							? 'Save & Deploy'
							: 'Close & Deploy'
					}
					initialStepId={props.wizardStep}
					history={props.history}
				/>
			</GridItem>
		</GridContainer>
	)
}

TheoryDetail.propTypes = {
	classes: PropTypes.object.isRequired,
	theoryUuid: PropTypes.string.isRequired,
	currentTheory: PropTypes.object,
	currentTheoryPending: PropTypes.bool.isRequired,
	wizardStep: PropTypes.string,
	fetchTheory: PropTypes.func.isRequired,
	uploadMedia: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
	const theoryUuid = ownProps.match.params.theoryUuid
	const wizardStep = ownProps.match.params.wizardStep

	const currentTheory = state.api.resources.currentTheory.data.find(
		c => c.uuid === theoryUuid
	)
	return {
		theoryUuid: theoryUuid,
		currentTheory: TheoryModel.fromDto(currentTheory),
		currentTheoryPending: state.api.resources.currentTheory.pending,
		wizardStep: wizardStep
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchTheory,
			deployTheory,
			fetchTheoryPipeline,
			updateTheory,
			uploadMedia
		},
		dispatch
	)

TheoryDetail = compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(challengeStyle),
	withIntl
)(TheoryDetail)

export default TheoryDetail
