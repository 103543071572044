// @flow

export type TheorySectionModelDto = {
	uuid: ?string,
	order: ?number,
	content: ?string
}

export class TheorySectionModel {
	uuid: string
	order: number
	content: string

	constructor(uuid: string, order: number, content: string) {
		this.uuid = uuid
		this.order = order
		this.content = content
	}

	static fromDto(dto: TheorySectionModelDto): TheorySectionModel {
		if (!dto) {
			return new TheorySectionModel()
		}
		return new TheorySectionModel(dto.uuid, dto.order, dto.content)
	}

	static fromDtoList(dtos: TheorySectionModelDto[]): TheorySectionModel[] {
		if (!dtos) {
			return []
		}
		return dtos.map(dto => TheorySectionModel.fromDto(dto))
	}

	toDto(index): TheorySectionModelDto {
		return {
			uuid: this.uuid,
			order: index + 1,
			content: this.content
		}
	}
}
