// @flow
import * as React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
// material-ui components
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import iconCardStyle from '../../assets/jss/material-dashboard-pro-react/components/iconCardStyle.jsx'

type IconColors = 'orange' | 'green' | 'red' | 'blue' | 'purple' | 'rose'

type Props = {
	classes: Object,
	icon: React.StatelessFunctionalComponent<*>,
	iconColor: IconColors,
	title?: React.Node,
	category?: React.Node,
	content?: React.Node,
	footer?: React.Node,
	plain?: boolean,
	customCardContentClass?: string
}

function IconCard({ ...props }: Props) {
	const {
		classes,
		title,
		content,
		iconColor,
		category,
		footer,
		plain,
		customCardContentClass
	} = props
	const cardClasses = cx(classes.card, {
		[classes.cardPlain]: plain
	})
	const cardContentClasses = cx(classes.cardContent, customCardContentClass)
	return (
		<Card className={cardClasses}>
			<CardHeader
				classes={{
					root: cx(classes.cardHeader, classes[iconColor + 'CardHeader']),
					avatar: classes.cardAvatar
				}}
				avatar={<props.icon className={classes.cardIcon} />}
			/>
			<CardContent className={cardContentClasses}>
				<h4 className={classes.cardTitle}>
					{title}
					{category !== undefined ? (
						<small className={classes.cardCategory}>{category}</small>
					) : null}
				</h4>
				{content}
			</CardContent>
			{footer !== undefined ? (
				<div className={classes.cardFooter}>{footer}</div>
			) : null}
		</Card>
	)
}

IconCard.defaultProps = {
	iconColor: 'purple'
}

IconCard.propTypes = {
	classes: PropTypes.object.isRequired,
	icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
	iconColor: PropTypes.oneOf([
		'orange',
		'green',
		'red',
		'blue',
		'purple',
		'rose'
	]),
	title: PropTypes.node,
	category: PropTypes.node,
	content: PropTypes.node,
	footer: PropTypes.node,
	plain: PropTypes.bool,
	customCardContentClass: PropTypes.string
}

export default withStyles(iconCardStyle)(IconCard)
