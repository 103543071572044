import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import { StyledMarkdown } from "@hlcr/ui";

import CustomInput from "shared/components/CustomInput/CustomInput";
import { primaryColor } from "shared/assets/jss/material-dashboard-pro-react.jsx";

const MarkdownArea = withStyles(
	{
		root: {
			padding: "0",
		},
		formControl: {
			margin: "0",
			paddingTop: "0",
		},
		input: {
			boxSizing: "border-box",
			borderRadius: "3px",
			overflow: "hidden",
			overflowWrap: "break-word",
			resize: "none",
			minHeight: "150px",
			padding: "0 12px",
			fontFamily: "\"Courier New\", Courier, monospace",
			"&:focus": {
				border: "1px solid primaryColor",
			},
			"&,&::placeholder": {
				fontSize: "14px",
				fontWeight: "400",
				lineHeight: "1.42857",
				opacity: "1",
			},
			"&::placeholder": {
				fontFamily: "\"Roboto Mono\", sans-serif",
				color: "#AAAAAA",
			},
		},
	},
)(CustomInput);

const EditorTabs = withStyles(
	{
		indicator: {
			background: primaryColor,
		},
	})(Tabs);

function TabContainer(props) {
	const { children, ...rest } = props;
	return (
		<Typography component="div" {...rest}>
			{children}
		</Typography>
	);
}

class MarkdownInput extends React.Component {
	state = {
		value: 0,
	};

	handleChange = (event, value) => {
		this.setState({ value });
	};

	render() {
		const {
			classes,
			content,
			challengeUuid,
			name,
			onChange,
			placeholder,
			headingOffset,
			actions,
			success,
			error,
		} = this.props;
		const { value } = this.state;
		return (
			<div className={classes.root}>
				<AppBar position="static" color="inherit" className={classes.appbar}>
					<Toolbar className={classes.toolbar}>
						<EditorTabs
							value={value}
							onChange={this.handleChange}
							className={classes.grow}
						>
							<Tab label="Edit" />
							<Tab label="Preview" />
						</EditorTabs>
						{actions && <div className={classes.actions}>{actions}</div>}
					</Toolbar>
				</AppBar>
				{value === 0 && (
					<TabContainer>
						<MarkdownArea
							success={success}
							error={error}
							formControlProps={{
								fullWidth: true,
								margin: "none",
							}}
							inputProps={{
								placeholder: `${placeholder} (Markdown)`,
								multiline: true,
								disableUnderline: false,
								name: `${name}`,
								onChange: event => onChange(event),
								onBlur: this.handleBlur,
								value: content,
							}}
						/>
					</TabContainer>
				)}
				{value === 1 && (
					<TabContainer className={classes.preview}>
						{content && content.length > 0 ? (
							<StyledMarkdown
								source={content}
								headingOffset={headingOffset}
							/>
						) : (
							<h4>No text to preview!</h4>
						)}
					</TabContainer>
				)}
			</div>
		);
	}
}

export default withStyles({
	                          root: {
		                          width: "100%",
		                          tabs: {
			                          indicator: {
				                          color: primaryColor,
			                          },
		                          },
	                          },
	                          appbar: {
		                          boxShadow: "none",
		                          borderBottom: "1px #eee solid",
	                          },
	                          toolbar: {
		                          boxShadow: "none",
		                          padding: 0,
		                          minHeight: "48px",
	                          },
	                          grow: {
		                          flexGrow: 1,
	                          },
	                          preview: {
		                          padding: "24px",
	                          },
	                          actions: {
		                          padding: "0 24px",
		                          position: "relative",
		                          marginLeft: 0,
	                          },
                          })(MarkdownInput);
