import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridContainer from '../../../shared/components/Grid/GridContainer.jsx'
import GridItem from '../../../shared/components/Grid/GridItem.jsx'
import challengeStyle from '../../../shared/assets/jss/material-dashboard-pro-react/views/challengeStyle'
import { Button } from "@hlcr/mui/Button";
import {SectionModel} from "shared/models/section.model"
import {StepModel} from "shared/models/step.model"
import {ChallengeModel} from "shared/models/challenge.model"
import MediaListForMarkdown from '../../../shared/components/Media/MediaListForMarkdown'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ErrorIcon from '@material-ui/icons/Error'
import MarkdownInput from '../../../components/MarkdownInput/MarkdownInput'
import StepsAccordion from '../../../components/Accordion/StepsAccordion'
import CustomIconButton from '../../../components/Button/CustomIconButton'
import CustomInput from "../../../shared/components/CustomInput/CustomInput";
import {InputAdornment} from "@material-ui/core";

class SectionsStep extends React.Component {
    constructor(props) {
        super(props)
        const challenge: ChallengeModel = this.props.currentChallenge
        this.state = {
            sections: challenge.sections.slice(0),
            sectionMediaFiles: [],
            activeSection: 0,
            activeSectionStep: new Array(challenge.sections.length).fill(0)
        }
    }

    sendState() {
        return this.state
    }

    handleChange = (event, sectionIndex, title = false) => {
        const sections = this.state.sections
        const name = event.target.name
        const value = event.target.value
        if (sectionIndex !== undefined) {
            if (title) {
                sections[sectionIndex].steps[name].title = value
            } else {
                sections[sectionIndex].steps[name].content = value
            }
        } else {
            if (title) {
                sections[name].title = value
            } else {
                sections[name].content = value
            }
        }
        this.setState({sections: sections})
    }

    changeSectionField = (field, value, sectionIndex) => {
        const sections = this.state.sections.slice(0)
        const section = sections[sectionIndex]
        sections[sectionIndex] = Object.assign({}, section, {[field]: value})
        this.setState({sections})
    }

    handleBlur = () => {
        this.setState({sections: this.state.sections.slice(0)})
    }

    isSectionValid(section) {
        // XXX: If section titles need to be mandatory
        // if (!(section && section.title && section.title.length > 0)) {
        //     return false
        // }
        const emptySection = !(section && section.content && section.content.length > 0)
        for (const step of section.steps) {
            if (emptySection && step && step.content && step.content.length > 0) {
                return false
            }
            if (!(step && step.title && step.title.length > 0)) {
                return false
            }
        }
        return true
    }

    isValidated() {
        const sections = this.state.sections.slice(0)
        if (sections && sections.length > 0) {
            for (const section of sections) {
                if (!this.isSectionValid(section)) {
                    return false
                }
            }
        }
        return true
    }

    handleDelete = (sectionIndex, stepIndex) => {
        const sections = this.state.sections.slice(0)
        const activeSectionStep = this.state.activeSectionStep
        if (stepIndex !== undefined) {
            const section = sections[sectionIndex]
            const steps = section.steps.slice(0)
            steps.splice(stepIndex, 1)
            if (steps.length === activeSectionStep[sectionIndex]) {
                activeSectionStep[sectionIndex] = activeSectionStep[sectionIndex] - 1
            }
            this.changeSectionField('steps', steps, sectionIndex)
        } else {
            sections.splice(sectionIndex, 1)
            this.setState({sections, activeSection: sectionIndex === sections.length ? sectionIndex - 1 : sectionIndex})
        }
    }

    moveUpInArray = (array, elementIndex) => {
        const element = array[elementIndex]
        array.splice(elementIndex, 1)
        array.splice(elementIndex - 1, 0, element)
    }

    moveDownInArray = (array, elementIndex) => {
        const element = array[elementIndex]
        array.splice(elementIndex, 1)
        array.splice(elementIndex + 1, 0, element)
    }

    handleMoveUp = (sectionIndex, stepIndex) => {
        const sections = this.state.sections.slice(0)
        let activeSection = this.state.activeSection
        const activeSectionStep = this.state.activeSectionStep
        if (stepIndex !== undefined && stepIndex > 0) {
            const steps = sections[sectionIndex].steps.slice(0)
            this.moveUpInArray(steps, stepIndex)
            activeSectionStep[sectionIndex] = activeSectionStep[sectionIndex] - 1
            this.changeSectionField('steps', steps, sectionIndex)
        } else if (sectionIndex > 0) {
            this.moveUpInArray(sections, sectionIndex)
            activeSection = activeSection - 1
            this.setState({sections, activeSection, activeSectionStep})
        }
    }

    handleMoveDown = (sectionIndex, stepIndex) => {
        const sections = this.state.sections.slice(0)
        let activeSection = this.state.activeSection
        const activeSectionStep = this.state.activeSectionStep
        if (stepIndex !== undefined && stepIndex < sections[sectionIndex].steps.length - 1) {
            const steps = sections[sectionIndex].steps.slice(0)
            this.moveDownInArray(steps, stepIndex)
            activeSectionStep[sectionIndex] = activeSectionStep[sectionIndex] + 1
            this.changeSectionField('steps', steps, sectionIndex)
        } else if (sectionIndex < sections.length - 1) {
            this.moveDownInArray(sections, sectionIndex)
            activeSection = activeSection + 1
            this.setState({sections, activeSection, activeSectionStep})
        }
    }

    uploadMedia = (file, callback) => {
        this.props.uploadMedia(
            file.byteData,
            file.name,
            this.props.currentChallenge.uuid,
            mediaFile => callback(mediaFile),
            `${file.name} uploaded`
        )
    }

    handleSectionStepAccordionChange = (sectionIndex: number, panel: number) => (event, expanded) => {
        let activeSectionStep = this.state.activeSectionStep.slice(0)
        activeSectionStep[sectionIndex] = expanded ? panel : -1
        this.setState({activeSectionStep: activeSectionStep})
    }

    handleSectionAccordionChange = (panel: number) => (event, expanded) => {
        this.setState({activeSection: expanded ? panel : -1})
    }

    handleAddNewSectionAtEnd = () => {
        const sections = this.state.sections.slice(0)
        const activeSectionStep = this.state.activeSectionStep.slice(0)
        sections.push(new SectionModel('', this.state.sections.length + 1, '', '', []))
        activeSectionStep.push(-1)
        this.setState({
            sections: sections,
            activeSection: sections.length - 1,
            activeSectionStep
        })
    }

    handleAddNewSectionAtPosition = (positionIndex: number) => {
        const sections = this.state.sections.slice(0)
        if (positionIndex < sections.length) {
            sections.splice(positionIndex + 1, 0, new SectionModel('', positionIndex + 1, '', '', []))
            sections.map((section, index) => (section.order = index + 1))

            const activeSection = positionIndex + 1
            const activeSectionStep = this.state.activeSectionStep.splice(positionIndex, 0, 0)
            this.setState({sections: sections, activeSection, activeSectionStep})
        }
    }

    handleAddNewStepAtEnd = (sectionIndex: number) => {
        const sections = this.state.sections.slice(0)
        const activeSectionStep = this.state.activeSectionStep
        sections[sectionIndex].steps.push(new StepModel('', sections[sectionIndex].steps.length + 1, 'instruction', '', ''))
        activeSectionStep[sectionIndex] = sections[sectionIndex].steps.length - 1
        this.setState({sections: sections, activeSectionStep})
    }

    handleAddNewStepAtPosition = (sectionIndex: number, positionIndex: number) => {
        const sections = this.state.sections.slice(0)
        const activeSectionStep = this.state.activeSectionStep
        if (sectionIndex < sections.length && positionIndex < sections[sectionIndex].steps.length) {
            sections[sectionIndex].steps.splice(positionIndex + 1, 0, new StepModel('', sections[sectionIndex].steps.length + 1, 'instruction', '', ''))
            sections[sectionIndex].steps.map((step, index) => (step.order = index + 1))
            activeSectionStep[sectionIndex] = positionIndex + 1
            this.setState({sections: sections, activeSectionStep})
        }
    }

    sectionsList = classes => {
        const {activeSection, sections} = this.state
        if (sections && sections.length > 0) {
            return (
                <StepsAccordion
                    overrideActiveSectionIndex={activeSection}
                    onChange={this.handleSectionAccordionChange}
                    sections={sections.map((section: SectionModel, index: number) => ({
                        title: (
                            !this.isSectionValid(section) ? (
                                <div className={classes.accordionTitleContainer}>
                                    <ErrorIcon className={classes.inlineErrorIcon} fontSize="small"/>
                                    <span className={classes.errorText}>{`Section ${index + 1} - Empty sections with steps are not permitted and step titles are mandatory`}</span>
                                </div>
                            ) : `Section ${index + 1}`
                        ),
                        // XXX: If you need titles for sections
                        // title: (
                        //     <div>
                        //         {!this.isSectionValid(section) && (
                        //             <div className={classes.accordionTitleContainer}>
                        //                 <ErrorIcon className={classes.inlineErrorIcon} fontSize="small"/>
                        //                 <span className={classes.errorText}>{`Empty sections with steps are not permitted and section and step titles are mandatory`}</span>
                        //             </div>
                        //         )}
                        //         <CustomInput
                        //             formControlProps={{
                        //                 fullWidth: true,
                        //                 className: classes.sectionAccordionTitleInput
                        //             }}
                        //             inputProps={{
                        //                 startAdornment: <InputAdornment position="start">{`Section ${index + 1}`}</InputAdornment>,
                        //                 name: index,
                        //                 onChange: event => this.handleChange(event, undefined, true),
                        //                 onClick: event => event.stopPropagation(),
                        //                 value: section.title,
                        //                 placeholder: "title"
                        //             }}
                        //             inheritWidth={true}
                        //         />
                        //     </div>
                        // ),
                        content: (
                            <div style={{width: '100%', overflow: 'hidden'}}>
                                <MarkdownInput
                                    placeholder={`Section ${section.uuid}`}
                                    name={index}
                                    content={section.content}
                                    onChange={event => this.handleChange(event)}
                                    headingOffset={2}
                                    plainTabs={true}
                                />
                                <div style={{padding: '0 24px', borderTop: '1px #eee solid'}}>
                                    {this.stepsList(section.steps, index, classes)}
                                </div>
                            </div>
                        ),
                        actions: (
                            <div className={classes.stepActions}>
                                <CustomIconButton disabled={index === 0} onClick={() => this.handleMoveUp(index)}>
                                    <ArrowUpwardIcon className={classes.buttonIcon} fontSize="small"/>
                                </CustomIconButton>
                                <CustomIconButton disabled={index + 1 === this.state.sections.length} onClick={() => this.handleMoveDown(index)}>
                                    <ArrowDownwardIcon className={classes.buttonIcon} fontSize="small"/>
                                </CustomIconButton>
                                <CustomIconButton onClick={() => this.handleDelete(index)}>
                                    <DeleteIcon className={classes.buttonIconDanger} fontSize="small"/>
                                </CustomIconButton>
                                <CustomIconButton onClick={() => this.handleAddNewSectionAtPosition(index)}>
                                    <AddIcon className={classes.buttonIconInfo} fontSize="small"/>
                                </CustomIconButton>
                            </div>
                        )
                    }))}
                />
            )
        }
    else
        {
            return (
                <div className={classes.actionsCenter}>
                    <Button variant="contained" color="primary" onClick={this.handleAddNewSectionAtEnd}>
                        Add new Section
                    </Button>
                </div>
            )
        }
    }

    stepsList = (steps: StepModel[], sectionIndex, classes) => {
    if (steps && steps.length > 0)
        {
            return (
                <div style={{marginTop: '20px'}}>
                    <StepsAccordion
                        initiallyActiveSectionIndex={this.state.activeSectionStep[sectionIndex]}
                        onHandleChange={panel => this.handleSectionStepAccordionChange(sectionIndex, panel)}
                        sections={steps.map((step: StepModel, index: number) => ({
                            title: (
                                <CustomInput
                                    formControlProps={{
                                        fullWidth: true,
                                        className: classes.sectionAccordionTitleInput
                                    }}
                                    inputProps={{
                                        startAdornment: <InputAdornment position="start">{`Step ${sectionIndex + 1}.${index + 1}`}</InputAdornment>,
                                        name: index,
                                        onChange: event => this.handleChange(event, sectionIndex, true),
                                        onClick: event => event.stopPropagation(),
                                        value: step.title,
                                        placeholder: "title",
                                        inheritWidth: true
                                    }}
                                    inheritWidth={true}
                                />
                            ),
                            content: (
                                <div style={{width: '100%', overflow: 'hidden'}}>
                                    <MarkdownInput
                                        placeholder={`Step ${step.uuid}`}
                                        name={index}
                                        content={step.content}
                                        onChange={event => this.handleChange(event, sectionIndex)}
                                        headingOffset={3}
                                        plainTabs={true}
                                    />
                                </div>
                            ),
                            actions: (
                                <div className={classes.stepActions}>
                                    <CustomIconButton disabled={index === 0} onClick={() => this.handleMoveUp(sectionIndex, index)}>
                                        <ArrowUpwardIcon className={classes.buttonIcon} fontSize="small"/>
                                    </CustomIconButton>
                                    <CustomIconButton disabled={index + 1 === steps.length} onClick={() => this.handleMoveDown(sectionIndex, index)}>
                                        <ArrowDownwardIcon className={classes.buttonIcon} fontSize="small"/>
                                    </CustomIconButton>
                                    <CustomIconButton onClick={() => this.handleDelete(sectionIndex, index)}>
                                        <DeleteIcon className={classes.buttonIconDanger} fontSize="small"/>
                                    </CustomIconButton>
                                    <CustomIconButton onClick={() => this.handleAddNewStepAtPosition(sectionIndex, index)}>
                                        <PlaylistAddIcon className={classes.buttonIconInfo} fontSize="small"/>
                                    </CustomIconButton>
                                </div>
                            )
                        }))}
                    />
                </div>
            )
        }
    else
        {
            return (
                <div className={classes.actionsCenter}>
                    <Button variant="contained" color="primary" onClick={() => this.handleAddNewStepAtEnd(sectionIndex)}>
                        Add new Step
                    </Button>
                </div>
            )
        }
    }

    render()
        {
            const {classes} = this.props
            return (
                <div>
                    <h4 className={classes.infoText}>Sections</h4>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={3}>
                            <MediaListForMarkdown
                                uploadedMediaFiles={this.state.sectionMediaFiles}
                                uploadMedia={this.uploadMedia}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={9}>
                            {this.sectionsList(classes)}
                        </GridItem>
                    </GridContainer>
                </div>
            )
        }
    }

    export default withStyles(challengeStyle)(SectionsStep)
