import type { Dto } from './index'

export type TheoryState = {
	challenges: {
		pending: boolean,
		data: Dto[]
	},
	currentChallenge: {
		pending: boolean,
		data: TheoryModelDto[]
	}
}

export const theoryInitState: TheoryState = {
	theories: {
		pending: false,
		data: []
	},
	currentTheory: {
		pending: false,
		data: []
	}
}
