// @flow
import type { OptionModelDto } from './option.model'
import { ChoiceOptionModel } from './option.model'

export const QuestionType = {
	MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
	SINGLE_CHOICE: 'SINGLE_CHOICE',
	SHORT_ANSWER: 'SHORT_ANSWER',
	LONG_ANSWER: 'LONG_ANSWER'
}

export type QuestionModelDto = {
	uuid: ?string,
	order: ?number,
	content: ?string,
	type: ?QuestionType,
	shortAnswers: ?(string[]),
	longAnswer: ?string,
	options: ?(OptionModelDto[])
}

export class QuestionModel {
	uuid: string
	order: number
	content: string
	type: QuestionType
	shortAnswers: string[]
	longAnswer: string
	options: ChoiceOptionModel[]

	constructor(
		uuid: string,
		order: number,
		content: string,
		type: string,
		shortAnswers: [],
		longAnswer: string,
		options: []
	) {
		this.uuid = uuid
		this.order = order
		this.content = content
		this.type = type
		this.shortAnswers = shortAnswers || []
		this.longAnswer = longAnswer
		this.options = options || []
	}

	static fromDto(dto: QuestionModelDto): QuestionModel {
		if (!dto) {
			return new QuestionModel()
		}
		const options =
			dto.type === QuestionType.MULTIPLE_CHOICE ||
			dto.type === QuestionType.SINGLE_CHOICE
				? ChoiceOptionModel.fromDtoList(dto.options)
				: []
		const shortAnswers =
			dto.type === QuestionType.SHORT_ANSWER ? dto.shortAnswers : []
		const longAnswer =
			dto.type === QuestionType.LONG_ANSWER ? dto.longAnswer : ''
		return new QuestionModel(
			dto.uuid,
			dto.order,
			dto.content,
			dto.type,
			shortAnswers,
			longAnswer,
			options
		)
	}

	static fromDtoList(dtos: QuestionModelDto[]): QuestionModel[] {
		if (!dtos) {
			return []
		}
		return dtos.map(dto => QuestionModel.fromDto(dto))
	}

	toDto(index): QuestionModelDto {
		return {
			uuid: this.uuid,
			order: index + 1,
			content: this.content,
			type: this.type,
			shortAnswers: this.shortAnswers,
			longAnswer: this.longAnswer,
			options: this.options.map((option, index) => option.toDto(index))
		}
	}
}
