import ChallengeList from '../views/Challenge/ChallengeList'
import ChallengeDetail from '../views/Challenge/ChallengeDetail'
import { HackingLabRole } from "@hlcr/app/model/HackingLabRole"
import BuildIcon from '@material-ui/icons/Build'
import SchoolIcon from '@material-ui/icons/School'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import ChallengeBreadCrumb from '../views/Challenge/ChallengeBreadCrumb'
import TheoryDetail from '../views/Theory/TheoryDetail'
import TheoryList from '../views/Theory/TheoryList'
import TheorySearchList from '../views/Theory/TheorySearchList'
import TheoryBreadCrumb from '../views/Theory/TheoryBreadCrumb'
import QuizList from '../views/Quiz/QuizList'
import QuizSearchList from '../views/Quiz/QuizSearchList'
import QuizDetail from '../views/Quiz/QuizDetail'
import QuizBreadCrumb from '../views/Quiz/QuizBreadCrumb'
import ChallengeSearchList from "views/Challenge/ChallengeSearchList";

const dashRoutes = [
	{
		path: '/challenges',
		name: 'Challenges',
		component: ChallengeList,
		needsRole: HackingLabRole.COMPOSITE_EDITOR,
		hasSidebarLink: true,
		icon: BuildIcon,
		breadcrumb: ChallengeBreadCrumb,
		help: { role: 'editor', action: 'pageChallenge' }
	},
	{
		path: '/challenges/search',
		name: 'Challenges',
		component: ChallengeSearchList,
		needsRole: HackingLabRole.COMPOSITE_EDITOR
	},
	{
		path: '/challenges/page/:pageNumber',
		name: 'Challenges',
		component: ChallengeList,
		needsRole: HackingLabRole.COMPOSITE_EDITOR
	},
	{
		path: '/challenges/:challengeUuid',
		name: 'Challenge',
		component: ChallengeDetail,
		needsRole: HackingLabRole.COMPOSITE_EDITOR
	},
	{
		path: '/challenges/:challengeUuid/:wizardStep',
		name: 'Challenge',
		component: ChallengeDetail,
		needsRole: HackingLabRole.COMPOSITE_EDITOR,
		breadcrumb: ChallengeBreadCrumb,
		help: { role: 'editor', action: 'pageChallenge' }
	},
	{
		path: '/theories',
		name: 'Theories',
		component: TheoryList,
		needsRole: HackingLabRole.COMPOSITE_EDITOR,
		hasSidebarLink: true,
		icon: SchoolIcon,
		breadcrumb: TheoryBreadCrumb,
		help: { role: 'editor', action: 'pageTheory' }
	},
	{
		path: '/theories/search',
		name: 'Theories',
		component: TheorySearchList,
		needsRole: HackingLabRole.COMPOSITE_EDITOR
	},
	{
		path: '/theories/page/:pageNumber',
		name: 'Theories',
		component: TheoryList,
		needsRole: HackingLabRole.COMPOSITE_EDITOR
	},
	{
		path: '/theories/:theoryUuid',
		name: 'Theories',
		component: TheoryDetail,
		needsRole: HackingLabRole.COMPOSITE_EDITOR
	},
	{
		path: '/theories/:theoryUuid/:wizardStep',
		name: 'Theories',
		component: TheoryDetail,
		needsRole: HackingLabRole.COMPOSITE_EDITOR,
		breadcrumb: TheoryBreadCrumb,
		help: { role: 'editor', action: 'pageTheory' }
	},
	{
		path: '/quizzes',
		name: 'Quizzes',
		icon: FormatListBulletedIcon,
		component: QuizList,
		hasSidebarLink: true,
		needsRole: HackingLabRole.COMPOSITE_EDITOR,
		breadcrumb: QuizBreadCrumb,
		help: { role: 'editor', action: 'pageQuiz' }
	},
	{
		path: '/quizzes/search',
		name: 'Quizzes',
		component: QuizSearchList,
		needsRole: HackingLabRole.COMPOSITE_EDITOR
	},
	{
		path: '/quizzes/page/:pageNumber',
		name: 'Quizzes',
		component: QuizList,
		needsRole: HackingLabRole.COMPOSITE_EDITOR
	},
	{
		path: '/quizzes/:quizUuid',
		component: QuizDetail,
		needsRole: HackingLabRole.COMPOSITE_EDITOR
	},
	{
		path: '/quizzes/:quizUuid/:wizardStep',
		component: QuizDetail,
		needsRole: HackingLabRole.COMPOSITE_EDITOR,
		breadcrumb: QuizBreadCrumb,
		help: { role: 'editor', action: 'pageQuiz' }
	},
	{
		redirect: true,
		path: '/',
		pathTo: '/challenges',
		name: ''
	}
]
export default dashRoutes
