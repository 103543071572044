import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// shared imports
import challengeStyle from "shared/assets/jss/material-dashboard-pro-react/views/challengeStyle";
import { StyledMarkdown } from "@hlcr/ui";
import NoData from "shared/components/NoData/NoData";
import ItemGrid from "shared/components/Grid/ItemGrid";
import GridContainer from "shared/components/Grid/GridContainer";
import IconCard from "shared/components/Cards/IconCard";
import { compose } from "redux";
import { withIntl } from "@hlcr/ui/Intl";
import BuildIcon from "@material-ui/icons/Build";
import InfoSection from "./Review/InfoSection";
import QuizQuestion from "shared/components/Quiz/QuizQuestion";

class ReviewAndSaveStep extends React.Component {
	sendState() {
		return this.state;
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.checked });
	};

	isValidated() {
		return true;
	}

	resolveTitleImageSource = (url, uploaded) => {
		if (uploaded && uploaded.data) {
			return `data:image/png;base64, ${uploaded.data}`;
		}
		return url;
	};

	render() {
		const { getStepStates, mergeWizardSteps, intl, classes } = this.props;
		const stepStates = getStepStates();
		const titleImage =
			stepStates &&
			stepStates["general"] &&
			stepStates["general"].titleImageFile;
		const quiz = mergeWizardSteps(stepStates);
		return quiz ? (
			<div>
				<GridContainer>
					<ItemGrid xs={12} sm={12} md={12} lg={6}>
						<IconCard
							icon={BuildIcon}
							title={intl.fm("quiz.name")}
							iconColor="purple"
							content={
								<div>
									{titleImage !== null && (
										<div className={classes.imageContainer}>
											<img
												className={classes.image}
												src={this.resolveTitleImageSource(
													`/api${quiz.titleImageUrl}`,
													titleImage,
												)}
												alt={quiz.title}
											/>
										</div>
									)}
									{quiz.abstract ? (
										<StyledMarkdown source={quiz.abstract} headingOffset={2} />
									) : (
										<NoData text={intl.fm("quiz.nodata.content")} />
									)}
								</div>
							}
						/>
					</ItemGrid>
					<ItemGrid xs={12} sm={12} md={6} lg={6}>
						<InfoSection quiz={quiz} unitType={intl.fm("quiz.name")} />
					</ItemGrid>
					<ItemGrid xs={12} sm={12} md={12} lg={12}>
						<h2>Questions</h2>
						<Panel quiz={quiz} classes={classes} />
					</ItemGrid>
				</GridContainer>
			</div>
		) : (
			<NoData />
		);
	}
}

const Panel = ({ quiz, classes }) => {
	return quiz.questions.map((question, index) => (
		<QuizQuestion
			key={index}
			question={question}
			customCardClasses={classes.questionsMargin}
			gradingInstruction={question}
			quizClosed
		/>
	));
};

export default compose(
	withStyles(challengeStyle),
	withIntl,
)(ReviewAndSaveStep);
