import eventUnitsStyle from './eventUnitsStyle'

const paginatedProjectStyles = {
	...eventUnitsStyle,
	uuid: {
		fontSize: '11px',
		color: 'grey'
	},
	title: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'space-between'
	},
	titleButton: {
		margin: 0,
		padding: '5px 0 5px 5px'
	}
}

export default paginatedProjectStyles
