import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import NoData from '../../shared/components/NoData/NoData'
import GridContainer from '../../shared/components/Grid/GridContainer'
import {
	deployQuiz,
	fetchQuiz,
	fetchQuizPipeline,
	updateQuiz,
	uploadMedia
} from "actions/quiz.actions"
import challengeStyle from '../../shared/assets/jss/material-dashboard-pro-react/views/challengeStyle'
import { withStyles } from '@material-ui/core/styles'
import { withIntl } from "@hlcr/ui/Intl"
// core components
import Wizard from '../../components/Wizard/Wizard.jsx'
import GridItem from '../../shared/components/Grid/GridItem.jsx'

import GeneralStep from './QuizSteps/GeneralStep.jsx'
import CategoryAndTagsStep from './QuizSteps/CategoryAndTagsStep.jsx'
import QuestionsStep from './QuizSteps/QuestionsStep.jsx'
import ReviewAndSaveStep from './QuizSteps/ReviewAndSaveStep'
import { QuizModel } from "shared/models/quiz.model"
import type { QuestionModelDto } from '../../shared/models/question.model'
import { QuestionModel } from "shared/models/question.model"
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import { formatFullDate } from "shared/helper/dateCalc"
import { QuizLevelModel } from "shared/models/quizLevel.model"
import { Redirect } from 'react-router-dom'
import { hasWriteAccess } from "shared/auth/authUtils"
import ShowGitlabStatus from '../../shared/helper/ShowGitlabStatus'

const QUIZ_STORAGE_NAME = 'quizData'

class QuizDetail extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			alert: null,
			show: false,
			redirect: false,
			quiz: props.currentQuiz,
			quizUuid: props.quizUuid,
			deployStatus: '',
			pipelineId: 0
		}
	}

	componentDidMount() {
		const { fetchQuiz, quizUuid } = this.props
		if (quizUuid) {
			const temporaryQuiz: QuizModel = this.restoreQuiz(quizUuid)
			if (temporaryQuiz) {
				this.setState({
					alert: (
						<SweetAlert
							warning
							title="Local changes found"
							onConfirm={() => {
								this.loadLocalChanges(temporaryQuiz)
							}}
							onCancel={() => {
								this.hideAlert()
								this.removeLocalBackup(quizUuid)
								fetchQuiz(quizUuid)
							}}
							confirmBtnCssClass={
								this.props.classes.button + ' ' + this.props.classes.success
							}
							cancelBtnCssClass={
								this.props.classes.button + ' ' + this.props.classes.danger
							}
							confirmBtnText="Yes, restore!"
							cancelBtnText="No, discard local changes!"
							showCancel
						>
							Do you want to restore your local changes from{' '}
							{formatFullDate(temporaryQuiz.modifyDate)}
						</SweetAlert>
					)
				})
			} else {
				fetchQuiz(quizUuid)
			}
		}
	}

	restoreQuiz = (quizUuid): QuizModel => {
		const cacheData = localStorage.getItem(QUIZ_STORAGE_NAME)
		if (cacheData) {
			const cachedQuiz = JSON.parse(cacheData)[quizUuid]
			return QuizModel.fromDto(cachedQuiz)
		}
	}

	backupQuiz = (quiz: QuizModel) => {
		if (quiz) {
			quiz.modifyDate = new Date()
			let localCache = JSON.parse(localStorage.getItem(QUIZ_STORAGE_NAME))
			localCache = localCache ? localCache : {}
			localCache[quiz.uuid] = quiz.toDto()
			// Disables localStorage for the moment
			// localStorage.setItem(QUIZ_STORAGE_NAME, JSON.stringify(localCache))
		}
	}

	removeLocalBackup = uuid => {
		let localCache = JSON.parse(localStorage.getItem(QUIZ_STORAGE_NAME))
		if (localCache && localCache[uuid]) {
			delete localCache[uuid]
			localStorage.setItem(QUIZ_STORAGE_NAME, JSON.stringify(localCache))
		}
	}

	loadLocalChanges = (localQuiz: QuizModel) => {
		this.setState({ quiz: localQuiz, alert: null })
	}

	hideAlert = () => {
		this.setState({
			alert: null
		})
	}

	mergeWizardSteps = (allStepStates): QuizModel => {
		const quiz: QuizModel =
			this.state.quiz !== null ? this.state.quiz : this.props.currentQuiz

		if (quiz && allStepStates) {
			// Change according to step states
			quiz.modifyMessage = 'updated quiz' // allStepStates['review'].modifyMessage
			quiz.modifyDate = new Date()

			if (allStepStates['general']) {
				quiz.name = allStepStates['general'].name
				quiz.abstract = allStepStates['general'].abstract
				quiz.isPublic = allStepStates['general'].isPublic
				quiz.level = QuizLevelModel.fromDto(allStepStates['general'].level)
				quiz.type = allStepStates['general'].type
			}

			if (allStepStates['category-and-tags']) {
				quiz.categories = allStepStates['category-and-tags'].categories
				quiz.keywords = allStepStates['category-and-tags'].keywords
			}

			if (allStepStates['goldnugget']) {
				quiz.goldNugget.type = allStepStates['goldnugget'].goldNuggetType
				quiz.goldNugget.secret = allStepStates['goldnugget'].goldNuggetSecret
				quiz.grading = allStepStates['goldnugget'].grading
			}

			if (allStepStates['questions']) {
				const questions: QuestionModelDto = allStepStates['questions'].questions
				quiz.questions = QuestionModel.fromDtoList(questions)
			}
		}

		return quiz
	}

	handleOnBlur = allStepStates => {
		const quiz: QuizModel = this.mergeWizardSteps(allStepStates)
		this.backupQuiz(quiz)
	}

	save(allStepStates, callback) {
		if (hasWriteAccess(this.props.currentQuiz)) {
			this.setState({
				alert: (
					<LoadingModal
						title="Saving your changes"
						onConfirm={() => this.hideAlert()}
					/>
				)
			})

			const quiz: QuizModel = this.mergeWizardSteps(allStepStates)

			this.props.updateQuiz(
				this.props.quizUuid,
				quiz,
				allStepStates['general'].titleImageFile,
				() => {
					callback()
					this.removeLocalBackup(quiz.uuid)
				},
				() => {
					this.setState({ alert: null })
				}
			)
		} else {
			callback()
		}
	}

	handleFinish = allStepStates => {
		this.save(allStepStates, () =>
			this.setState({ alert: null, redirect: true, quiz: null })
		)
	}

	handleFinishDeploy = allStepStates => {
		this.save(allStepStates, () => this.handleDeployQuiz(this.props.quizUuid))
	}

	handleDeployQuiz = projectUuid => {
		this.setState({
			alert: (
				<LoadingModal
					title={'Deploying Quiz'}
					onConfirm={() => this.hideAlert()}
				/>
			)
		})
		this.props.deployQuiz(projectUuid, result => {
			if (result && result.id) {
				this.setState({ pipelineId: result.id })
				this.setState({ deployStatus: result.status })
				this.interval = setInterval(
					() => this.handleFetchQuizPipeline(result.id),
					5000
				)
			} else {
				this.setState({
					alert: (
						<DeployModal
							title={'Deploy Quiz Error'}
							classes={this.props.classes}
							onConfirm={() => this.setState({ alert: null, redirect: false })}
							showConfirm={true}
							gitlabStatus={'failed'}
						/>
					)
				})
			}
		})
	}

	handleFetchQuizPipeline = pipelineId => {
		this.props.fetchQuizPipeline(pipelineId, result => {
			if (result && result.status) {
				this.setState({
					deployStatus: result.status,
					alert: (
						<DeployModal
							title={'Deploy Quiz Status'}
							classes={this.props.classes}
							onConfirm={() =>
								this.setState({ alert: null, redirect: true, quiz: null })
							}
							showConfirm={
								result.status.match(/^(success|failed|canceled|skipped)$/) !=
								null
							}
							gitlabStatus={result.status}
						/>
					)
				})
				if (result.status.match(/^(success|failed|canceled|skipped)$/)) {
					clearInterval(this.interval)
				}
			} else {
				this.setState({
					alert: (
						<DeployModal
							title={'Deploy Quiz Error'}
							classes={this.props.classes}
							onConfirm={() => this.setState({ alert: null, redirect: false })}
							showConfirm={true}
							gitlabStatus={'failed'}
						/>
					)
				})
				clearInterval(this.interval)
			}
		})
	}

	render() {
		const { currentQuiz, currentQuizPending } = this.props
		const { quiz, redirect } = this.state

		if (redirect) {
			return <Redirect to={`/quizzes/search?q=${this.props.quizUuid}`} />
		}

		if (currentQuizPending) {
			return (
				<LoadingModal title="Loading Quiz" onConfirm={() => this.hideAlert()} />
			)
		}

		if (!currentQuiz && !quiz)
			return (
				<div>
					{this.state.alert}
					<NoData />
				</div>
			)

		this.backupQuiz(currentQuiz)

		return (
			<div>
				{this.state.alert}
				<ProjectDetail
					{...this.props}
					currentQuiz={currentQuiz}
					mergeWizardSteps={allStepStates =>
						this.mergeWizardSteps(allStepStates)
					}
					handleFinish={this.handleFinish}
					handleFinishDeploy={this.handleFinishDeploy}
					handleOnBlur={this.handleOnBlur}
				/>
			</div>
		)
	}
}

const LoadingModal = ({ title, onConfirm }) => (
	<SweetAlert title={title} onConfirm={onConfirm} showConfirm={false}>
		<CircularProgress />
	</SweetAlert>
)

const DeployModal = ({
	title,
	classes,
	onConfirm,
	showConfirm,
	gitlabStatus
}) => (
	<SweetAlert
		title={title}
		onConfirm={onConfirm}
		confirmBtnText={'Close'}
		confirmBtnCssClass={classes.button + ' ' + classes.success}
		showConfirm={showConfirm}
	>
		<ShowGitlabStatus status={gitlabStatus} />
	</SweetAlert>
)

const ProjectDetail = ({ ...props }) => {
	return (
		<GridContainer justify="center">
			<GridItem xs={12} sm={10}>
				<Wizard
					color={'primary'}
					validate
					steps={
						hasWriteAccess(props.currentQuiz)
							? [
									{
										stepName: 'General',
										stepComponent: GeneralStep,
										stepId: 'general',
										props: { ...props }
									},
									{
										stepName: 'Category & Tags',
										stepComponent: CategoryAndTagsStep,
										stepId: 'category-and-tags',
										props: { ...props }
									},
									{
										stepName: 'Questions',
										stepComponent: QuestionsStep,
										stepId: 'questions',
										props: { ...props }
									},
									{
										stepName: 'Review & Save',
										stepComponent: ReviewAndSaveStep,
										stepId: 'review',
										props: { ...props }
									}
							  ]
							: [
									{
										stepName: 'Review & Save',
										stepComponent: ReviewAndSaveStep,
										stepId: 'review',
										props: { ...props }
									}
							  ]
					}
					title="Quiz Editor"
					subtitle={props.currentQuiz.uuid}
					finishButtonClick={props.handleFinish}
					finishButtonDeployClick={props.handleFinishDeploy}
					handleOnBlur={props.handleOnBlur}
					finishButtonText={
						hasWriteAccess(props.currentQuiz) ? 'Save' : 'Close'
					}
					finishButtonDeployText={
						hasWriteAccess(props.currentQuiz)
							? 'Save & Deploy'
							: 'Close & Deploy'
					}
					initialStepId={props.wizardStep}
					history={props.history}
				/>
			</GridItem>
		</GridContainer>
	)
}

QuizDetail.propTypes = {
	classes: PropTypes.object.isRequired,
	quizUuid: PropTypes.string.isRequired,
	currentQuiz: PropTypes.object,
	currentQuizPending: PropTypes.bool.isRequired,
	wizardStep: PropTypes.string,
	fetchQuiz: PropTypes.func.isRequired,
	updateQuiz: PropTypes.func.isRequired,
	uploadMedia: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
	const quizUuid = ownProps.match.params.quizUuid
	const wizardStep = ownProps.match.params.wizardStep

	const currentQuiz = state.api.resources.currentQuiz.data.find(
		c => c.uuid === quizUuid
	)
	return {
		quizUuid: quizUuid,
		currentQuiz: QuizModel.fromDto(currentQuiz),
		currentQuizPending: state.api.resources.currentQuiz.pending,
		wizardStep: wizardStep
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{ fetchQuiz, deployQuiz, fetchQuizPipeline, updateQuiz, uploadMedia },
		dispatch
	)

QuizDetail = compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	),
	withStyles(challengeStyle),
	withIntl
)(QuizDetail)

export default QuizDetail
