import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import Card from '@material-ui/core/Card'
import NavigationButtons from './NavigationButtons'

import wizardStyle from '../../shared/assets/jss/material-dashboard-pro-react/components/wizardStyle.jsx'

class Wizard extends React.Component {
	constructor(props) {
		super(props)
		let width
		if (this.props.steps.length === 1) {
			width = '100%'
		} else {
			if (window.innerWidth < 600) {
				if (this.props.steps.length !== 3) {
					width = '50%'
				} else {
					width = 100 / 3 + '%'
				}
			} else {
				if (this.props.steps.length === 2) {
					width = '50%'
				} else {
					width = 100 / 3 + '%'
				}
			}
		}
		let initialStep = Math.max(
			this.props.steps.findIndex(
				step => step.stepId === this.props.initialStepId
			),
			0
		)

		this.refreshRouter(initialStep)

		this.state = {
			currentStep: initialStep,
			color: this.props.color,
			nextButton:
				this.props.steps.length > 1 &&
				initialStep !== this.props.steps.length - 1,
			previousButton: initialStep !== 0,
			finishButton: this.props.steps.length === initialStep + 1,
			width: width,
			movingTabStyle: {
				transition: 'transform 0s'
			},
			allStates: [],
			//backupInterval: setInterval(this.handleOnBlur, 1000)
		}
		this.navigationStepChange = this.navigationStepChange.bind(this)
		this.refreshAnimation = this.refreshAnimation.bind(this)
		this.refreshRouter = this.refreshRouter.bind(this)
		this.previousButtonClick = this.previousButtonClick.bind(this)
		this.previousButtonClick = this.previousButtonClick.bind(this)
		this.finishButtonClick = this.finishButtonClick.bind(this)
		this.finishButtonDeployClick = this.finishButtonDeployClick.bind(this)
		this.updateWidth = this.updateWidth.bind(this)
	}

	componentDidMount() {
		this.refreshAnimation(this.state.currentStep)
		window.addEventListener('resize', this.updateWidth)
	}

	componentWillUnmount() {
		//clearInterval(this.state.backupInterval);
		window.removeEventListener('resize', this.updateWidth)
	}

	updateWidth() {
		this.refreshAnimation(this.state.currentStep)
	}

	navigationStepChange(key) {
		if (this.props.steps) {
			var validationState = true
			if (key > this.state.currentStep) {
				for (var i = this.state.currentStep; i < key; i++) {
					if (this[this.props.steps[i].stepId].sendState !== undefined) {
						this.setState(
							{
								allStates: [
									...this.state.allStates,
									{
										[this.props.steps[i].stepId]: this[
											this.props.steps[i].stepId
										].sendState()
									}
								]
							},
							() => this.handleOnBlur()
						)
					}
					if (
						this[this.props.steps[i].stepId].isValidated !== undefined &&
						this[this.props.steps[i].stepId].isValidated() === false
					) {
						validationState = false
						break
					}
				}
			} else {
				this.handleOnBlur()
			}
			if (validationState) {
				this.setState({
					currentStep: key,
					nextButton: this.props.steps.length > key + 1 ? true : false,
					previousButton: key > 0 ? true : false,
					finishButton: this.props.steps.length === key + 1 ? true : false
				})
				this.refreshRouter(key)
				this.refreshAnimation(key)
			}
		}
	}

	nextButtonClick() {
		if (
			(this.props.validate &&
				((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
					undefined &&
					this[
						this.props.steps[this.state.currentStep].stepId
					].isValidated()) ||
					this[this.props.steps[this.state.currentStep].stepId].isValidated ===
						undefined)) ||
			this.props.validate === undefined
		) {
			if (
				this[this.props.steps[this.state.currentStep].stepId].sendState !==
				undefined
			) {
				this.setState(
					{
						allStates: [
							...this.state.allStates,
							{
								[this.props.steps[this.state.currentStep].stepId]: this[
									this.props.steps[this.state.currentStep].stepId
								].sendState()
							}
						]
					},
					() => this.handleOnBlur()
				)
			}
			var key = this.state.currentStep + 1
			this.setState({
				currentStep: key,
				nextButton: this.props.steps.length > key + 1 ? true : false,
				previousButton: key > 0 ? true : false,
				finishButton: this.props.steps.length === key + 1 ? true : false
			})
			this.refreshRouter(key)
			this.refreshAnimation(key)
		}
	}

	previousButtonClick() {
		if (
			this[this.props.steps[this.state.currentStep].stepId].sendState !==
			undefined
		) {
			this.setState(
				{
					allStates: [
						...this.state.allStates,
						{
							[this.props.steps[this.state.currentStep].stepId]: this[
								this.props.steps[this.state.currentStep].stepId
							].sendState()
						}
					]
				},
				() => this.handleOnBlur()
			)
		}
		var key = this.state.currentStep - 1
		if (key >= 0) {
			this.setState({
				currentStep: key,
				nextButton: this.props.steps.length > key + 1 ? true : false,
				previousButton: key > 0 ? true : false,
				finishButton: this.props.steps.length === key + 1 ? true : false
			})
			this.refreshRouter(key)
			this.refreshAnimation(key)
		}
	}

	getStepStates = () => {
		let stepStates = []
		this.props.steps.forEach(step => {
			let stepComponent = this[step.stepId]
			if (stepComponent && stepComponent.sendState !== undefined) {
				stepStates[step.stepId] = this[step.stepId].sendState()
			}
		})
		return stepStates
	}

	handleOnBlur = () => {
		this.props.handleOnBlur(this.getStepStates())
	}

	validateAndFinish(callback) {
		var stepStates = []
		this.props.steps.forEach(step => {
			if (this[step.stepId]?.sendState !== undefined) {
				stepStates[step.stepId] = this[step.stepId].sendState()
			}
		})
		this.setState(
			{
				allStates: stepStates
			},
			() => {
				if (
					this.props.validate &&
					(this[this.props.steps[this.state.currentStep].stepId]?.isValidated ===
						undefined ||
						this[
							this.props.steps[this.state.currentStep].stepId
						].isValidated()) &&
					callback !== undefined
				) {
					callback(this.state.allStates)
				}
			}
		)
	}

	finishButtonClick() {
		this.validateAndFinish(this.props.finishButtonClick)
	}

	finishButtonDeployClick() {
		this.validateAndFinish(this.props.finishButtonDeployClick)
	}

	refreshRouter(index) {
		const segments = this.props.history.location.pathname.split('/')
		if (segments[3] !== this.props.steps[index].stepId) {
			segments[3] = this.props.steps[index].stepId
			segments[4] = ''
			this.props.history.push(segments.join('/'))
		}
	}

	refreshAnimation(index) {
		var total = this.props.steps.length
		var li_width = 100 / total
		var total_steps = this.props.steps.length
		var move_distance = this.refs.wizard.children[0].offsetWidth / total_steps
		var index_temp = index
		var vertical_level = 0

		var mobile_device = window.innerWidth < 600 && total > 3

		if (mobile_device) {
			move_distance = this.refs.wizard.children[0].offsetWidth / 2
			index_temp = index % 2
			li_width = 50
		}

		this.setState({ width: li_width + '%' })

		var step_width = move_distance
		move_distance = move_distance * index_temp

		var current = index + 1

		if (current === 1 || (mobile_device === true && index % 2 === 0)) {
			move_distance -= 8
		} else if (
			current === total_steps ||
			(mobile_device === true && index % 2 === 1)
		) {
			move_distance += 8
		}

		if (mobile_device) {
			vertical_level = parseInt(index / 2, 10)
			vertical_level = vertical_level * 38
		}
		var movingTabStyle = {
			width: step_width,
			transform:
				'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
			transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'
		}
		this.setState({ movingTabStyle: movingTabStyle })
	}

	render() {
		const { classes, title, subtitle, color, steps } = this.props
		return (
			<div className={classes.wizardContainer} ref="wizard">
				<Card className={classes.card}>
					<div className={classes.wizardHeader}>
						<h3 className={classes.title}>{title}</h3>
						<h5 className={classes.subtitle}>{subtitle}</h5>
					</div>
					<div className={classes.wizardNavigation}>
						<ul className={classes.nav}>
							{steps.map((prop, key) => {
								return (
									<li
										className={classes.steps}
										key={key}
										style={{ width: this.state.width }}
									>
										<a
											className={classes.stepsAnchor}
											onClick={() => this.navigationStepChange(key)}
										>
											{prop.stepName}
										</a>
									</li>
								)
							})}
						</ul>
						<div
							className={classes.movingTab + ' ' + classes[color]}
							style={this.state.movingTabStyle}
						>
							{steps[this.state.currentStep].stepName}
						</div>
					</div>
					<div className={this.state.finishButton ? null : classes.hidden}>
						<NavigationButtons
							classes={classes}
							previousButton={this.state.previousButton}
							previousButtonClasses={this.props.previousButtonClasses}
							previousButtonClick={() => this.previousButtonClick()}
							previousButtonText={this.props.previousButtonText}
							nextButton={this.state.nextButton}
							nextButtonClasses={this.props.nextButtonClasses}
							nextButtonClick={() => this.nextButtonClick()}
							nextButtonText={this.props.nextButtonText}
							finishButton={this.state.finishButton}
							finishButtonClasses={this.finishButtonClasses}
							finishButtonClick={() => this.finishButtonClick()}
							finishButtonText={this.props.finishButtonText}
							finishButtonDeployText={this.props.finishButtonDeployText}
							finishButtonDeployClick={() => this.finishButtonDeployClick()}
						/>
					</div>
					<div className={classes.content}>
						{steps.map((prop, key) => {
							const stepContentClasses = cx({
								[classes.stepContentActive]: this.state.currentStep === key,
								[classes.stepContent]: this.state.currentStep !== key
							})
							return (
								<div className={stepContentClasses} key={key}>
									<prop.stepComponent
										{...prop.props}
										handleOnBlur={this.handleOnBlur}
										innerRef={node => (this[prop.stepId] = node)}
										allStates={this.state.allStates}
										getStepStates={this.getStepStates}
									/>
								</div>
							)
						})}
					</div>
					<NavigationButtons
						classes={classes}
						previousButton={this.state.previousButton}
						previousButtonClasses={this.props.previousButtonClasses}
						previousButtonClick={() => this.previousButtonClick()}
						previousButtonText={this.props.previousButtonText}
						nextButton={this.state.nextButton}
						nextButtonClasses={this.props.nextButtonClasses}
						nextButtonClick={() => this.nextButtonClick()}
						nextButtonText={this.props.nextButtonText}
						finishButton={this.state.finishButton}
						finishButtonClasses={this.finishButtonClasses}
						finishButtonClick={() => this.finishButtonClick()}
						finishButtonText={this.props.finishButtonText}
						finishButtonDeployText={this.props.finishButtonDeployText}
						finishButtonDeployClick={() => this.finishButtonDeployClick()}
					/>
				</Card>
			</div>
		)
	}
}

Wizard.defaultProps = {
	color: 'rose',
	title: 'Here should go your title',
	subtitle: 'And this would be your subtitle',
	previousButtonText: 'Previous',
	previousButtonClasses: '',
	nextButtonClasses: '',
	nextButtonText: 'Next',
	finishButtonClasses: '',
	finishButtonText: 'Finish',
	finishButtonDeployText: 'Finish & Deploy'
}

Wizard.propTypes = {
	classes: PropTypes.object.isRequired,
	steps: PropTypes.arrayOf(
		PropTypes.shape({
			stepName: PropTypes.string.isRequired,
			stepComponent: PropTypes.func.isRequired,
			stepId: PropTypes.string.isRequired
		})
	).isRequired,
	color: PropTypes.oneOf([
		'primary',
		'warning',
		'danger',
		'success',
		'info',
		'rose'
	]),
	title: PropTypes.string,
	subtitle: PropTypes.string,
	previousButtonClasses: PropTypes.string,
	previousButtonText: PropTypes.string,
	nextButtonClasses: PropTypes.string,
	nextButtonText: PropTypes.string,
	finishButtonClasses: PropTypes.string,
	finishButtonText: PropTypes.string,
	finishButtonDeployText: PropTypes.string,
	finishButtonClick: PropTypes.func,
	finishButtonDeployClick: PropTypes.func,
	validate: PropTypes.bool,
	initialStepId: PropTypes.string
}

export default withStyles(wizardStyle)(Wizard)
