// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import levelLabelStyle from '../../assets/jss/material-dashboard-pro-react/components/levelLabelStyle'
import { withIntl } from "@hlcr/ui/Intl"
import { ChallengeLevelModel } from '../../models/challengeLevel.model'

type Props = {
	level: ChallengeLevelModel,
	classes: Object
}

const LevelLabel = ({ level, classes, intl }: Props) => (
	<div className={classes.root} style={{ backgroundColor: level.color }}>
		{intl.fm(`challenge.level.${level.name || 'unknown'}`)}
	</div>
)

export default withIntl(withStyles(levelLabelStyle)(LevelLabel))
