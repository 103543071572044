import connect from 'react-redux/es/connect/connect'
import React from 'react'
import type { AppState } from '../../reducers'

let ChallengeBreadCrumb = ({ challenge }) => (
	<span>{challenge ? challenge.name || 'New Challenge' : 'Challenges'}</span>
)

const mapStateToPropsChallenge = (state: AppState, ownProps) => {
	const challengeUuid = ownProps.match.params.challengeUuid
	return {
		challenge: state.api.resources.currentChallenge.data.find(
			c => c.uuid === challengeUuid
		)
	}
}

ChallengeBreadCrumb = connect(mapStateToPropsChallenge)(ChallengeBreadCrumb)
export default ChallengeBreadCrumb
