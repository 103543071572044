// @flow
import React from 'react'
// @material-ui/icons
import Check from '@material-ui/icons/Check'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
// core components
import GridContainer from '../../../shared/components/Grid/GridContainer.jsx'
import GridItem from '../../../shared/components/Grid/GridItem.jsx'
import CustomInput from '../../../shared/components/CustomInput/CustomInput.jsx'
import { Upload } from "@hlcr/mui/Upload";
import { TheoryModel } from "shared/models/theory.model"
import challengeStyle from '../../../shared/assets/jss/material-dashboard-pro-react/views/challengeStyle'

class GeneralStep extends React.Component {
	constructor(props) {
		super(props)
		const theory: TheoryModel = this.props.currentTheory
		this.state = {
			name: theory.name,
			nameState: '',
			titleImageUrl: theory.titleImageUrl,
			titleImageFile: theory.titleImageUrl ? { name: 'image' } : null,
			isPublic: theory.isPublic,
			abstract: theory.abstract,
			abstractState: ''
		}
		this.handleChange = this.handleChange.bind(this)
	}

	sendState() {
		return this.state
	}

	validate(field) {
		let allFieldsValid = true
		if (field === '*' || field === 'abstract' || field === 'name') {
			if (this.state.abstract.length > 0) {
				this.setState({ abstractState: 'success' })
			} else {
				allFieldsValid = false
				this.setState({ abstractState: 'error' })
			}
			if (this.state.name.length > 0) {
				this.setState({ nameState: 'success' })
			} else {
				allFieldsValid = false
				this.setState({ nameState: 'error' })
			}
			if (this.state.titleImageFile === null) {
				allFieldsValid = false
			}
			if (field !== '*') {
				return allFieldsValid
			}
		}
		return allFieldsValid
	}

	isValidated() {
		return this.validate('*')
	}

	handleChange(event) {
		const fieldName = event.target.name
		if (fieldName === undefined) {
			console.error(event.target)
			console.error('input has no name to bind')
			return
		}
		if (event.target.value === undefined) {
			console.error(event.target)
			console.error('input has no value to bind')
			return
		}
		this.setState({ [fieldName]: event.target.value }, () => {
			this.validate(fieldName)
		})
	}

	handleBlur = () => {
		this.props.handleOnBlur()
	}

	handleImageChange = titleImageFile =>
		this.setState({ titleImageFile, titleImageUrl: '' })

	handleToggle() {
		const { isPublic } = this.state

		this.setState({
			isPublic: !isPublic
		})
	}

	render() {
		const { classes } = this.props
		return (
			<GridContainer justify="center">
				<GridItem xs={12} sm={12}>
					<h4 className={classes.infoText}>
						Let's start with the basic information
					</h4>
				</GridItem>
				<GridItem xs={12} sm={4}>
					<div className={classes.imageUploadWrapper}>
						<Upload
							type="image"
							handleProcessedFile={this.handleImageChange}
							file={this.state.titleImageFile}
							imageUrl={`/api${this.state.titleImageUrl}`}
							rootClassName={classes.imageUploadFormRequired}
						/>
					</div>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						success={this.state.nameState === 'success'}
						error={this.state.nameState === 'error'}
						labelText={
							<span>
								Theory Name <small>(required)</small>
							</span>
						}
						id="name"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							name: 'name',
							onChange: event => this.handleChange(event),
							onBlur: this.handleBlur,
							value: this.state.name,
						}}
					/>
					<div className={classes.checkboxAndRadio}>
						<FormControlLabel
							control={
								<Checkbox
									tabIndex={-1}
									checked={this.state.isPublic}
									onClick={() => this.handleToggle()}
									checkedIcon={<Check className={classes.checkedIcon} />}
									icon={<Check className={classes.uncheckedIcon} />}
									classes={{
										checked: classes.checked,
										root: classes.checkRoot
									}}
								/>
							}
							classes={{
								label: classes.label
							}}
							label="Make public"
						/>
					</div>
					<CustomInput
						success={this.state.abstractState === 'success'}
						error={this.state.abstractState === 'error'}
						labelText={
							<span>
								Theory Abstract <small>(required)</small>
							</span>
						}
						id="abstract"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							multiline: true,
							name: 'abstract',
							onChange: event => this.handleChange(event),
							onBlur: this.handleBlur,
							value: this.state.abstract
						}}
					/>
				</GridItem>
			</GridContainer>
		)
	}
}

export default withStyles(challengeStyle)(GeneralStep)
