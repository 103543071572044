import React from "react";
import { compose } from "redux";
import { QuestionType } from "shared/models/question.model";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import SingleChoiceQuestion from "./SingleChoiceQuestion";
import ShortAnswerQuestion from "./ShortAnswerQuestion";

import RegularCard from "shared/components/Cards/RegularCard";
import { withStyles } from "@material-ui/core/styles";
import { withIntl } from "@hlcr/ui/Intl";
import { Button } from "@hlcr/mui/Button";
import { StyledMarkdown } from "@hlcr/ui";
import LongAnswerQuestion from "./LongAnswerQuestion";

const SUBMIT_DELAY = 300;

const styles = {
	actions: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
};

class QuizQuestion extends React.Component {
	state = {
		temporaryAnswer: {
			shortAnswer: { content: "" },
			multipleChoiceOptionAnswers: [],
		},
		isInitialized: false,
	};
	inputTimeout = null;

	static getDerivedStateFromProps(nextProps, prevState) {
		const { question, questionAnswer } = nextProps;
		const { isInitialized } = prevState;

		if (!isInitialized && question && questionAnswer) {
			return { isInitialized: true, temporaryAnswer: questionAnswer };
		}
		return null;
	}

	displayQuestion = () => {
		const { question, quizClosed, gradingInstruction } = this.props;
		switch (question.type) {
			case QuestionType.MULTIPLE_CHOICE:
				return (
					<MultipleChoiceQuestion
						onChange={this.setMCAnswer}
						disabled={quizClosed}
						question={question}
						answers={this.getMCAnswer()}
						gradingInstruction={gradingInstruction}
					/>
				);
			case QuestionType.SINGLE_CHOICE:
				return (
					<SingleChoiceQuestion
						onChange={this.setMCAnswer}
						disabled={quizClosed}
						question={question}
						answer={this.getSCAnswer()}
						gradingInstruction={gradingInstruction}
					/>
				);
			case QuestionType.SHORT_ANSWER:
				return (
					<ShortAnswerQuestion
						onChange={this.setShortAnswer}
						disabled={quizClosed}
						answer={gradingInstruction}
					/>
				);
			case QuestionType.LONG_ANSWER:
				return (
					<LongAnswerQuestion
						onChange={this.setLongAnswer}
						disabled={quizClosed}
						answer={gradingInstruction}
					/>
				);
			default:
				return <div>Question Type is not implemented yet.</div>;
		}
	};

	getMCAnswer() {
		const { temporaryAnswer } = this.state;
		return temporaryAnswer.multipleChoiceOptionAnswers || [];
	}

	getSCAnswer() {
		const { temporaryAnswer } = this.state;
		return (
			(temporaryAnswer.multipleChoiceOptionAnswers[0] &&
			 temporaryAnswer.multipleChoiceOptionAnswers[0].selectedOptionUuid) ||
			null
		);
	}

	submitTimeout(requestBody) {
		clearTimeout(this.inputTimeout);
		this.inputTimeout = setTimeout(this.submitAnswer(requestBody), SUBMIT_DELAY);
	}

	submitAnswer = requestBody => () => {
		const { updateQuestionAnswer, question, quiz } = this.props;
		updateQuestionAnswer(quiz.id, question.id, requestBody);
	};

	setMCAnswer = multipleChoiceOptionAnswers => {
		const { question } = this.props;
		const requestBody = {
			questionUuid: question.id,
			multipleChoiceOptionAnswers,
		};
		this.setState({ temporaryAnswer: requestBody, isInitialized: true });
		this.submitTimeout(requestBody);
	};

	setShortAnswer = shortAnswer => {
		const { question } = this.props;
		const requestBody = { questionUuid: question.id, shortAnswer };
		this.setState({ temporaryAnswer: requestBody, isInitialized: true });
		this.submitTimeout(requestBody);
	};

	setLongAnswer = longAnswer => {
		const { question } = this.props;
		const requestBody = { questionUuid: question.id, longAnswer };
		this.setState({ temporaryAnswer: requestBody, isInitialized: true });
		this.submitTimeout(requestBody);
	};

	setNextQuestion = () => {
		const { goToNextTab } = this.props;
		goToNextTab();
	};

	render() {
		const {
			question,
			isLast,
			goToNextTab,
			customCardClasses,
			classes,
			intl,
		} = this.props;
		return (
			<RegularCard
				customCardClasses={customCardClasses}
				content={
					question && (
						<div>
							<StyledMarkdown source={question.content} headingOffset={1} />
							{this.displayQuestion()}
						</div>
					)
				}
				footer={
					!isLast &&
					goToNextTab && (
						<div className={classes.actions}>
							<Button onClick={this.setNextQuestion} color="infoNoBackground">
								{intl.fm("common.labels.next")}
							</Button>
						</div>
					)
				}
			/>
		);
	}
}

QuizQuestion = compose(
	withStyles(styles),
	withIntl,
)(QuizQuestion);

export default QuizQuestion;
