// @flow
import type {
	AddField,
	UpdateField,
	UpdatePropForArray,
	UpdatePropForObj
} from '../shared/actions/api'
import {
	ADD_NOTIFICATION,
	API_REMOVE,
	API_REQUEST,
	API_RESULT,
	REMOVE_NOTIFICATION
} from "shared/actions/api"
import type { Action } from '../shared/actions'
import type { Message } from '../shared/models/Message'
import type { Dto } from './'
import { challengeInitState, ChallengeState } from './challenge.state'
import { theoryInitState, TheoryState } from './theory.state'
import { quizInitState, QuizState } from './quiz.state'

export type ApiState = {
	notifications: Array<Message>,
	resources: ChallengeState & TheoryState & QuizState
}

const initState: ApiState = {
	notifications: [],
	resources: {
		...challengeInitState,
		...theoryInitState,
		...quizInitState
	}
}

export default (state: ApiState = initState, action: Action): ApiState => {
	switch (action.type) {
		case API_REQUEST:
			if (!action.resource) {
				return state
			}
			return {
				...state,
				resources: {
					...state.resources,
					[action.resource]: {
						...state.resources[action.resource],
						pending: true
					}
				}
			}
		case API_RESULT:
			return {
				...state,
				resources: {
					...state.resources,
					[action.resource]: {
						data: mergeArray(
							state.resources[action.resource].data,
							action.data,
							action.updatePropForArray,
							action.updatePropForObj,
							action.updateField,
							action.setFieldOnObject
						),
						pending: false
					}
				}
			}
		case API_REMOVE:
			return {
				...state,
				resources: {
					...state.resources,
					[action.resource]: {
						data: removeFromArray(
							state.resources[action.resource].data,
							action.entity,
							action.updatePropForObj
						),
						pending: false
					}
				}
			}
		case ADD_NOTIFICATION:
			return {
				...state,
				notifications: [
					...state.notifications,
					{
						id: action.id,
						messageType: action.messageType,
						message: action.message
					}
				]
			}
		case REMOVE_NOTIFICATION:
			const actionId = action.id
			return {
				...state,
				notifications: [...state.notifications.filter(e => e.id !== actionId)]
			}
		default:
			return state
	}
}

const mergeArray = (
	resourceData: Dto[],
	update?: Dto | Dto[],
	updatePropForArray?: UpdatePropForArray,
	updatePropForObj?: UpdatePropForObj,
	updateField?: UpdateField,
	setFieldOnObject?: AddField
) => {
	if (!update) return resourceData

	if (!Array.isArray(update)) {
		if (!updateField) {
			const updateId = getId(update, updatePropForObj)
			return [
				...resourceData.filter(
					data => getId(data, updatePropForObj) !== updateId
				),
				setFieldOnObject
					? Object.assign(update, {
							[setFieldOnObject.key]: setFieldOnObject.value
					  })
					: update
			]
		} else
			return updateFieldInObject(
				resourceData,
				update,
				updatePropForObj,
				updateField
			)
	} else
		return updateFieldsInArray(
			resourceData,
			update,
			updatePropForArray,
			updatePropForObj,
			setFieldOnObject
		)
}

const updateFieldsInArray = (
	resourceData: Dto[],
	update: Dto[],
	updatePropForArray?: UpdatePropForArray,
	updatePropForObj?: UpdatePropForObj,
	setFieldOnObject?: AddField
) => {
	if (updatePropForObj) {
		return [
			...resourceData.filter(
				data =>
					!update.find(
						updateData =>
							getId(data, updatePropForObj) ===
							getId(updateData, updatePropForObj)
					)
			),
			...addFieldsOnObjects(update, setFieldOnObject)
		]
	}

	if (updatePropForArray) {
		const { key, value } = updatePropForArray
		return [
			...resourceData.filter(data =>
				typeof key === 'function'
					? key(data) !== value
					: data[key] && data[key] !== value
			),
			...addFieldsOnObjects(update, setFieldOnObject)
		]
	}

	return [...addFieldsOnObjects(update, setFieldOnObject)]
}

const addFieldsOnObjects = (update: Dto[], setFieldOnObject?: AddField) => {
	return setFieldOnObject
		? update.map(value =>
				Object.assign(
					value,
					setFieldOnObject &&
						typeof setFieldOnObject.key !== 'function' && {
							[setFieldOnObject.key]: setFieldOnObject.value
						}
				)
		  )
		: update
}

const updateFieldInObject = (
	resourceData: Dto[],
	update: Dto,
	updatePropForObj?: UpdatePropForObj,
	updateField: UpdateField
) => {
	const updateEntityId = updateField.entityId
	const updateEntity = resourceData.find(
		data => getId(data, updatePropForObj) === updateEntityId
	)
	if (updateEntity && Array.isArray(updateEntity[updateField.key])) {
		return [
			...resourceData.filter(
				data => getId(data, updatePropForObj) !== updateEntityId
			),
			Object.assign({}, updateEntity, {
				[updateField.key]: mergeArray(updateEntity[updateField.key], update)
			})
		]
	}
	if (updateEntityId && updateEntity)
		return [
			...resourceData.filter(
				data => getId(data, updatePropForObj) !== updateEntityId
			),
			Object.assign({}, updateEntity, {
				[updateField.key]: update
			})
		]
	else {
		return resourceData
	}
}

const removeFromArray = (
	resourceData: Dto[],
	entity: Object | Object[],
	updatePropForObj?: UpdatePropForObj
) => {
	if (!entity || !getId(entity, updatePropForObj)) {
		return resourceData
	}
	return [
		...resourceData.filter(
			data => getId(data, updatePropForObj) !== getId(entity, updatePropForObj)
		)
	]
}

const getId = (
	data: Dto,
	updatePropForObj?: UpdatePropForObj
): string | number => {
	const entityId = updatePropForObj
		? updatePropForObj.idSelector(data)
		: data.id
	!entityId &&
		process.env.NODE_ENV !== 'production' &&
		console.error('[getId]: Missing Id of updateEntity', data, updatePropForObj)
	return entityId
}
