// @flow
import moment from 'moment'

export const checkDatePassed = (date?: any): boolean => {
	return date ? moment(date).isBefore(moment()) : false
}

export const checkDatePendent = (date?: any): boolean => {
	return !!date && moment().diff(moment(date)) < 0
}

export const dateOnlyFormat = 'DD.MM.YYYY'
export const timeOnlyFormat = 'HH:mm'
export const fullDateFormat = 'DD.MM.YYYY HH:mm'
export const fullLongDateFormat = 'D MMMM YYYY HH:mm'

export const formatDateOnly = (date?: any): string => {
	if (!date) {
		return 'not defined'
	}
	return moment(date).format(dateOnlyFormat)
}

export const formatTimeOnly = (date?: any): string => {
	if (!date) {
		return 'not defined'
	}
	return moment(date).format(timeOnlyFormat)
}

export const formatFullDate = (date?: any): string => {
	if (!date) {
		return 'not defined'
	}
	return moment(date).format(fullDateFormat)
}
export const formatFullLongDate = (
	date?: any,
	alternative?: string
): string => {
	if (!date) {
		return alternative ? alternative : 'not defined'
	}
	return moment(date).format(fullLongDateFormat)
}

export const tryFormatShort = (date?: any): string => {
	if (!date) {
		return 'not defined'
	}
	const mDate = moment(date)
	if (moment().date() === mDate.date()) {
		return formatTimeOnly(date)
	} else {
		return formatFullDate(date)
	}
}
