// @flow

export const MessageType = {
	ERROR: 'ERROR',
	SUCCESS: 'SUCCESS'
}
export type MessageTypeType = $Keys<typeof MessageType>

export type Message = {
	id: ?string,
	messageType: MessageTypeType,
	message: ?string
}
