import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { StyledMarkdown } from "@hlcr/ui";
import { answerColors, getCorrectState } from "shared/helper/quiz";
import CustomCheckbox from "shared/components/CustomSwitch/CustomCheckbox";

const styles = {
	...Object.assign(
		{},
		...Object.keys(answerColors).map(k => ({
			["root" + k]: {
				color: answerColors[k],
				"&$checked": {
					color: answerColors[k],
				},
			},
			["disabled" + k]: {
				color: answerColors[k] + "FF !important",
			},
		})),
	),
	answerContainer: {
		display: "flex",
		flexDirection: "column",
	},
	label: {
		"& > div > p": { margin: 0 },
	},
	checked: {},
};

class MultipleChoiceQuestion extends React.Component {
	setChecked = id => event => {
		const value = event.target.checked;
		const { onChange, answers } = this.props;

		const checkedOptions = value
			? [ ...answers, { selectedOptionUuid: id } ]
			: answers.filter(a => a.selectedOptionUuid !== id);
		onChange(checkedOptions);
	};

	render() {
		const { question, disabled, classes } = this.props;
		return (
			<div className={classes.answerContainer}>
				{question.options &&
				 question.options.map((option, i) => {
					 const optionChecked = option.correct;

					 const correctState = getCorrectState(option.correct);

					 return (
						 <CustomCheckbox
							 key={i}
							 disabled={disabled}
							 onChange={this.setChecked(option.id)}
							 checked={!!optionChecked}
							 label={<StyledMarkdown source={option.content} />}
							 labelStyle={classes.label}
							 classes={
								 correctState && {
									 root: classes["root" + correctState],
									 disabled: classes["disabled" + correctState],
								 }
							 }
						 />
					 );
				 })}
			</div>
		);
	}
}

export default withStyles(styles)(MultipleChoiceQuestion);
