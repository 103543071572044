// @flow
import type { ApiRequestAction } from '../shared/actions/api'
import { API_REQUEST } from "shared/actions/api"
import { TheoryModel } from "shared/models/theory.model"
import * as BaseActions from './baseActions'
import { PipelineResultModel } from "shared/models/pipelineResult.model"
import { DeployResultModel } from "shared/models/deployResult.model"

const BASE_URL = '/api/theories'

export const fetchTheoryProjects = (pageNumber: number): ApiRequestAction => ({
	...BaseActions.fetchProjectsBase(pageNumber),
	url: `${BASE_URL}/?page=${pageNumber}`,
	resource: 'theories'
})

export const fetchTheory = (theoryUuid: string): ApiRequestAction => ({
	...BaseActions.fetchSingleBase,
	url: `${BASE_URL}/${theoryUuid}`,
	resource: 'currentTheory'
})

export const fetchTheoriesByInputSearch = (
	search: string
): ApiRequestAction => ({
	...BaseActions.fetchSearchBase(search),
	url: `${BASE_URL}/search?search=${search}`,
	resource: 'theories'
})

export const createTheory = (callback): ApiRequestAction => ({
	...BaseActions.createProjectBase(callback, TheoryModel),
	url: `${BASE_URL}/`,
	resource: 'currentTheory'
})

export const cloneTheory = (theoryUuid, callback): ApiRequestAction => ({
	...BaseActions.cloneProjectBase(theoryUuid, callback, TheoryModel),
	url: `${BASE_URL}/${theoryUuid}/clone/`,
	resource: 'currentTheory'
})

export const deployTheory = (theoryUuid, callback): ApiRequestAction => ({
	...BaseActions.deployProjectBase(theoryUuid, callback, DeployResultModel),
	url: `${BASE_URL}/${theoryUuid}/deploy/`
})

export const fetchTheoryPipeline = (
	pipelineId,
	callback
): ApiRequestAction => ({
	...BaseActions.fetchPipelineBase(pipelineId, callback, PipelineResultModel),
	url: `${BASE_URL}/deploy/${pipelineId}`
})

export const updateTheory = (
	theoryUuid: string,
	theory: TheoryModel,
	titleImageFile?: any,
	callback: Function,
	failCallback: Function
): ApiRequestAction => ({
	...BaseActions.updateProjectBase(
		theoryUuid,
		theory,
		titleImageFile,
		callback,
		failCallback,
		uploadTitleImage,
		removeTitleImage
	),
	url: `${BASE_URL}/${theoryUuid}`,
	resource: 'currentTheory',
	successNotification: 'Theory saved'
})

export const uploadTitleImage = (
	file: any,
	fileName: string,
	theoryUuid: string,
	onSuccess?: (string | number) => void,
	successNotification?: string | boolean
): ApiRequestAction => ({
	...BaseActions.uploadTitleImageBase(file, onSuccess, successNotification),
	url: `${BASE_URL}/${theoryUuid}/media/${fileName}`
})

export const removeTitleImage = (
	fileName: string,
	theoryUuid: string,
	onSuccess?: () => void,
	successNotification?: string | boolean
): ApiRequestAction => ({
	...BaseActions.removeTitleImageBase(onSuccess, successNotification),
	type: API_REQUEST,
	url: `${BASE_URL}/${theoryUuid}/media/${fileName}`
})

export const uploadMedia = (
	fileData: any,
	fileName: string,
	theoryUuid: string,
	onSuccess?: (string | number) => void,
	successNotification?: string | boolean
): ApiRequestAction => ({
	...BaseActions.uploadMediaBase(fileData, onSuccess, successNotification),
	url: `${BASE_URL}/${theoryUuid}/media/${fileName}/add/`
})
