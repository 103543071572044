import React from 'react'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'

const showGitlabStatusStyle = {
	deployStatus: {
		margin: '0 0 0 5px',
		padding: '5px'
	},
	running: {
		color: '#1f78d1'
	},
	pending: {
		color: '#fc9403'
	},
	success: {
		color: '#1aaa55'
	},
	failed: {
		color: '#db3b21'
	},
	canceled: {
		color: '#1f1f1f'
	},
	skipped: {
		color: '#a7a7a7'
	},
	uuid: {
		fontSize: '11px',
		color: 'grey'
	}
}

const ShowGitlabStatus = ({ classes, status }) => {
	let svg = {}
	switch (status) {
		case 'running':
			svg = (
				<svg
					fill={'#1f78d1'}
					width="14"
					height="14"
					viewBox="0 0 14 14"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g fillRule="evenodd">
						<path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" />
						<path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="#FFF" />
						<path d="M7 3c2.2 0 4 1.8 4 4s-1.8 4-4 4c-1.3 0-2.5-.7-3.3-1.7L7 7V3" />
					</g>
				</svg>
			)
			break
		case 'pending':
			svg = (
				<svg
					fill={'#fc9403'}
					width="14"
					height="14"
					viewBox="0 0 14 14"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g fillRule="evenodd">
						<path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" />
						<path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="#FFF" />
						<path d="M4.7 5.3c0-.2.1-.3.3-.3h.9c.2 0 .3.1.3.3v3.4c0 .2-.1.3-.3.3H5c-.2 0-.3-.1-.3-.3V5.3m3 0c0-.2.1-.3.3-.3h.9c.2 0 .3.1.3.3v3.4c0 .2-.1.3-.3.3H8c-.2 0-.3-.1-.3-.3V5.3" />
					</g>
				</svg>
			)
			break
		case 'success':
			svg = (
				<svg
					fill={'#1aaa55'}
					width="14"
					height="14"
					viewBox="0 0 14 14"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g fillRule="evenodd">
						<path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" />
						<path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="#FFF" />
						<path d="M6.278 7.697L5.045 6.464a.296.296 0 0 0-.42-.002l-.613.614a.298.298 0 0 0 .002.42l1.91 1.909a.5.5 0 0 0 .703.005l.265-.265L9.997 6.04a.291.291 0 0 0-.009-.408l-.614-.614a.29.29 0 0 0-.408-.009L6.278 7.697z" />
					</g>
				</svg>
			)
			break
		case 'failed':
			svg = (
				<svg
					fill={'#db3b21'}
					width="14"
					height="14"
					viewBox="0 0 14 14"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g fillRule="evenodd">
						<path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" />
						<path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="#FFF" />
						<path d="M7 5.969L5.599 4.568a.29.29 0 0 0-.413.004l-.614.614a.294.294 0 0 0-.004.413L5.968 7l-1.4 1.401a.29.29 0 0 0 .004.413l.614.614c.113.114.3.117.413.004L7 8.032l1.401 1.4a.29.29 0 0 0 .413-.004l.614-.614a.294.294 0 0 0 .004-.413L8.032 7l1.4-1.401a.29.29 0 0 0-.004-.413l-.614-.614a.294.294 0 0 0-.413-.004L7 5.968z" />
					</g>
				</svg>
			)
			break
		case 'canceled':
			svg = (
				<svg
					fill={'#1f1f1f'}
					width="14"
					height="14"
					viewBox="0 0 14 14"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g fillRule="evenodd">
						<path d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7z" />
						<path d="M13 7A6 6 0 1 0 1 7a6 6 0 0 0 12 0z" fill="#FFF" />
						<path d="M5.2 3.8l4.9 4.9c.2.2.2.5 0 .7l-.7.7c-.2.2-.5.2-.7 0L3.8 5.2c-.2-.2-.2-.5 0-.7l.7-.7c.2-.2.5-.2.7 0" />
					</g>
				</svg>
			)
			break
		case 'skipped':
			svg = (
				<svg
					fill={'#a7a7a7'}
					width="14"
					height="14"
					viewBox="0 0 14 14"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14z" />
					<path
						d="M7 13A6 6 0 1 0 7 1a6 6 0 0 0 0 12z"
						fill="#FFF"
						fillRule="nonzero"
					/>
					<path d="M6.415 7.04L4.579 5.203a.295.295 0 0 1 .004-.416l.349-.349a.29.29 0 0 1 .416-.004l2.214 2.214a.289.289 0 0 1 .019.021l.132.133c.11.11.108.291 0 .398L5.341 9.573a.282.282 0 0 1-.398 0l-.331-.331a.285.285 0 0 1 0-.399L6.415 7.04zm2.54 0L7.119 5.203a.295.295 0 0 1 .004-.416l.349-.349a.29.29 0 0 1 .416-.004l2.214 2.214a.289.289 0 0 1 .019.021l.132.133c.11.11.108.291 0 .398L7.881 9.573a.282.282 0 0 1-.398 0l-.331-.331a.285.285 0 0 1 0-.399L8.955 7.04z" />
				</svg>
			)
			break
		default:
			svg = ''
	}
	const dplClass = cx(classes.deployStatus, { [classes[status]]: status })
	return (
		<span className={dplClass}>
			{status} {svg}
		</span>
	)
}

export default withStyles(showGitlabStatusStyle)(ShowGitlabStatus)
