// @flow
import React from 'react'
// @material-ui/icons
import Check from '@material-ui/icons/Check'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
// core components
import GridContainer from '../../../shared/components/Grid/GridContainer.jsx'
import GridItem from '../../../shared/components/Grid/GridItem.jsx'
import CustomInput from '../../../shared/components/CustomInput/CustomInput.jsx'

import {challengeTypes} from "shared/helper/challenge"

import challengeStyle from '../../../shared/assets/jss/material-dashboard-pro-react/views/challengeStyle'
import { Upload } from "@hlcr/mui/Upload";
import type {ChallengeLevelDataModel} from '../../../shared/models/challengeLevel.model'
import {challengeLevels} from "shared/models/challengeLevel.model"
import {ChallengeModel} from "shared/models/challenge.model"

class GeneralStep extends React.Component {
	constructor(props) {
		super(props)
		const challenge: ChallengeModel = this.props.currentChallenge
		this.state = {
			name: challenge.name,
			nameState: '',
			titleImageUrl: challenge.titleImageUrl,
			titleImageFile: challenge.titleImageUrl ? { name: 'image' } : null,
			isPublic: challenge.isPublic,
			level: challenge.level.id,
			levelState: '',
			type: challenge.type,
			typeState: '',
			abstract: challenge.abstract,
			abstractState: ''
		}
		this.handleChange = this.handleChange.bind(this)
	}

	sendState() {
		return this.state
	}

	validate(field) {
		let allFieldsValid = true
		if (field === '*' || field === 'abstract' || field === 'name') {
			if (this.state.abstract.length > 0) {
				this.setState({ abstractState: 'success' })
			} else {
				allFieldsValid = false
				this.setState({ abstractState: 'error' })
			}
			if (this.state.name.length > 0) {
				this.setState({ nameState: 'success' })
			} else {
				allFieldsValid = false
				this.setState({ nameState: 'error' })
			}
			if (this.state.titleImageFile === null) {
				allFieldsValid = false
			}
			if (field !== '*') {
				return allFieldsValid
			}
		}
		return allFieldsValid
	}

	isValidated() {
		return this.validate('*')
	}

	handleChange(event) {
		const fieldName = event.target.name
		if (fieldName === undefined) {
			console.error(event.target)
			console.error('input has no name to bind')
			return
		}
		if (event.target.value === undefined) {
			console.error(event.target)
			console.error('input has no value to bind')
			return
		}
		this.setState({ [fieldName]: event.target.value }, () => {
			this.validate(fieldName)
		})
	}

	handleBlur = () => {
		this.props.handleOnBlur()
	}

	challengeTypes = (classes, intl) => {
		return challengeTypes.map(challengeType => (
			<MenuItem
				key={challengeType}
				classes={{
					root: classes.selectMenuItem,
					selected: classes.selectMenuItemSelected
				}}
				value={challengeType}
			>
				{intl.fm('challenge.type.' + challengeType)}
			</MenuItem>
		))
	}

	challengeLevelMenuItems = (classes, intl) => {
		return challengeLevels.map((challengeLevel: ChallengeLevelDataModel) => (
			<MenuItem
				key={challengeLevel.id}
				classes={{
					root: classes.selectMenuItem,
					selected: classes.selectMenuItemSelected
				}}
				value={`${challengeLevel.id}`}
			>
				{intl.fm(`challenge.level.${challengeLevel.name}`)}
			</MenuItem>
		))
	}

	handleImageChange = titleImageFile =>
		this.setState({ titleImageFile, titleImageUrl: '' })

	handleToggle() {
		const { isPublic } = this.state

		this.setState({
			isPublic: !isPublic
		})
	}

	render() {
		const { classes, intl } = this.props
		return (
			<GridContainer justify="center">
				<GridItem xs={12} sm={12}>
					<h4 className={classes.infoText}>
						Let's start with the basic information
					</h4>
				</GridItem>
				<GridItem xs={12} sm={4}>
					<div className={classes.imageUploadWrapper}>
						<Upload
							type="image"
							handleProcessedFile={this.handleImageChange}
							file={this.state.titleImageFile}
							imageUrl={`/api${this.state.titleImageUrl}`}
							rootClassName={classes.imageUploadFormRequired}
						/>
					</div>
					<h6>Abstract</h6>
					<CustomInput
						success={this.state.abstractState === 'success'}
						error={this.state.abstractState === 'error'}
						labelText={
							<span>
								Challenge Abstract <small>(required)</small>
							</span>
						}
						id="abstract"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							multiline: true,
							name: 'abstract',
							onChange: event => this.handleChange(event),
							onBlur: this.handleBlur,
							value: this.state.abstract
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						success={this.state.nameState === 'success'}
						error={this.state.nameState === 'error'}
						labelText={
							<span>
								Challenge Name <small>(required)</small>
							</span>
						}
						id="name"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							name: 'name',
							onChange: event => this.handleChange(event),
							onBlur: this.handleBlur,
							value: this.state.name,
						}}
					/>
					<FormControl fullWidth className={classes.selectFormControl}>
						<InputLabel htmlFor="simple-select" className={classes.selectLabel}>
							Choose Challenge Type
						</InputLabel>
						<Select
							MenuProps={{
								className: classes.selectMenu
							}}
							classes={{
								select: classes.select
							}}
							value={this.state.type}
							onChange={this.handleChange}
							inputProps={{
								name: 'type',
								id: 'type'
							}}
						>
							<MenuItem
								disabled
								classes={{
									root: classes.selectMenuItem
								}}
							>
								Choose Challenge Type
							</MenuItem>
							{this.challengeTypes(classes, intl)}
						</Select>
					</FormControl>
					<div className={classes.checkboxAndRadio}>
						<FormControlLabel
							control={
								<Checkbox
									tabIndex={-1}
									checked={this.state.isPublic}
									onClick={() => this.handleToggle()}
									checkedIcon={<Check className={classes.checkedIcon} />}
									icon={<Check className={classes.uncheckedIcon} />}
									classes={{
										checked: classes.checked,
										root: classes.checkRoot
									}}
								/>
							}
							classes={{
								label: classes.label
							}}
							label="Make public"
						/>
					</div>
					<FormControl fullWidth className={classes.selectFormControl}>
						<InputLabel htmlFor="simple-select" className={classes.selectLabel}>
							Choose Challenge Level
						</InputLabel>
						<Select
							MenuProps={{
								className: classes.selectMenu
							}}
							classes={{
								select: classes.select
							}}
							value={`${this.state.level}`}
							onChange={this.handleChange}
							inputProps={{
								name: 'level',
								id: 'level'
							}}
						>
							<MenuItem
								disabled
								classes={{
									root: classes.selectMenuItem
								}}
							>
								Challenge Level
							</MenuItem>
							{this.challengeLevelMenuItems(classes, intl)}
						</Select>
					</FormControl>
				</GridItem>
			</GridContainer>
		)
	}
}

export default withStyles(challengeStyle)(GeneralStep)
