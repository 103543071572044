import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// shared imports
import challengeStyle from "shared/assets/jss/material-dashboard-pro-react/views/challengeStyle";
import { StyledMarkdown } from "@hlcr/ui";
import { Accordion } from "@hlcr/mui";
import NoData from "shared/components/NoData/NoData";
import RegularCard from "shared/components/Cards/RegularCard";
import ItemGrid from "shared/components/Grid/ItemGrid";
import GridContainer from "shared/components/Grid/GridContainer";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import IconCard from "shared/components/Cards/IconCard";
import BuildIcon from "@material-ui/icons/Build";
import InfoSection from "./Review/InfoSection";
import ResourceTable from "./Review/ResourceTable";
import { ChallengeModel } from "shared/models/challenge.model";
import { getTokenNoFail } from "shared/auth/authUtils";

class ReviewAndSaveStep extends React.Component {
	constructor(props) {
		super(props);
		const challenge: ChallengeModel = this.props.currentChallenge;
		const ownerEmail = challenge.ownerEmail
			? challenge.ownerEmail
			: getTokenNoFail().email;
		this.state = {
			ownerEmail: ownerEmail,
		};
	}

	sendState() {
		return this.state;
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.checked });
	};

	handleRemoveOwner = () => {
		this.setState({ ownerEmail: "" });
	};

	isValidated() {
		return true;
	}

	resolveTitleImageSource = (url, uploaded) => {
		if (uploaded && uploaded.data) {
			return `data:image/png;base64, ${uploaded.data}`;
		}
		return url;
	};

	render() {
		const { getStepStates, mergeWizardSteps, intl, classes } = this.props;
		const stepStates = getStepStates();
		const titleImage =
			stepStates &&
			stepStates["general"] &&
			stepStates["general"].titleImageFile;
		const challenge = mergeWizardSteps(stepStates);
		return challenge ? (
			<div>
				<GridContainer>
					<ItemGrid xs={12} sm={12} md={12} lg={4}>
						<IconCard
							icon={BuildIcon}
							title={intl.fm("challenge.name")}
							iconColor="purple"
							content={
								<div>
									{titleImage !== null && (
										<div className={classes.imageContainer}>
											<img
												className={classes.image}
												src={this.resolveTitleImageSource(
													`/api${challenge.titleImageUrl}`,
													titleImage,
												)}
												alt={challenge.title}
											/>
										</div>
									)}
									{challenge.abstract ? (
										<StyledMarkdown
											source={challenge.abstract}
											headingOffset={2}
										/>
									) : (
										<NoData text={intl.fm("challenge.nodata.content")} />
									)}
								</div>
							}
						/>
					</ItemGrid>
					<ItemGrid xs={12} sm={12} md={6} lg={4}>
						<InfoSection
							challenge={challenge}
							unitType={intl.fm("challenge.name")}
							handleRemoveOwner={this.handleRemoveOwner}
							ownerEmail={this.state.ownerEmail}
						/>
					</ItemGrid>
					<ItemGrid xs={12} sm={12} md={6} lg={4}>
						<IconCard
							icon={OpenInNewIcon}
							title={intl.fm("challenge.titles.resources")}
							iconColor="purple"
							content={<ResourceTable resources={challenge.resources} />}
						/>
					</ItemGrid>
					<ItemGrid xs={12} sm={12} md={12} lg={12}>
						<h2>Grading</h2>
						{challenge.grading && challenge.grading.length > 0 ? (
							<RegularCard
								content={
									<StyledMarkdown
										source={challenge.grading}
										headingOffset={2}
									/>
								}
							/>
						) : (
							<NoData text={intl.fm("challenge.nodata.content")} />
						)}
					</ItemGrid>
					<ItemGrid xs={12} sm={12} md={12} lg={12}>
						<h2>Sections</h2>
						<Panel challenge={challenge} intl={intl} />
					</ItemGrid>
				</GridContainer>
			</div>
		) : (
			<NoData />
		);
	}
}

const Panel = ({ challenge, intl }) => {
	if (challenge.sections && challenge.sections.length > 0) {
		return challenge.sections.map((section, index) => (
			<RegularCard
				key={section.uuid}
				content={
					<DescriptionContent
						section={section}
						index={index}
						theoryUuid={challenge.uuid}
					/>
				}
			/>
		));
	} else {
		return <NoData text={intl.fm("challenge.nodata.content")} />;
	}
};

const DescriptionContent = ({ section, index, challengeUuid }) =>
	section && section.steps ? (
		<div>
			<StyledMarkdown
				source={section.content}
				headingOffset={2}
			/>
			{section.steps && section.steps.length
				? section.steps.map((step, stepIndex) => (
					<Accordion
						key={step.uuid ? step.uuid : stepIndex}
						sections={[
							{
								title: <span>Step {stepIndex + 1}: {step.title}</span>,
								content: (
									<StyledMarkdown
										source={step.content}
										headingOffset={3}
									/>
								),
							},
						]}
					/>
				))
				: null
			}
		</div>
	) : (
		<NoData text="NoData" />
	);

export default withStyles(challengeStyle)(ReviewAndSaveStep);
