// @flow strict

import { QuizLevelModel } from './quizLevel.model'
import { QuestionModel } from './question.model'

export type QuizUsage = 'ctf' | 'training'

export type QuizModelDto = {
	uuid: string,
	ownerEmail: string,
	title: string,
	name: string,
	level: any,
	createDate: string,
	createUser: string,
	modifyDate: string,
	modifyUser: string,
	modifyMessage: string,
	keywords: string[],
	categories: any[],
	titleImageUrl: string,
	thumbnailImageUrl: string,
	questions: any[],
	abstract: string,
	isPublic: boolean,
	readOnly: boolean
}

export class QuizModel {
	uuid: string
	ownerEmail: string
	title: string
	name: string
	level: QuizLevelModel
	createDate: ?Date
	createUser: string
	modifyDate: ?Date
	modifyUser: string
	modifyMessage: string
	keywords: string[]
	categories: string[]
	titleImageUrl: string
	thumbnailImageUrl: string
	questions: QuestionModel[]
	abstract: string
	isPublic: boolean
	readOnly: boolean

	constructor(
		uuid: string,
		ownerEmail: string,
		title: string,
		name: string,
		level: QuizLevelModel,
		createDate: Date,
		createUser: string,
		modifyDate: Date,
		modifyUser: string,
		modifyMessage: string,
		keywords: string[],
		categories: string[],
		titleImageUrl: string,
		thumbnailImageUrl: string,
		questions: QuestionModel[],
		abstract: string,
		isPublic: boolean,
		readOnly: boolean
	) {
		this.uuid = uuid
		this.ownerEmail = ownerEmail
		this.title = title
		this.name = name
		this.level = level
		this.createDate =
			createDate && isNaN(createDate.getTime()) ? null : createDate
		this.createUser = createUser
		this.modifyDate =
			modifyDate && isNaN(modifyDate.getTime()) ? null : modifyDate
		this.modifyUser = modifyUser
		this.modifyMessage = modifyMessage
		this.keywords = keywords
		this.categories = categories
		this.titleImageUrl = titleImageUrl
		this.thumbnailImageUrl = thumbnailImageUrl
		this.questions = questions
		this.abstract = abstract
		this.isPublic = isPublic
		this.readOnly = readOnly
	}

	static fromDto(dto: QuizModelDto): ?QuizModel {
		if (!dto) {
			return null
		}
		return new QuizModel(
			dto.uuid,
			dto.ownerEmail,
			dto.title,
			dto.name,
			QuizLevelModel.fromDto(dto.level),
			new Date(dto.createDate),
			dto.createUser,
			new Date(dto.modifyDate),
			dto.modifyUser,
			dto.modifyMessage,
			dto.keywords,
			dto.categories,
			dto.titleImageUrl,
			dto.thumbnailImageUrl,
			QuestionModel.fromDtoList(dto.questions),
			dto.abstract,
			dto.public,
			dto.readOnly
		)
	}

	static fromDtoList(dtos: QuizModelDto[]): QuizModel[] {
		if (!dtos) {
			return []
		}
		return dtos.map(dto => QuizModel.fromDto(dto))
	}

	toDto(): QuizModelDto {
		return {
			uuid: this.uuid,
			ownerEmail: this.ownerEmail,
			title: this.title,
			name: this.name,
			level: this.level.id,
			createDate: this.createDate ? this.createDate.toISOString() : '',
			createUser: this.createUser,
			modifyDate: this.modifyDate ? this.modifyDate.toISOString() : '',
			modifyUser: this.modifyUser,
			modifyMessage: this.modifyMessage,
			keywords: this.keywords,
			categories: this.categories,
			titleImageUrl: this.titleImageUrl,
			thumbnailImageUrl: this.thumbnailImageUrl,
			questions: this.questions.map((question, index) => question.toDto(index)),
			abstract: this.abstract,
			public: this.isPublic
		}
	}
}
