import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
// material-ui components
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
// material-ui icons
import Menu from '@material-ui/icons/Menu'
// core components
import HeaderLinks from './HeaderLinks'
import BreadCrumbs from './BreadCrumbs'

import headerStyle from '../../assets/jss/material-dashboard-pro-react/components/headerStyle.jsx'

function Header({ ...props }) {
	function makeBrand() {
		let name = '???'
		props.routes.some(route => {
			if (props.location.pathname.startsWith(route.path)) {
				return (name = route.name)
			}
			return false
		})
		return name
	}

	const { classes, color, rtlActive } = props
	const appBarClasses = cx(classes.appBar, {
		[classes[color]]: color
	})
	return (
		<AppBar className={appBarClasses}>
			<Toolbar className={classes.container}>
				<div className={classes.flex}>
					<BreadCrumbs
						className={classes.breadcrumbs}
						linkClassName={classes.breadcrumbLink}
						alternative={
							<Button href="#" className={classes.title}>
								{makeBrand()}
							</Button>
						}
					/>
				</div>
				<Hidden smDown implementation="css">
					<HeaderLinks rtlActive={rtlActive} />
				</Hidden>
				<Hidden mdUp>
					<IconButton
						className={classes.appResponsive}
						color="inherit"
						aria-label="open drawer"
						onClick={props.handleDrawerToggle}
					>
						<Menu />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	)
}

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
	rtlActive: PropTypes.bool,
	routes: PropTypes.array
}

export default withStyles(headerStyle)(Header)
