// @flow

import type { ChallengeModelDto } from './challenge.model'
import { ChallengeModel } from './challenge.model'
import { QuizModel } from './quiz.model'
import type { TheoryModelDto } from './theory.model'
import { TheoryModel } from './theory.model'

export type PaginatedDataModelDto = {
	total: number,
	totalPages: number,
	perPage: number,
	page: number,
	nextPage?: number,
	previousPage?: number,
	values: ChallengeModelDto[] | TheoryModelDto[]
}

export class PaginatedDataModel {
	total: number
	totalPages: number
	perPage: number
	page: number
	nextPage: number
	previousPage: number
	values: ChallengeModelDto[] | TheoryModelDto[]

	constructor(
		total: number,
		totalPages: number,
		perPage: number,
		page: number,
		nextPage?: number,
		previousPage?: number,
		values: ChallengeModelDto[] | TheoryModelDto[]
	) {
		this.total = total
		this.totalPages = totalPages
		this.perPage = perPage
		this.page = page
		this.nextPage = nextPage
		this.previousPage = previousPage
		this.values = values
	}

	static fromDtoWithChallenges(dto: PaginatedDataModelDto): PaginatedDataModel {
		if (!dto) {
			return
		}
		return new PaginatedDataModel(
			dto.total,
			dto.totalPages,
			dto.perPage,
			dto.page,
			dto.nextPage,
			dto.previousPage,
			ChallengeModel.fromDtoList(dto.values)
		)
	}

	static fromDtoWithQuizzes(dto: PaginatedDataModelDto): PaginatedDataModel {
		if (!dto) {
			return
		}
		return new PaginatedDataModel(
			dto.total,
			dto.totalPages,
			dto.perPage,
			dto.page,
			dto.nextPage,
			dto.previousPage,
			QuizModel.fromDtoList(dto.values)
		)
	}

	static fromDtoWithTheories(dto: PaginatedDataModelDto): PaginatedDataModel {
		if (!dto) {
			return
		}
		return new PaginatedDataModel(
			dto.total,
			dto.totalPages,
			dto.perPage,
			dto.page,
			dto.nextPage,
			dto.previousPage,
			TheoryModel.fromDtoList(dto.values)
		)
	}
}
