// @flow
import * as React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
// material-ui components
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'

import regularCardStyle from '../../assets/jss/material-dashboard-pro-react/components/regularCardStyle'

type Alignments = 'right' | 'left' | 'center'

type Props = {
	classes: Object,
	customCardClasses?: string,
	customCardTitleClasses?: string,
	customCardContentClasses?: string,
	plainCard?: boolean,
	cardTitle?: React.Node,
	cardSubtitle?: React.Node,
	content?: React.Node,
	titleAlign?: Alignments,
	contentAlign?: Alignments,
	subtitleAlign?: Alignments
}

function RegularCard({
	classes,
	plainCard,
	cardTitle,
	cardSubtitle,
	content,
	titleAlign,
	customCardClasses,
	customCardContentClasses,
	contentAlign,
	subtitleAlign,
	customCardTitleClasses,
	footer
}: Props) {
	const cardClasses = cx(classes.card, {
		[classes.cardPlain]: plainCard,
		[customCardClasses]: customCardClasses !== undefined
	})
	const titleClasses = cx(
		classes.cardTitle,
		classes[titleAlign],
		customCardTitleClasses
	)
	const subHeaderClasses = cx(classes.cardSubtitle, classes[subtitleAlign])
	return (
		<Card className={cardClasses}>
			{cardTitle !== undefined || cardSubtitle !== undefined ? (
				<CardHeader
					classes={{
						root: classes.cardHeader,
						title: titleClasses,
						subheader: subHeaderClasses
					}}
					title={cardTitle}
					subheader={cardSubtitle}
				/>
			) : null}
			<CardContent
				className={cx(
					classes.cardContent,
					customCardContentClasses,
					classes[contentAlign]
				)}
			>
				{content}
			</CardContent>
			{footer && (
				<CardActions className={classes.cardActions}>
					<div className={classes.cardStats}>{footer}</div>
				</CardActions>
			)}
		</Card>
	)
}

RegularCard.propTypes = {
	classes: PropTypes.object.isRequired,
	customCardClasses: PropTypes.string,
	customCardTitleClasses: PropTypes.string,
	plainCard: PropTypes.bool,
	cardTitle: PropTypes.node,
	cardSubtitle: PropTypes.node,
	footer: PropTypes.node,
	content: PropTypes.node,
	titleAlign: PropTypes.oneOf(['right', 'left', 'center']),
	contentAlign: PropTypes.oneOf(['right', 'left', 'center']),
	subtitleAlign: PropTypes.oneOf(['right', 'left', 'center'])
}

export default withStyles(regularCardStyle)(RegularCard)
