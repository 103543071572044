export default {
	'common.labels.goto': 'Go to',
	'common.labels.register': 'Register',
	'common.labels.unknown': 'UNKNOWN',
	'common.labels.filter': 'Filter',
	'common.labels.all': 'All',
	'common.labels.add': 'All',
	'common.labels.submit': 'Submit',
	'common.labels.cancel': 'Cancel',
	'common.labels.clearForm': 'Clear form',
	'common.labels.resetForm': 'Reset form',
	'common.labels.save': 'Save',
	'common.labels.delete': 'Delete',
	'common.labels.chooseBeginDate': 'Choose begin date',
	'common.labels.chooseEndDate': 'Choose end date',
	'common.labels.image': 'Image',
	'common.labels.next': 'Next',
	'common.labels.collapseSidebar': 'Collapse Sidebar',

	'navigation.entries.events': 'Events',
	'navigation.entries.teacher': 'Teacher',
	'navigation.entries.administration': 'Administration',
	'navigation.entries.report': 'Personal Report',
	'navigation.entries.classesReport': 'Classes Report',
	'navigation.entries.classReport': 'Class Report',
	'navigation.entries.teamsReport': 'Teams Report',
	'navigation.entries.usersReport': 'Users Report',
	'navigation.entries.userReport': 'User Report',
	'navigation.entries.manager': 'Manager',
	'navigation.entries.eventManagement': 'Event Management',
	'navigation.entries.teamsManagement': 'Teams Management',
	'navigation.entries.classesManagement': 'Classes Management',
	'navigation.entries.help': 'Help',
	'navigation.entries.user.account': 'Account',
	'navigation.entries.user.profile': 'Profile',
	'navigation.entries.user.logout': 'Logout',

	'events.titles.running': 'Running',
	'events.titles.upcoming': 'Upcoming',
	'events.titles.past': 'Past',
	'events.nodata': 'No events here',
	'event.table.name': 'Name',
	'event.table.categories': 'Categories',
	'event.table.level': 'Level',
	'event.table.grading': 'Grading',
	'event.table.points': 'Points',
	'event.table.mode': 'Mode',

	'theory.loadingListText': 'Loading Theories',
	'theory.nodata.content': 'no data',
	'theory.nodata.properties': 'no properties',
	'theory.nodata.description': 'no description yet',
	'theory.nodata': 'No theories found!',
	'theory.createNew': 'Create new Theory',
	'theory.title': 'Theories',
	'theory.name': 'Theory',
	'theory.keywords': 'Keywords',
	'theory.properties': 'Properties',
	'theory.access': 'Access',
	'theory.public': 'Public',
	'theory.private': 'Private',

	'quiz.loadingListText': 'Loading Quizzes',
	'quiz.nodata': 'No quizzes found!',
	'quiz.title': 'Quizzes',
	'quiz.name': 'Quiz',
	'quiz.createNew': 'Create new Quiz',
	'quiz.info.endTime': 'End time',
	'quiz.info.finishTime': 'Finish',
	'quiz.info.solved': 'Solved',
	'quiz.shortAnswer.label': 'Your answer',
	'quiz.comments': 'Comments and Grading',
	'quiz.type': 'Type',
	'quiz.properties': 'Properties',
	'quiz.access': 'Access',
	'quiz.public': 'Public',
	'quiz.private': 'Private',
	'quiz.keywords': 'Keywords',
	'quiz.nodata.content': 'no data',

	'quiz.questionType.MULTIPLE_CHOICE': 'Multiple Choice',
	'quiz.questionType.SINGLE_CHOICE': 'Single Choice',
	'quiz.questionType.SHORT_ANSWER': 'Short Anwser',
	'quiz.questionType.LONG_ANSWER': 'Long Answer',

	'quiz.level.unknown': 'UNKNOWN LEVEL',
	'quiz.level.novice': 'Novice',
	'quiz.level.easy': 'Easy',
	'quiz.level.medium': 'Medium',
	'quiz.level.hard': 'Hard',
	'quiz.level.leet': 'Leet',
	'quiz.shortAnswer.possibleAnswers': 'Possible answers',
	'quiz.longAnswer.answer': 'LongAnswer',

	'challenge.loadingListText': 'Loading Challenges',
	'challenge.nodata': 'No challenges found!',
	'challenge.createNew': 'Create new Challenge',
	'challenge.title': 'Challenges',
	'challenge.name': 'Challenge',
	'challenge.keywords': 'Keywords',
	'challenge.access': 'Access',
	'challenge.public': 'Public',
	'challenge.private': 'Private',
	'solution.status': 'Solution Status',
	'challenge.titles.event': 'Event',
	'challenge.titles.category': 'Category',
	'challenge.titles.resources': 'Resources',
	'challenge.titles.description': 'Description',
	'challenge.titles.solution': 'Solution History',

	'challenge.type': 'Type',
	'challenge.type.unknown': 'UNKNOWN TYPE',
	'challenge.type.jeopardy': 'Jeopardy',
	'challenge.type.ctf': 'CTF',
	'challenge.type.training': 'Training',

	'challenge.gold-nugget': 'Flag',

	'challenge.level.unknown': 'UNKNOWN LEVEL',
	'challenge.level.novice': 'Novice',
	'challenge.level.easy': 'Easy',
	'challenge.level.medium': 'Medium',
	'challenge.level.hard': 'Hard',
	'challenge.level.leet': 'Leet',

	'challenge.goldNuggetType.none': 'None',
	'challenge.goldNuggetType.static': 'Static',
	'challenge.goldNuggetType.dynamic': 'Dynamic',

	'challenge.nodata.content': 'no data',
	'challenge.nodata.resources': 'no resources',
	'challenge.nodata.properties': 'no properties',
	'challenge.nodata.description': 'no description yet',
	'challenge.description.step': 'Step',
	'challenge.comment.accepted': 'Accepted',
	'challenge.comment.submitted': 'Submitted',
	'challenge.comment.reviewed': 'Reviewed',
	'challenge.comment.newSolution.title': 'Submit new Solution',
	'challenge.comment.newSolution.titleTeacher': 'Submit new Grading',

	'challenge.mode.training': 'Training',
	'challenge.mode.test': 'Test',

	'challenge.properties': 'Properties',

	'challenge.resource.start': 'Start',
	'challenge.resource.stop': 'stop',
	'challenge.resource.download': 'Download',
	'challenge.resource.open': 'Open',
	'challenge.resource.showProps': 'Show properties',
	'challenge.resource.expiresOn': 'Expires on',

	'teacher.solutions.noResources': 'No resources',
	'teacher.event.noResources': 'No resources',
	'teacher.event.nextSolution': 'Next Solution',
	'teacher.event.view': 'View',

	'teacher.event.notFetched': 'Event not yet available',
	'teacher.event.solutions.title': 'Solutions',
	'teacher.event.users': 'User',
	'teacher.event.openSolutions': 'Open Solutions',
	'teacher.event.eventStatus': 'Event Status',
	'teacher.event.solution.newComment.title': 'Submit new grading',

	'user.label.username': 'Username',
	'user.label.email': 'Email',
	'user.label.leader': 'Leader',
	'user.label.actions': 'Actions',

	'team.titles.overview': 'Teams',
	'team.titles.general': 'General',
	'team.titles.users': 'Users',
	'team.noResources': 'No teams to display',
	'team.label.name': 'Name',
	'team.label.description': 'Description',
	'team.label.class': 'Class',
	'team.label.membercount': 'Members',
	'team.label.leader': 'Leader',
	'team.label.actions': 'Actions',
	'team.placeholder.selectLeader': 'Select Leader',
	'team.placeholder.selectClass': 'Select Class',
	'team.placeholder.addUser': 'Add User',
	'team.members.noResources': 'No team members available',

	'event.state.pendent': 'Upcoming',
	'event.state.running': 'Running',
	'event.state.finished': 'Past',

	'solution.state.unsolved': 'Unsolved',
	'solution.state.submitted': 'Submitted',
	'solution.state.rejected': 'Rejected',
	'solution.state.partial_points': 'Partial Points',
	'solution.state.full_points': 'Full Points',

	'quizSolution.state.corrected': 'Corrected',
	'quizSolution.state.in_progress': 'In progress',
	'quizSolution.state.manually_corrected': 'Manually corrected',

	'solution.history.solutionInstr': 'Your solution goes here *',
	'solution.history.maxCommentLength': 'maximum of 2000 chars',
	'solution.history.goldNugget.label': 'Solution *',
	'solution.history.goldNugget.helper': 'Write down the Flag or Codeword here',

	'solution.gradinginstruction.title': 'Grading Instructions',
	'solution.gradinginstruction.open': 'Open Grading Instructions',
	'solution.gradinginstruction.close': 'Close Grading Instructions',

	'teacher.event.solution.name': 'Name',
	'teacher.event.solution.title': 'Title',
	'teacher.event.solution.status': 'Status',
	'teacher.event.solution.percent': 'Percent',
	'teacher.event.solution.date': 'Date',
	'teacher.event.solution.actions': 'Actions',

	'teacher.solution.title': 'Solution from',
	'teacher.solution.grading.title': 'Solution grading',
	'teacher.solution.latestSolution': 'Latest Solution',
	'teacher.solution.missingPointsData': 'Missing points data',
	'teacher.solution.noTeacherComment': 'No teacher commented yet',
	'teacher.solution.lastUpdate': 'Last update',
	'teacher.solution.feedbackInstr': 'Your feedback goes here *',

	'report.card.title.user': 'User',
	'report.card.title.events': 'Events',
	'report.card.title.challenges': 'Challenges',
	'report.card.title.solutions': 'Solutions',
	'report.card.title.class': 'Class',
	'report.card.title.status': 'Status',
	'report.card.title.classes': 'Classes',
	'report.card.title.teams': 'Teams',
	'report.card.title.team': 'Team',
	'report.card.title.teachers': 'Teachers',
	'report.card.title.members': 'Members',

	'report.card.description.teachers': 'teachers',

	'report.table.classes': 'Classes',
	'report.table.classes.teamsFromAllClasses': 'Teams From All Classes',
	'report.table.classes.teamsFromClass': 'Users From All {className} Teams',
	'report.table.classes.header.name': 'Name',
	'report.table.classes.header.teams': 'Teams',
	'report.table.classes.header.users': 'Users',
	'report.table.classes.header.status': 'Status',
	'report.table.classes.header.running': 'Running',
	'report.table.classes.header.action': 'Action',
	'report.table.classes.allClasses': 'All Classes',

	'report.table.teams': 'Teams',
	'report.table.teams.header.name': 'Name',
	'report.table.teams.header.class': 'Class',
	'report.table.teams.header.members': 'Members',
	'report.table.teams.header.events': 'Events',
	'report.table.teams.header.units': 'Units',
	'report.table.teams.header.status': 'Status',
	'report.table.teams.header.action': 'Action',

	'report.table.users': 'Users',
	'report.table.users.header.name': 'Name',
	'report.table.users.header.team': 'Team',
	'report.table.users.header.leader': 'Leader',
	'report.table.users.header.solutions': 'Solutions',
	'report.table.users.header.status': 'Status',
	'report.table.users.header.action': 'Action',

	'report.table.event': 'Event',
	'report.table.challenge.header.event': 'Event',
	'report.table.challenge.header.user': 'User',
	'report.table.challenge.header.name': 'Name',
	'report.table.challenge.header.state': 'State',
	'report.table.challenge.header.status': 'Status',
	'report.table.challenge.header.last_solution': 'Last Solution',
	'report.table.challenge.header.action': 'Action',

	'report.table.allEvents': 'All Events',

	'report.table.noData': 'No Report Data available',

	'report.card.events.mandatory': 'mandatory',

	'manager.eventOverview.editEvent': 'Edit',
	'manager.eventOverview.newEvent.title': 'Create new Event',
	'manager.eventOverview.newEvent.copyImage': 'Copy existing Image',

	'manager.eventDetails.title': 'Manage Event Details',
	'manager.eventDetails.breadCrumb': 'Event Details',

	'manager.eventDetails.event.title': 'Event',

	'manager.table.noData': 'No Data yet',

	'manager.eventDetails.units.title': 'Units',
	'manager.eventDetails.units.table.name': 'Name',
	'manager.eventDetails.units.table.visibility': 'Visibility',
	'manager.eventDetails.units.table.autocheck': 'Autocheck',
	'manager.eventDetails.units.table.instructions': 'Instructions',
	'manager.eventDetails.units.table.level': 'Level',
	'manager.eventDetails.units.table.maxPoints': 'Max Points',
	'manager.eventDetails.units.table.actions': 'Actions',
	'manager.eventDetails.units.addChallenge': 'Add Challenge...',
	'manager.eventDetails.units.addTheory': 'Add Theory...',
	'manager.eventDetails.units.changeDate': 'Change date',
	'manager.eventDetails.units.unsetDates': 'Unset dates',

	'manager.eventDetails.teams.title': 'Teams',
	'manager.eventDetails.teams.table.name': 'Name',
	'manager.eventDetails.teams.table.class': 'Class',
	'manager.eventDetails.teams.table.mandatory': 'Mandatory',
	'manager.eventDetails.teams.table.actions': 'Actions',
	'manager.eventDetails.teams.add': 'Add Team...',

	'manager.eventDetails.users.title': 'Users',
	'manager.eventDetails.users.table.name': 'Name',
	'manager.eventDetails.users.table.teacher': 'Teacher',
	'manager.eventDetails.users.table.registered': 'Registered',
	'manager.eventDetails.users.table.actions': 'Actions',
	'manager.eventDetails.users.addTeacher': 'Add Teacher...',
	'manager.eventDetails.users.addStudent': 'Add Student...',

	'teacher.entityName': 'Teacher',
	'team.entityName': 'Team',
	'user.entityName': 'User',

	'class.entityName': 'Class',
	'class.field.name': 'Name',
	'class.field.description': 'Description',

	'event.entityName': 'Event',
	'event.field.name': 'Event title',
	'event.field.description': 'Description',

	'manager.classes.title': 'Classes',
	'manager.classes.create': 'Create Class',
	'manager.classes.details.breadCrumb': 'Class details',
	'manager.classes.noResources': 'No classes to display',
	'manager.classes.name': 'Name',
	'manager.classes.description': 'Description',
	'manager.classes.teamCount': 'Teams',
	'manager.classes.actions': 'Actions',

	'manager.classes.teams.title': 'Teams',
	'manager.classes.teams.table.name': 'Name',
	'manager.classes.teams.table.description': 'Description',
	'manager.classes.teams.table.actions': 'Actions',
	'manager.classes.teams.add': 'Add Team...',

	'manager.classes.teachers.title': 'Teachers',
	'manager.classes.teachers.table.name': 'Name',
	'manager.classes.teachers.table.actions': 'Actions',
	'manager.classes.teachers.add': 'Add Teacher...',

	'team.field.name': 'Name',
	'team.field.description': 'Description',
	'manager.teams.create': 'Create Team',

	'ranking.users.title': 'User Ranking',
	'ranking.teams.title': 'Team Ranking',
	'ranking.event': 'Event',
	'ranking.table.noData': 'No Ranking yet',
	'ranking.userRanking.rank': 'Rank',
	'ranking.userRanking.score': 'Score',
	'ranking.userRanking.name': 'Name',
	'ranking.userRanking.solvedChallenges': 'Solved Challenges',

	'': ''
}
