import eventUnitsStyle from './eventUnitsStyle'
import paginatedProjectStyles from './paginatedProjectStyles'

const deployComponentsStyles = {
	...paginatedProjectStyles,
	...eventUnitsStyle,
	deployStatus: {
		margin: '0 0 0 5px',
		padding: '5px'
	},
	running: {
		color: 'blue'
	},
	pending: {
		color: 'orange'
	},
	success: {
		color: 'green'
	},
	failed: {
		color: 'red'
	},
	canceled: {
		color: 'rgba(0, 0, 0, 0.87)'
	},
	skipped: {
		color: 'grey'
	},
	uuid: {
		fontSize: '11px',
		color: 'grey'
	}
}

export default deployComponentsStyles
