// @flow strict

export type DeployResultModelDto = {
	id: any,
	status: any,
	timestamp: any
}

export class DeployResultModel {
	id: string
	status: string
	timestamp: string

	constructor(id: string, status: string, timestamp: string) {
		this.id = id
		this.status = status
		this.timestamp = timestamp
	}

	static fromDto(dto: DeployResultModelDto): ?DeployResultModel {
		if (!dto) {
			return null
		}
		return new DeployResultModel(dto.id, dto.status, dto.timestamp)
	}

	static fromDtoList(dtos: DeployResultModelDto[]): DeployResultModel[] {
		if (!dtos) {
			return []
		}
		return dtos.map(dto => DeployResultModel.fromDto(dto))
	}

	toDto(): DeployResultModelDto {
		return {
			id: this.id,
			status: this.status,
			timestamp: this.timestamp
		}
	}
}
