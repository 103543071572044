import { grayColor } from 'shared/assets/jss/material-dashboard-pro-react.jsx'

const extendedTablesStyle = {
	right: {
		textAlign: 'right'
	},
	center: {
		textAlign: 'center'
	},
	actionButton: {
		margin: '0 0 0 5px',
		padding: '5px'
	},
	icon: {
		verticalAlign: 'middle',
		width: '17px',
		height: '17px',
		top: '-1px',
		position: 'relative'
	},
	thumb: {
		height: 72,
		width: 110
	},
	thumbSquare: {
		height: 72,
		width: 72
	},
	categoryImage: {
		width: 36,
		height: 36,
		marginRight: 6
	},
	categoryText: {
		marginRight: 6
	},
	tooltip: {
		fontSize: '1.2em',
		letterSpacing: '.05em'
	},
	typeIcon: {
		color: grayColor
	},
	useFlagIcon: {
		marginRight: 6
	},
	panelWithInfo: {
		display: 'flex'
	},
	mainTable: {
		flexGrow: 50,
		alignSelf: 'stretch'
	},
	filterField: {
		float: 'right',
		marginTop: '-10px'
	},
	filterButton: {
		margin: 0
	},
	filterButtonIcon: {
		verticalAlign: 'middle',
		top: '-1px',
		position: 'relative'
	},
	imageUploadForm: {
		minWidth: '200px',
		maxWidth: '600px',
		flexBasis: 'auto',

		flexGrow: 10,
		margin: '0 8px'
	}
}

export default extendedTablesStyle
