import React from "react";
import PropTypes from "prop-types";
import { Button } from "@hlcr/mui/Button";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "../../shared/components/CustomTooltip/CustomTooltip";
import CustomTooltip from "../../shared/components/CustomTooltip/CustomTooltip";
import { projectNamespaceIcons } from "shared/helper/projects";
import Paper from "@material-ui/core/Paper/Paper";
import { formatFullDate } from "shared/helper/dateCalc";
import GridContainer from "../../shared/components/Grid/GridContainer";
import ItemGrid from "../../shared/components/Grid/ItemGrid";
import IconCard from "../../shared/components/Cards/IconCard";
import Assignment from "@material-ui/icons/Assignment";
import Pagination from "../../shared/components/Pagination/Pagination";
import Table from "../Table/Table";
import { hasWriteAccess } from "shared/auth/authUtils";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomSearch from "../../components/Search/CustomSearch";
import paginatedProjectStyles from "../../shared/assets/jss/material-dashboard-pro-react/views/paginatedProjectStyles";
import DeployComponents from "./DeployComponents";

const ENTER_KEY = 13;

const PaginatedProjects = ({
	                           triggerChange,
	                           inputSearchValue,
	                           fetchPage,
	                           createNewProject,
	                           cloneProject,
	                           deployProject,
	                           fetchPipeline,
	                           paginatedProjects,
	                           languageKey,
	                           pathSegment,
	                           classes,
	                           intl,
	                           showError,
	                           history,
                           }) => {
	const pages = [];
	if (paginatedProjects) {
		if (paginatedProjects.totalPages > 1) {
			pages.push({
				           text: "PREV",
				           onClick: paginatedProjects.previousPage
					           ? () => {
						           fetchPage(paginatedProjects.previousPage);
					           }
					           : () => {
					           },
				           disabled: paginatedProjects.previousPage === undefined,
			           });
		}

		for (
			let pageIndex = 1;
			pageIndex <= paginatedProjects.totalPages;
			pageIndex++
		) {
			pages.push({
				           text: pageIndex,
				           onClick: () => fetchPage(pageIndex),
				           active: paginatedProjects.page === pageIndex,
			           });
		}
		if (paginatedProjects.totalPages > 1) {
			pages.push({
				           text: "NEXT",
				           onClick: () => fetchPage(paginatedProjects.nextPage),
				           disabled: paginatedProjects.nextPage === undefined,
			           });
		}
	}

	const handleKeyDown = event => {
		if (event.keyCode === ENTER_KEY) {
			triggerChange(event.target.value, true);
		}
	};

	const handleSearchButtonClick = (searchValue) => {
		triggerChange(searchValue, true);
	};

	const handleNewProject = () => {
		createNewProject();
	};

	const handleEditProject = row => {
		if (hasWriteAccess(row)) {
			history.push(`/${pathSegment}/${row.uuid}/general/`);
		} else {
			history.push(`/${pathSegment}/${row.uuid}/review/`);
		}
	};

	const EditButton = ({ project }) => (
		<CustomTooltip title="Edit">
			<Button color="info" customClass={classes.actionButton}>
				{hasWriteAccess(project) ? (
					<EditIcon className={classes.icon} />
				) : (
					<VisibilityIcon className={classes.icon} />
				)}
			</Button>
		</CustomTooltip>
	);

	const DuplicateButton = ({ project }) => (
		<CustomTooltip title="Clone">
			<Button
				onClick={event => {
					event.stopPropagation();
					cloneProject(project.uuid);
				}}
				color="warning"
				customClass={classes.actionButton}
			>
				<FileCopyIcon className={classes.icon} />
			</Button>
		</CustomTooltip>
	);

	const TableIcon = ({ icon, title }) => (
		<span>
			<Tooltip title={title} placement="right">
				<div>{icon}</div>
			</Tooltip>
		</span>
	);

	const tableRenderer = [
		{
			renderCell: project => (
				<TableIcon
					title={project.name}
					icon={projectNamespaceIcons[languageKey]}
				/>
			),
		},
		{
			renderCell: (project) => (
				<Paper square style={{ display: "inline-block" }}>
					<img
						src={`/api${project.thumbnailImageUrl}`}
						onError={e => {
							e.target.src = "/thumbnail_placeholder.svg";
						}}
						className={classes.thumbSquare}
						alt={project.name}
					/>
				</Paper>
			),
		},
		{
			title: intl.fm("event.table.name"),
			renderCell: (project) => (
				<div>
					<b>{project.name}</b>
					<br />
					<span className={classes.uuid}>{project.uuid}</span>
				</div>
			),
		},
		{
			title: "Modified",
			renderCell: (project) =>
				project.modifyUser ? `${project.modifyUser} (${formatFullDate(project.modifyDate)})` : `${formatFullDate(project.modifyDate)}`,
		},
		{
			title: "Created",
			renderCell: (project) =>
				project.createUser ? `${project.createUser} (${formatFullDate(project.createDate)})` : `${formatFullDate(project.createDate)}`,
		},
		{
			renderCell: project => (
				<div>
					<DuplicateButton project={project} />{" "}
					<DeployComponents
						classes={classes}
						deployProject={deployProject}
						fetchPipeline={fetchPipeline}
						project={project}
					/>
				</div>
			),
		},
		{
			renderCell: project => (
				<div>
					<EditButton project={project} />
				</div>
			),
		},
	];

	const content =
		paginatedProjects &&
		paginatedProjects.values &&
		paginatedProjects.values.length > 0 ? (
			<React.Fragment>
				<div style={{ textAlign: "center" }}>
					<Pagination pages={pages} />
				</div>
				<Table
					tableData={paginatedProjects.values}
					tableRenderer={tableRenderer}
					hover
					onRowClick={handleEditProject}
				/>
			</React.Fragment>
		) : (
			<div>{intl.fm(`${languageKey}.nodata`)}</div>
		);

	const container = (
		<div className={classes.title}>
			<span>{intl.fm(`${languageKey}.title`)}</span>
			<CustomSearch
				onSearchKeyDown={handleKeyDown}
				onSearchButtonClick={handleSearchButtonClick}
				inputSearchValue={inputSearchValue}
				showError={showError}
			/>
			<div>
				<Button
					onClick={handleNewProject}
					color="infoNoBackground"
					customClass={classes.titleButton}
				>
					{intl.fm(`${languageKey}.createNew`)}
				</Button>
			</div>
		</div>
	);

	return (
		<GridContainer>
			<ItemGrid xs={12}>
				<IconCard
					align={"center"}
					icon={Assignment}
					iconColor="purple"
					title={container}
					content={content}
				/>
			</ItemGrid>
		</GridContainer>
	);
};

PaginatedProjects.propTypes = {
	classes: PropTypes.object.isRequired,
	fetchPage: PropTypes.func.isRequired,
	fetchPipeline: PropTypes.func.isRequired,
	createNewProject: PropTypes.func.isRequired,
	cloneProject: PropTypes.func.isRequired,
	deployProject: PropTypes.func.isRequired,
	paginatedProjects: PropTypes.object,
	languageKey: PropTypes.string,
	pathSegment: PropTypes.string,
	history: PropTypes.object,
};

export default withStyles(paginatedProjectStyles)(PaginatedProjects);
