import React from 'react'
import { Route } from 'react-router-dom'
import { AUTHENTICATION_COOKIE_NAME, handleAuthError } from "@hlcr/ui/auth/keycloak";
import jwtDecode from 'jwt-decode'
import NoData from '../components/NoData/NoData'
import BlockIcon from '@material-ui/icons/Block'

export const checkHasRole = (needsRole, token = getToken()) => {
	if (needsRole === undefined) return true
	const areUserRolesGiven = token =>
		token &&
		token.resource_access &&
		token.resource_access.ces &&
		Array.isArray(token.resource_access.ces.roles)

	if (!areUserRolesGiven(token)) return false

	if (!Array.isArray(needsRole)) {
		return token.resource_access.ces.roles.some(role => role === needsRole)
	} else {
		return token.resource_access.ces.roles.some(role =>
			needsRole.some(chkRole => chkRole === role)
		)
	}
}

export const ProtectedRoute = ({ component, needsRole, exact, key, path }) => {
	return checkHasRole(needsRole) ? (
		<Route component={component} exact={exact} key={key} path={path} />
	) : (
		<Route
			component={() => <NoData text="Access Denied" Icon={BlockIcon} />}
			exact={exact}
			key={key}
			path={path}
		/>
	)
}

export const getCurrentUsername = () => {
	let token = getTokenNoFail()
	return token && token.preferred_username
		? token.preferred_username
		: 'unknown'
}

export const hasWriteAccess = project => {
	let token = getTokenNoFail()

	return (
		project &&
		!project.readOnly &&
		((token &&
			token.resource_access &&
			token.resource_access.ces &&
			token.resource_access.ces.roles &&
			token.resource_access.ces.roles.includes('super_editor')) ||
			(project && project.ownerEmail === undefined) ||
			(project && project.ownerEmail === '') ||
			(token && project && token.email === project.ownerEmail))
	)
}

export const getToken = () => {
	let tokenEncoded = getCookieValue(AUTHENTICATION_COOKIE_NAME)
	try {
		return jwtDecode(tokenEncoded)
	} catch (e) {
		console.error('getToken_JWT Decode Error')
		handleAuthError()
	}
}

export const getTokenNoFail = () => {
	let tokenEncoded = getCookieValue(AUTHENTICATION_COOKIE_NAME)
	try {
		return jwtDecode(tokenEncoded)
	} catch (e) {
		return undefined
	}
}

export function getCookieValue(a) {
	let b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)')
	return b ? b.pop() : ''
}

export function doLogout() {
	if (window.keycloak && window.keycloak.logout) {
		window.history.pushState({}, '', '/')
		window.keycloak.logout({ redirectUri: window.location.origin })
	}
	document.cookie = AUTHENTICATION_COOKIE_NAME + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC'
}
