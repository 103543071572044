import React from 'react'
import { withIntl } from "@hlcr/ui/Intl"
import { withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip/Chip'

const styles = { textField: { width: 400 } }

class ShortAnswerQuestion extends React.Component {
	onChange = event => {
		const value = event.target.value
		const { onChange } = this.props
		onChange(value)
	}

	render() {
		const { disabled, answer, classes } = this.props
		if (disabled && answer) {
			return (
				<div>
					{answer.shortAnswers &&
						answer.shortAnswers.map(data => {
							return <Chip key={data} label={data} className={classes.chip} />
						})}
				</div>
			)
		} else return <div>Not yet implemented</div>
	}
}

export default withStyles(styles)(withIntl(ShortAnswerQuestion))
