import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { dangerColor, primaryColor } from "shared/assets/jss/material-dashboard-pro-react";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "shared/components/CustomButtons/IconButton";

const styles = {
	searchInput: {
		border: "1px solid #ccc",
		borderRadius: 100,
		padding: "1px 10px",
		margin: 0,
		width: 300,
		height: 40,
		"&:hover": {
			borderColor: primaryColor,
		},
		"&:focus": {
			border: `2px solid ${primaryColor}`,
			padding: "0 9px",
		},
	},
	searchWrapper: {
		display: "flex",
		justifyContent: "center",
		"& > button": {
			marginLeft: "-45px",
		},
	},
	inputWrapper: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	inputError: {
		color: dangerColor,
		fontWeight: "bold",
		fontSize: "0.75rem",
	},
};

class CustomSearch extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			currentSearchValue: "",
		};
	}

	componentDidMount() {
		this.setState({ currentSearchValue: this.props.inputSearchValue });
	}

	render() {
		const {
			classes,
			onSearchKeyDown,
			onSearchButtonClick,
			showError,
		} = this.props;

		return (
			<div className={classes.inputWrapper}>
				<div className={classes.searchWrapper}>
					<input
						value={this.state.currentSearchValue}
						onChange={(event) => this.setState({ currentSearchValue: event.target.value })}
						onKeyDown={onSearchKeyDown}
						placeholder="Search..."
						className={classes.searchInput}
					/>
					<IconButton
						color="infoNoBackground"
						onClick={() => onSearchButtonClick(this.state.currentSearchValue)}
					>
						<SearchIcon />
					</IconButton>
				</div>
				{showError && <span className={classes.inputError}>Type at least 3 characters...</span>}
			</div>
		);
	}
}

export default withStyles(styles)(CustomSearch);
