import { QuestionType } from "shared/models/question.model"
import {
	infoColor,
	successColor,
	warningColor
} from '../assets/jss/material-dashboard-pro-react.jsx'

export const QuestionAnswerState = {
	UNSOLVED: 'UNSOLVED',
	SOLVED: 'SOLVED',
	RIGHT: 'RIGHT',
	WRONG: 'WRONG'
}

export const answerColors = {
	[QuestionAnswerState.UNSOLVED]: '',
	[QuestionAnswerState.SOLVED]: infoColor,
	[QuestionAnswerState.RIGHT]: successColor,
	[QuestionAnswerState.WRONG]: warningColor
}

export const answerBgColors = {
	[`bgColor${QuestionAnswerState.UNSOLVED}`]: {},
	[`bgColor${QuestionAnswerState.SOLVED}`]: { background: infoColor },
	[`bgColor${QuestionAnswerState.RIGHT}`]: { background: successColor },
	[`bgColor${QuestionAnswerState.WRONG}`]: { background: warningColor }
}

export const getQuestionState = (questionAnswer, gradingInstruction) => {
	if (!questionAnswer || (!gradingInstruction && !hasAnyAnswer(questionAnswer)))
		return QuestionAnswerState.UNSOLVED
	if (!gradingInstruction) return QuestionAnswerState.SOLVED
	switch (gradingInstruction.type) {
		case QuestionType.MULTIPLE_CHOICE:
			return getCorrectState(checkMCAnswer(gradingInstruction, questionAnswer))
		case QuestionType.SINGLE_CHOICE:
			return getCorrectState(checkSCAnswer(gradingInstruction, questionAnswer))
		case QuestionType.SHORT_ANSWER:
			return getCorrectState(
				checkShortAnswer(gradingInstruction, questionAnswer)
			)
		default:
			return QuestionAnswerState.UNSOLVED
	}
}
const checkSCAnswer = (gradingInstruction, questionAnswer) => {
	const correctOption = gradingInstruction.options.find(gia => gia.correct)
	return (
		questionAnswer.multipleChoiceOptionAnswers.length === 1 &&
		questionAnswer.multipleChoiceOptionAnswers[0].selectedOptionUuid ===
			correctOption.id
	)
}

const checkMCAnswer = (gradingInstruction, questionAnswer) => {
	const correctOptions = gradingInstruction.options.filter(gia => gia.correct)
	console.log(
		'checkMCAnswer',
		correctOptions,
		questionAnswer.multipleChoiceOptionAnswers
	)
	return (
		questionAnswer.multipleChoiceOptionAnswers.length ===
			correctOptions.length &&
		correctOptions.every(co =>
			questionAnswer.multipleChoiceOptionAnswers.some(
				mca => mca.selectedOptionUuid === co.id
			)
		)
	)
}

const checkShortAnswer = (gradingInstruction, questionAnswer) => {
	const lowerCaseAnswer = questionAnswer.shortAnswer.content.toLowerCase()
	return gradingInstruction.shortAnswers.some(
		gia => gia.content.toLowerCase() === lowerCaseAnswer
	)
}

export const isOptionRight = (option, gradingInstruction, isChecked) => {
	const solution = gradingInstruction.options.find(gia => gia.id === option.id)
	return solution.correct ? isChecked : !isChecked
}

export const getCorrectState = isRight =>
	isRight ? QuestionAnswerState.RIGHT : QuestionAnswerState.WRONG

export const hasAnyAnswer = questionAnswer =>
	(questionAnswer.shortAnswer &&
		questionAnswer.shortAnswer.content.length > 0) ||
	(questionAnswer.multipleChoiceOptionAnswers &&
		questionAnswer.multipleChoiceOptionAnswers.length > 0)
