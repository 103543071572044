// @flow strict

export type PipelineResultModelDto = {
	pipelineId: any,
	status: any
}

export class PipelineResultModel {
	pipelineId: string
	status: string

	constructor(pipelineId: string, status: string) {
		this.pipelineId = pipelineId
		this.status = status
	}

	static fromDto(dto: PipelineResultModelDto): ?PipelineResultModel {
		if (!dto) {
			return null
		}
		return new PipelineResultModel(dto.pipelineId, dto.status)
	}

	static fromDtoList(dtos: PipelineResultModelDto[]): PipelineResultModel[] {
		if (!dtos) {
			return []
		}
		return dtos.map(dto => PipelineResultModel.fromDto(dto))
	}

	toDto(): PipelineResultModelDto {
		return {
			pipelineId: this.pipelineId,
			status: this.status
		}
	}
}
