// @flow
import type { ApiRequestAction } from "../shared/actions/api"
import { API_REQUEST } from "shared/actions/api";
import { ChallengeModel } from "shared/models/challenge.model";
import { DeployResultModel } from "shared/models/deployResult.model";
import * as BaseActions from "./baseActions";
import { PipelineResultModel } from "shared/models/pipelineResult.model";

const BASE_URL = '/api/challenges'

export const fetchChallengeProjects = (
	pageNumber: number,
	searchString: String,
): ApiRequestAction => ({
	...BaseActions.fetchProjectsBase(pageNumber, searchString),
	url: `${BASE_URL}/?page=${pageNumber}&search=${encodeURIComponent(searchString)}`,
	resource: 'challenges'
})

export const fetchChallenge = (challengeUuid: string): ApiRequestAction => ({
	...BaseActions.fetchSingleBase,
	url: `${BASE_URL}/${challengeUuid}`,
	resource: 'currentChallenge'
})

export const fetchChallengesByInputSearch = (
	search: string
): ApiRequestAction => ({
	...BaseActions.fetchSearchBase(search),
	url: `${BASE_URL}/search?search=${encodeURI(search)}`,
	resource: "challenges",
})

export const createChallenge = (callback): ApiRequestAction => ({
	...BaseActions.createProjectBase(callback, ChallengeModel),
	url: `${BASE_URL}/`,
	resource: 'currentChallenge'
})

export const cloneChallenge = (challengeUuid, callback): ApiRequestAction => ({
	...BaseActions.cloneProjectBase(challengeUuid, callback, ChallengeModel),
	url: `${BASE_URL}/${challengeUuid}/clone/`,
	resource: 'currentChallenge'
})

export const deployChallenge = (challengeUuid, callback): ApiRequestAction => ({
	...BaseActions.deployProjectBase(challengeUuid, callback, DeployResultModel),
	url: `${BASE_URL}/${challengeUuid}/deploy/`
})

export const fetchChallengePipeline = (
	pipelineId,
	callback
): ApiRequestAction => ({
	...BaseActions.fetchPipelineBase(pipelineId, callback, PipelineResultModel),
	url: `${BASE_URL}/deploy/${pipelineId}`
})

export const updateChallenge = (
	challengeUuid: string,
	challenge: ChallengeModel,
	titleImageFile?: any,
	callback: Function,
	failCallback: Function
): ApiRequestAction => ({
	...BaseActions.updateProjectBase(
		challengeUuid,
		challenge,
		titleImageFile,
		callback,
		failCallback,
		uploadTitleImage,
		removeTitleImage
	),
	url: `${BASE_URL}/${challengeUuid}`,
	resource: 'currentChallenge',
	successNotification: 'Challenge saved'
})

export const uploadTitleImage = (
	file: any,
	fileName: string,
	challengeUuid: string,
	onSuccess?: (string | number) => void,
	successNotification?: string | boolean
): ApiRequestAction => ({
	...BaseActions.uploadTitleImageBase(file, onSuccess, successNotification),
	url: `${BASE_URL}/${challengeUuid}/media/${fileName}`
})

export const removeTitleImage = (
	fileName: string,
	challengeUuid: string,
	onSuccess?: () => void,
	successNotification?: string | boolean
): ApiRequestAction => ({
	...BaseActions.removeTitleImageBase(onSuccess, successNotification),
	type: API_REQUEST,
	url: `${BASE_URL}/${challengeUuid}/media/${fileName}`
})

export const uploadMedia = (
	fileData: any,
	fileName: string,
	challengeUuid: string,
	onSuccess?: (string | number) => void,
	successNotification?: string | boolean
): ApiRequestAction => ({
	...BaseActions.uploadMediaBase(fileData, onSuccess, successNotification),
	url: `${BASE_URL}/${challengeUuid}/media/${fileName}/add/`
})
