// @flow
import type { ApiRequestAction } from '../shared/actions/api'
import { API_REQUEST } from "shared/actions/api"
import { QuizModel } from "shared/models/quiz.model"
import * as BaseActions from './baseActions'
import { PipelineResultModel } from "shared/models/pipelineResult.model"
import { DeployResultModel } from "shared/models/deployResult.model"

const BASE_URL = '/api/quizzes'

export const fetchQuizProjects = (pageNumber: number): ApiRequestAction => ({
	...BaseActions.fetchProjectsBase(pageNumber),
	url: `${BASE_URL}/?page=${pageNumber}`,
	resource: 'quizzes'
})

export const fetchQuiz = (quizUuid: string): ApiRequestAction => ({
	...BaseActions.fetchSingleBase,
	url: `${BASE_URL}/${quizUuid}`,
	resource: 'currentQuiz'
})

export const fetchQuizzesByInputSearch = (
	search: string
): ApiRequestAction => ({
	...BaseActions.fetchSearchBase(search),
	url: `${BASE_URL}/search?search=${search}`,
	resource: 'quizzes'
})

export const createQuiz = (callback): ApiRequestAction => ({
	...BaseActions.createProjectBase(callback, QuizModel),
	url: `${BASE_URL}/`,
	resource: 'currentQuiz'
})

export const cloneQuiz = (quizUuid, callback): ApiRequestAction => ({
	...BaseActions.cloneProjectBase(quizUuid, callback, QuizModel),
	url: `${BASE_URL}/${quizUuid}/clone/`,
	resource: 'currentQuiz'
})

export const deployQuiz = (quizUuid, callback): ApiRequestAction => ({
	...BaseActions.deployProjectBase(quizUuid, callback, DeployResultModel),
	url: `${BASE_URL}/${quizUuid}/deploy/`
})

export const fetchQuizPipeline = (pipelineId, callback): ApiRequestAction => ({
	...BaseActions.fetchPipelineBase(pipelineId, callback, PipelineResultModel),
	url: `${BASE_URL}/deploy/${pipelineId}`
})

export const updateQuiz = (
	quizUuid: string,
	quiz: QuizModel,
	titleImageFile?: any,
	callback: Function,
	failCallback: Function
): ApiRequestAction => ({
	...BaseActions.updateProjectBase(
		quizUuid,
		quiz,
		titleImageFile,
		callback,
		failCallback,
		uploadTitleImage,
		removeTitleImage
	),
	url: `${BASE_URL}/${quizUuid}`,
	resource: 'currentQuiz',
	successNotification: 'Quiz saved'
})

export const uploadTitleImage = (
	file: any,
	fileName: string,
	quizUuid: string,
	onSuccess?: (string | number) => void,
	successNotification?: string | boolean
): ApiRequestAction => ({
	...BaseActions.uploadTitleImageBase(file, onSuccess, successNotification),
	url: `${BASE_URL}/${quizUuid}/media/${fileName}`
})

export const removeTitleImage = (
	fileName: string,
	quizUuid: string,
	onSuccess?: () => void,
	successNotification?: string | boolean
): ApiRequestAction => ({
	...BaseActions.removeTitleImageBase(onSuccess, successNotification),
	type: API_REQUEST,
	url: `${BASE_URL}/${quizUuid}/media/${fileName}`
})

export const uploadMedia = (
	fileData: any,
	fileName: string,
	quizUuid: string,
	onSuccess?: (string | number) => void,
	successNotification?: string | boolean
): ApiRequestAction => ({
	...BaseActions.uploadMediaBase(fileData, onSuccess, successNotification),
	url: `${BASE_URL}/${quizUuid}/media/${fileName}/add/`
})
